import { createEffect, For, Show } from "solid-js";
import { P } from "../../../utils/typography";
import ColorField from "../../forms/fields/ColorField";
import { useFormState } from "../../forms/state";

type ChecklistFormValues = Record<string, string>;

export function ColorPickerChecklistField(props: {
    defaultValue?: string;
    name: string;
    label?: string;
}) {
    const form = useFormState<ChecklistFormValues>();
    const field = form.getFieldController("color");
    const colorList = [
        "#fd1d1d",
        "#fda71d",
        "#ffe30a",
        "#53e638",
        "#2facd9",
        "#261dfd",
        "#9300ff",
        "#000000",
        "#b9b9b9",
    ];

    createEffect(() => {
        if (!field.value && props.defaultValue) {
            field.setValue(props.defaultValue);
        }
    });

    return (
        <div class={"w-full"}>
            {props.label && <P class={"font-normal"}>{props.label}</P>}
            <div class={"flex flex-wrap items-center gap-2"}>
                <For each={colorList}>
                    {color => (
                        <div
                            class={`flex h-6 w-6 cursor-pointer items-center justify-center rounded-full`}
                            style={{ "background-color": color }}
                            onClick={() => field.setValue(color)}
                        >
                            <Show when={(field.value || props.defaultValue) === color}>
                                <i class="fas fa-check text-sm text-white" />
                            </Show>
                        </div>
                    )}
                </For>
                <ColorField
                    name={props.name}
                    label={"Personalizado"}
                    parentClass={!colorList.includes(field.value) ? "border-success-500" : ""}
                    defaultValue={props.defaultValue ?? "#808080"}
                />
            </div>
        </div>
    );
}
