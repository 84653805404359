import "./IconField.css";
import { FieldProps } from "../parts/types";
import { useFormState } from "../../state";
import { onMount, untrack } from "solid-js";
import { createRef } from "../../../../utils/reactRefs";
import Choices, { InputChoice } from "choices.js";
import SelectField from "../SelectField";
import { InputGroup } from "choices.js/src/scripts/interfaces/input-group";

type ChoiceFieldProps<TChoice extends InputChoice> = FieldProps<string, HTMLSelectElement> & {
    renderChoice: (icon: TChoice) => string;
    fetchChoices: () => Promise<(InputChoice | InputGroup)[]>;
    userConfig?: Partial<Choices["config"]>;
};
export default function ChoiceField<TChoice extends InputChoice = InputChoice>(
    props: ChoiceFieldProps<TChoice>,
) {
    const form = useFormState();
    const field = form.getFieldController(untrack(() => props.name));

    const ref = props.staticRef ?? createRef<HTMLSelectElement>();

    onMount(async () => {
        let choices = new Choices(ref.current!, {
            allowHTML: true,

            // readOnly (part 1 of 2)
            addItems: !props.readOnly,
            removeItems: !props.readOnly,

            removeItemButton: props.optional,
            searchResultLimit: 15,
            callbackOnCreateTemplates: () => ({
                choice: template(Choices.defaults.templates.choice, props.renderChoice),
                item: template(Choices.defaults.templates.item, props.renderChoice),
            }),
            placeholderValue: "Selecciona un icono",
            searchPlaceholderValue: "Escribe para buscar un icono",
            loadingText: "Cargando...",
            noResultsText: "No se encontraron resultados",
            noChoicesText: "No hay opciones para elegir",
            // Hidden by IconField.css https://github.com/Choices-js/Choices/issues/491
            //itemSelectText: "Enter",
            ...props.userConfig,
        });

        // defaultValue
        await choices.setChoices(props.fetchChoices);
        if (field.value) choices.setChoiceByValue(field.value as string);

        // readOnly (part 2 of 2) (this must be after setChoices)
        if (props.readOnly) choices.disable();

        ref.current!.addEventListener("change", event => {
            const e = event as CustomEvent<{ value: string }>;
            field.setValue(e.detail.value);
        });
    });

    return <SelectField staticRef={ref} icon="" {...props} options={[]} />;
}

const template =
    // @ts-expect-error - .
    (defaultTemplate, html) =>
        // @ts-expect-error - .
        function (this, opts, icon, ...rest) {
            return Object.assign(defaultTemplate.call(this, opts, icon, ...rest), {
                innerHTML: html(icon),
            });
        };
