import { For, Show, Suspense } from "solid-js";
import {
    ChecklistSections,
    Concept,
    CreateConceptStepParams,
    SectionStep,
} from "../../../api/services/checklist/interface";
import {
    createChecklistSectionsListQuery,
    createListConceptStepsQuery,
} from "../../../api/services/checklist/queries";
import { FormWrapper } from "../../forms/FormWrapper";
import { Button } from "../../ui/components";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { SectionCard } from "../commons/SectionCard";
import { LabelCard } from "../commons/LabelCard";
import { P } from "../../../utils/typography";
import CheckboxField from "../../forms/fields/CheckboxField";
import { useFormState } from "../../forms/state";
import { createConceptStepMutation } from "../../../api/services/checklist/mutations";
import Toastify from "toastify-js";

type CreateConceptStepFormValues = Record<string, boolean>;

export default function ConceptStepForm(props: { concept: Concept; checklistId: string }) {
    const conceptStepMutation = createConceptStepMutation();
    const listSectionsQuery = createChecklistSectionsListQuery(() => props.checklistId);
    const onSubmit = async (values: CreateConceptStepFormValues) => {
        const allSteps = Object.entries(values)
            .filter(entry => entry[0].includes("concept_"))
            .filter(entry => entry[1]);
        if (allSteps.length === 0) {
            alert("Selecciona al menos un paso");
            return;
        }
        try {
            for (const [key] of allSteps) {
                //`section_${props.step.sectionId}_concept_${props.step.id}`
                const [, conceptId] = key.split("concept_");
                const payload: CreateConceptStepParams = {
                    checklist_form: conceptId,
                    concept: props.concept.id,
                    order: 1,
                    weight: 1,
                };
                await conceptStepMutation.mutateAsync(payload);
            }
            Toastify({
                text: "Pasos creados correctamente",
            }).showToast();
        } catch (e) {
            console.error(e);
            Toastify({
                text: "Error al crear los pasos",
                backgroundColor: "red",
            }).showToast();
        }
    };
    return (
        <FormWrapper onSubmit={onSubmit}>
            <P class="my-2">
                Elige los pasos que componen el concepto <b>{props.concept.name}</b>
            </P>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={listSectionsQuery.data}>
                    {sections => (
                        <>
                            {/* <Show
                                when={getCurrentStepsQuery.data}
                            >
                                {data => (
                                    <JsonDebug value={filterChecklistConcepts( sections(), data())}/>
                                )}
                            </Show> */}
                            <SectionConceptList sections={sections()} concept={props.concept} />
                        </>
                    )}
                </Show>
            </Suspense>
            <Button type="submit">Crear Paso</Button>
        </FormWrapper>
    );
}

function SectionConceptList(props: { sections: ChecklistSections[]; concept: Concept }) {
    const formState = useFormState();
    const state = formState.values;
    const getCurrentStepsQuery = createListConceptStepsQuery(() => props.concept.id);

    const onChangeSectionCheckbox = (e: Event) => {
        const target = e.target as HTMLInputElement;
        const isChecked = target.checked;
        const sectionId = target.name.split("_")[1];
        //Check all steps in this section
        //step names are `section_${props.step.sectionId}_concept_${props.step.id}`
        formState.setValues(values => {
            console.log("FORMSTATEVALUES", { values });
            //Change all steps in this section. find keys that start with `section_${sectionId}_concept_`
            const sectionkeyprefix = `section_${sectionId}_concept_`;
            const keys = Object.keys(values).filter(key => key.startsWith(sectionkeyprefix));
            console.log({ keys });
            keys.forEach(key => {
                values[key] = isChecked;
            });
        });
    };
    const isSectionChecked = (sectionId: string) => {
        return state[`section_${sectionId}`];
    };

    // const filterSteps = (step: SectionStep, dict: Record<string, ConceptStep>) => {
    //     console.log("FILTER STEPS", { step, dict });
    //     return !dict[step.id];
    // };

    return (
        <div class={"space-y-4"}>
            <Suspense>
                <Show when={getCurrentStepsQuery.data}>
                    <For each={props.sections}>
                        {section => (
                            <SectionCard
                                //filterSteps={(section: SectionStep) => filterSteps(section, dict())}
                                customName={
                                    <div>
                                        <div>
                                            <CheckboxField
                                                name={`section_${section.id}`}
                                                label={
                                                    isSectionChecked(section.id)
                                                        ? "Deseleccionar esta sección completa"
                                                        : `Seleccionar esta sección completa`
                                                }
                                                onChange={onChangeSectionCheckbox}
                                            />
                                        </div>
                                        <P>{section.name}</P>
                                    </div>
                                }
                                section={section}
                                IterableItem={ConceptCard}
                            />
                        )}
                    </For>
                </Show>
            </Suspense>
        </div>
    );
}

function ConceptCard(props: { step: SectionStep; index: number; disabled?: boolean }) {
    const formState = useFormState();

    const onChangeConceptStepCheckbox = (e: Event) => {
        //Deselect section checkbox if any step in this section is deselected
        const target = e.target as HTMLInputElement;
        const sectionId = target.name.split("_")[1];
        const stepsInThisSection = Object.keys(formState.values).filter(key =>
            key.startsWith(`section_${sectionId}_concept_`),
        );
        const allStepsSelected = stepsInThisSection.every(key => formState.values[key]);
        formState.setValues(values => {
            values[`section_${sectionId}`] = allStepsSelected;
        });
    };
    return (
        <LabelCard
            {...props}
            class={"flex-row-reverse"}
            actions={
                <div>
                    <CheckboxField
                        name={`section_${props.step.sectionId}_concept_${props.step.id}`}
                        onChange={onChangeConceptStepCheckbox}
                    />
                </div>
            }
        />
    );
}
