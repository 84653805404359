import { Show, Suspense } from "solid-js";
import { createFormByBpmnElementQuery } from "../../api/services/workflow/queries";
import { PageWrapper } from "../ui/pageWrappers";
import { useLocation, useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { RenderDynamicFields } from "../FormBuilder/RenderDynamicForm";
import { createForm, FormController, FormValues, isBlank } from "../forms/state";
import { createExecuteWorkflowMutation } from "../../api/services/workflow/mutations";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";
import { StartEvent, Workflow } from "../../api/services/workflow/workflows/interface";
import SubmitButton from "../forms/SubmitButton";
import { FormWrapper } from "../forms/FormWrapper";
import { parsedEnv } from "../../utils/parsedEnv";
import { makeQueryString } from "../client/client";
import _ from "lodash";
import { Capacitor } from "@capacitor/core";
import RichTextRender from "../../utils/rich-text/RichTextRender";

export default function StartWorkflowExecutionPage() {
    const location = useLocation();
    const params = useParams<{
        workflowId: string;
        bpmnId: string;
        workflowType: string;
    }>();
    const workflowQuery = createWorkflowDetailQuery(() => params.workflowId);
    const findStartEvent = (workflow: Workflow, bpmnId: string) => {
        const found = workflow.start_events.find(se => se.bpmnElementId === bpmnId);
        if (!found)
            throw new Error(`Start event ${params.bpmnId} not found in workflow ${workflow.id}`);
        return found;
    };
    const formController = createForm();
    return (
        <PageWrapper class="bg-sidebar">
            <Show when={workflowQuery.data}>
                {workflow => (
                    <div class="flex flex-col gap-x-10 gap-y-3 lg:flex-row">
                        <div class="flex justify-center lg:flex-1">
                            <StartEventForm
                                startEvent={findStartEvent(workflow(), params.bpmnId)}
                                workflowId={params.workflowId}
                                workflowType={params.workflowType}
                                formController={formController}
                            />
                        </div>
                        <Show when={!location.pathname.startsWith("/public")}>
                            <PublicUrlGenerator
                                formValues={_.omitBy(
                                    formController.values,
                                    (value, fieldName) =>
                                        isBlank(value) ||
                                        value ===
                                            formController.state.fieldStore[fieldName]?.blankValue,
                                )}
                            />
                        </Show>
                    </div>
                )}
            </Show>
        </PageWrapper>
    );
}

function StartEventForm(props: {
    workflowId: string;
    startEvent: StartEvent;
    workflowType: string;
    formController?: FormController<FormValues>;
}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        workflowId: props.workflowId,
        bpmnElementId: props.startEvent.bpmnElementId,
    }));
    const executeWorkflowMutation = createExecuteWorkflowMutation();
    const onSubmit = async (values: FormValues) => {
        await executeWorkflowMutation.mutateAsync({
            workflowId: props.workflowId,
            startEventElementId: props.startEvent.bpmnElementId,
            startEventType: props.startEvent.type,
            formValues: values,
        });
        navigate(`/workflows/${props.workflowType}/executions`);
    };
    return (
        <div class="min-w-80 max-w-120">
            <RichTextRender
                text={
                    "# Heading 1\n## Heading 2\n" +
                    `Markdown **de prueba**, aquí va la description del start event, hay dos alternativas:\n` +
                    "1. Agregarle el campo `description` a los `start_events` del endpoint `/workflows/workflows/{id}`.\n" +
                    "2. Usar el endpoint `GET /workflows/start-events/{id}`, el alan lo tendría que volver público (por el QR).\n\n" +
                    `***Inserte aquí la description correspondiente al start event \`${props.startEvent.bpmnElementId}\`***`
                }
            />
            <Suspense>
                <Show when={formByBpmnElementQuery.data}>
                    {form => (
                        <FormWrapper
                            staticForm={props.formController}
                            class="flex flex-col gap-3"
                            fieldWrapperClass="rounded-md px-4 py-3 bg-white"
                            onSubmit={onSubmit}
                            defaultValues={searchParams}
                        >
                            <RenderDynamicFields
                                fields={form().fields.map(field => ({
                                    ...field,

                                    // Fields specified in the URL are "hardcoded by QR" therefore readonly.
                                    // `field.name in searchParams` doesn't work with searchParams.
                                    readOnly: searchParams[field.name] !== undefined,
                                }))}
                            />
                            <SubmitButton submittingText="Enviando...">Enviar</SubmitButton>
                        </FormWrapper>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function PublicUrlGenerator(props: { formValues: FormValues }) {
    const location = useLocation();
    const url = () =>
        `${
            Capacitor.isNativePlatform()
                ? parsedEnv.VITE_WEB_URL
                : `${window.location.protocol}//${window.location.hostname}${
                      window.location.port ? ":" + window.location.port : ""
                  }`
        }/public${location.pathname}?${makeQueryString(props.formValues)}`;

    return (
        <div class="max-w-192">
            <h2 class="mb-3 text-display-xs">URL ejecución pública con QR</h2>
            <p class="mb-3">
                Al ingresar a la URL a continuación, los datos que hayas ingresado ahora en el
                formulario se reflejarán como campos de solo lectura.
            </p>
            <a href={url()} class="font-mono">
                {url()}
            </a>
        </div>
    );
}
