import { createSignal, For, Show } from "solid-js";
import {
    createPaulsenGroupTypeListQuery,
    createListPaulsenGroupsQuery,
    createListPaulsenPositionsQuery,
} from "../../api/services/audiences-paulsen/queries";
import { PageWrapper } from "../ui/pageWrappers";
import AudienceGroupDefinitionForm from "./AudienceGroupDefinitionForm";

import { Button } from "../ui/components";
import _ from "lodash";
import { AudiencesFunctionsTable } from "./AudiencesFunctionsTable";
import {
    createMakePaulsenAudienceMutation,
    createMakePaulsenVariableMutation,
} from "../../api/services/audiences-paulsen/mutations";
import TextField from "../forms/fields/TextField";
import Toastify from "toastify-js";
import {
    PaulsenAudienceGroup,
    PaulsenAudiencePosition,
    PaulsenGroupType,
} from "../../api/services/audiences-paulsen/interface";
import DeleteButton from "../ui/DeleteButton";
import { useNavigate } from "@solidjs/router";
import { FormWrapper } from "../forms/FormWrapper";
import { createForm } from "../forms/state";
import AudienceOperatorForm from "./AudienceOperatorForm";

type AudienceGroupDefinition = {
    group: string | "new";
    groupType: string | "new";
};

export default function AudienceCreatePage() {
    //const audienceFunctionsQuery = createAudienceFunctionsQuery();

    const [audiences, setAudiences] = createSignal<AudienceGroupDefinition[]>([]);
    const form = createForm<{ audienceName: string }>();

    const createAudienceMutation = createMakePaulsenAudienceMutation();
    const makeVariableMutation = createMakePaulsenVariableMutation();
    const navigate = useNavigate();
    const createAudience = () => {
        const variables = () => formToVariables(form.values);
        const operators = () => formToOperators(form.values);
        const expression = createparenthesesExpression(
            createExpressionStr(variables(), operators()),
        );
        createAudienceMutation.mutate(
            {
                name: form.values.audienceName,
                expression: expression,
            },
            {
                onSuccess(data) {
                    const audienceId = data.id;
                    variables().forEach((variable, index) => {
                        const [func] = variable.groupType.split("-");
                        const audience = Number(audienceId);
                        const args = [Number(variable.group)];
                        const fun = func;
                        const payload = {
                            audience,
                            args: args,
                            function: fun,
                            name: variable.variableName,
                        };
                        makeVariableMutation.mutate(payload, {
                            onSuccess() {
                                if (index === variables().length - 1) {
                                    Toastify({
                                        text: `Audiencia ${data.name} creada con éxito`,
                                    }).showToast();
                                    navigate("/audiences");
                                }
                            },
                        });
                    });
                },
            },
        );
    };
    const addNewVariable = () => {
        const prev = audiences();
        const len = prev.length;
        const newAudience = {
            group: `${len + 1}`,
            groupType: `${len + 1}`,
        };
        setAudiences([...prev, newAudience]);
    };
    const removeVariable = (index: number) => {
        const prev = audiences();
        const newAudiences = prev.filter((_, i) => i !== index);
        setAudiences(newAudiences);
    };
    return (
        <PageWrapper>
            <FormWrapper staticForm={form}>
                <TextField
                    name="audienceName"
                    label={"Nombre de la audiencia"}
                    defaultValue="Nombre de la audiencia"
                />
                <For each={audiences()}>
                    {(audience, index) => {
                        const identifier = `${index() + 1}`;
                        return (
                            <div class="space-y-4">
                                <AudienceGroupDefinitionForm identifier={identifier} />
                                <Show when={index() !== 0}>
                                    <div>
                                        <DeleteButton onClick={() => removeVariable(index())} />
                                    </div>
                                </Show>
                                <Show when={audiences().length - 1 != index()}>
                                    <AudienceOperatorForm identifier={identifier} />
                                </Show>
                            </div>
                        );
                    }}
                </For>
            </FormWrapper>
            <div class="flex">
                <ExpressionRenderer formValues={form.values} />
                <AudiencesFunctionsTable />
            </div>
            <div class="flex gap-4">
                <Button onClick={addNewVariable}>Agregar variable</Button>
                <Button onClick={createAudience} disabled={audiences().length === 0}>
                    Crear audiencia
                </Button>
            </div>
        </PageWrapper>
    );
}

function ExpressionRenderer(props: { formValues: Record<string, string> }) {
    const groupTypesQuery = createPaulsenGroupTypeListQuery();
    const groupListQuery = createListPaulsenGroupsQuery();
    const positionsQuery = createListPaulsenPositionsQuery();
    return (
        <Show when={groupTypesQuery.data}>
            {groupTypes => {
                const groupTypesAsDict = _.keyBy(groupTypes(), "id");
                return (
                    <Show when={groupListQuery.data}>
                        {groups => {
                            const groupsAsDict = _.keyBy(groups(), "id");
                            return (
                                <Show when={positionsQuery.data}>
                                    {positions => {
                                        const positionsAsDict = _.keyBy(positions(), "id");
                                        return (
                                            <div class="space-y-4">
                                                <AudienceVariablesList
                                                    formValues={props.formValues}
                                                    groupTypesAsDict={groupTypesAsDict}
                                                    groupsAsDict={groupsAsDict}
                                                    positionsAsDict={positionsAsDict}
                                                />
                                                <AudienceOperatorsList
                                                    formValues={props.formValues}
                                                />
                                            </div>
                                        );
                                    }}
                                </Show>
                            );
                        }}
                    </Show>
                );
            }}
        </Show>
    );
}

// function Expression(props: { formValues: Record<string, string> }) {
//     const variables = () => formToVariables(props.formValues);
//     const operators = () => formToOperators(props.formValues);
//     const expression = () => createExpressionStr(variables(), operators());
//     return (
//         <div>
//             <h2>Expresión texto</h2>
//             <div>Expresión</div>
//             <div class="flex gap-2">
//                 <div>
//                     <p>Expression</p>
//                     {expression()}
//                 </div>
//                 <div>
//                     <p>Expression with parentheses</p>

//                     {createparenthesesExpression(createExpressionStr(variables(), operators()))}
//                 </div>
//             </div>
//         </div>
//     );
// }
function AudienceOperatorsList(props: { formValues: Record<string, string> }) {
    return (
        <div>
            <h2>Operadores</h2>
            {formToOperators(props.formValues).join(" ")}
        </div>
    );
}
function AudienceVariablesList(props: {
    formValues: Record<string, string>;
    groupTypesAsDict: _.Dictionary<PaulsenGroupType>;
    groupsAsDict: _.Dictionary<PaulsenAudienceGroup>;
    positionsAsDict: _.Dictionary<PaulsenAudiencePosition>;
}) {
    return (
        <div>
            <h2>Variables</h2>
            <For each={formToVariables(props.formValues)}>
                {variable => {
                    return (
                        <VariableDefinition
                            variable={variable}
                            groupTypesAsDict={props.groupTypesAsDict}
                            groupsAsDict={props.groupsAsDict}
                            positionsAsDict={props.positionsAsDict}
                        />
                    );
                }}
            </For>
        </div>
    );
}
function createExpressionStr(variables: AudienceVariable[], operators: Operator[]): string {
    let str = "";
    for (let i = 0; i < variables.length; i++) {
        str += variables[i].variableName;
        if (i < operators.length) {
            str += operators[i] ? ` ${operators[i]} ` : "";
        }
    }
    return str;
}

function createparenthesesExpression(expression: string): string {
    if (!expression.includes("and") && !expression.includes("or")) {
        return "(" + expression + ")";
    }

    let operatorIndex = -1;
    let parenthesesCount = 0;
    for (let i = expression.length - 1; i >= 0; i--) {
        if (expression[i] === ")" || expression[i] === "(") {
            parenthesesCount += expression[i] === "(" ? 1 : -1;
        }
        if (
            ((expression[i] === "a" && expression.slice(i, i + 3) === "and") ||
                (expression[i] === "o" && expression.slice(i, i + 2) === "or")) &&
            parenthesesCount === 0
        ) {
            operatorIndex = i;
            break;
        }
    }

    if (operatorIndex === -1) {
        return expression;
    }

    const leftPart = createparenthesesExpression(expression.slice(0, operatorIndex - 1));
    const rightPart = createparenthesesExpression(expression.slice(operatorIndex + 3));

    return (
        "(" +
        leftPart +
        " " +
        expression.slice(operatorIndex, operatorIndex + 3) +
        " " +
        rightPart +
        ")"
    );
}

function VariableDefinition(props: {
    variable: AudienceVariable;
    groupTypesAsDict: _.Dictionary<PaulsenGroupType>;
    groupsAsDict: _.Dictionary<PaulsenAudienceGroup>;
    positionsAsDict: _.Dictionary<PaulsenAudiencePosition>;
}) {
    const groupTypeName = (): string => {
        const [type, id] = props.variable.groupType.split("-");
        switch (type) {
            case "in_group":
                return `Usuarios que pertenecen al ${props.groupTypesAsDict[id]?.name} `;

            case "bosses_of":
                return "Jefes de ";
            case "subordinates_of":
                return "Subordinados de ";
        }
        return "hardcoded name";
    };

    const groupName = (): string => {
        const [type, id] = props.variable.groupType.split("-");
        const group = props.variable.group;
        switch (type) {
            case "in_group":
                return props.groupsAsDict[id]?.name ?? "";
            case "bosses_of":
                return props.positionsAsDict[group]?.name ?? "";
            case "subordinates_of":
                return props.positionsAsDict[group]?.name ?? "";
            default:
                return "";
        }
    };
    return (
        <div>
            {props.variable.variableName} ={" "}
            <Show when={groupTypeName()}>
                {name => {
                    return <span>{name()}</span>;
                }}
            </Show>
            <Show when={groupName()}>
                {name => {
                    return <span>{name()}</span>;
                }}
            </Show>
        </div>
    );
}
type AudienceVariable = {
    group: string;
    groupType: string;
    variableName: string;
};

type Operator = "and" | "or" | "not";

function formToOperators(formValues: Record<string, string>): Operator[] {
    const chunks = groupBySuffix(formValues);

    return Object.keys(chunks).map(key => {
        const operator = chunks[`${key}`].find(([k]) => k.includes(`operator-${key}`))?.[1];
        return operator as Operator;
    });
}
function formToVariables(formValues: Record<string, string>): AudienceVariable[] {
    if (Object.keys(formValues).length > 26) throw new Error("Too many variables");
    const variableNameOptions = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    const split = groupBySuffix(formValues);

    return Object.keys(split).map(key => {
        const group = split[`${key}`].find(([k]) => k.includes(`group-${key}`))?.[1];
        const groupType = split[`${key}`].find(([k]) => k.includes(`groupType-${key}`))?.[1];
        return {
            group: group ?? "",
            groupType: groupType ?? "",
            variableName: variableNameOptions[Number(key) - 1],
        };
    });
}

function groupBySuffix(formValues: Record<string, string>) {
    return _.groupBy(
        Object.entries(formValues).filter(([key]) => key != "audienceName"),
        ([key]) => {
            const [, suffix] = key.split("-");
            return suffix;
        },
    );
}
