import { JsonDebug } from "../../../utils/debug";
import { BpmnElement } from "../bpmn";
import { ModalController } from "../../ui/Modal";

export type ElementExecutionProps<TBpmnElement extends BpmnElement> = {
    element: TBpmnElement;
    workflowId: string;
    executionId: string;
    onActionSuccess: () => void;
    // we need this or getWidget gives a type error, we have no idea why
    panel?: ModalController<TBpmnElement, void>;
};

export default function NotImplementedElementExecution(props: ElementExecutionProps<BpmnElement>) {
    return <JsonDebug value={props.element} />;
}
