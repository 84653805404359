import { PageWrapper } from "../ui/pageWrappers";
import { P } from "../../utils/typography";

import WorkflowExecutionList from "./WorkflowExecutionList";

export default function WorkflowsExecutionsPage() {
    return (
        <PageWrapper>
            <P>Ejecuciones</P>
            <WorkflowExecutionList />
        </PageWrapper>
    );
}
