import { type, string, Infer, enums, array, union, number, boolean, nullable } from "superstruct";
import { assetsClient } from "../../../clients/assets";
import { makeServiceQuery } from "../../../utils";
import {
    CreateUpdateLogicPayload,
    LogicJson,
    LogicParserOptions,
    LogicParserService,
} from "../interface";
import { sV3EntityTarget } from "../../checklist/implementations/v3";
import { Asset } from "../../checklist/interface";
import { Venue } from "../../locations/interface";

export class V3LogicParserService implements LogicParserService {
    retrieveLogicDetail = makeServiceQuery({
        fetchJson: async (id: string) => assetsClient.get(`/logic_parser/${id}`).receiveJson(),
        responseSchema: sV3LogicJson(),
        deserialize: deserializeLogicJson,
    });

    createLogic = async (payload: CreateUpdateLogicPayload) => {
        const response = await assetsClient
            .post("/logic_parser/new")
            .sendJson(payload)
            .receiveJson();
        return deserializeLogicJson(response as V3LogicJson);
    };

    putLogic = async (id: string, payload: CreateUpdateLogicPayload) => {
        const response = await assetsClient
            .put(`/logic_parser/${id}`)
            .sendJson(payload)
            .receiveJson();
        return deserializeLogicJson(response as V3LogicJson);
    };
    patchLogic = async (id: string, payload: CreateUpdateLogicPayload) => {
        const response = await assetsClient
            .patch(`/logic_parser/${id}`)
            .sendJson(payload)
            .receiveJson();
        return deserializeLogicJson(response as V3LogicJson);
    };
    retrieveLogicParserOptions = makeServiceQuery({
        fetchJson: async (model: string) =>
            assetsClient.get(`/logic_parser/${model}/options`).receiveJson(),
        responseSchema: sV3LogicParserOptions(),
        deserialize: deserializeLogicParserOptions,
    });

    evaluateLogic = async (payload: CreateUpdateLogicPayload) => {
        const response = await assetsClient
            .post("/logic_parser/evaluate")
            .sendJson(payload)
            .receive(array(sV3EntityTarget()));
        return response.map(i => {
            if ("address" in i) return { ...i, target_entity: "venue" } as Venue;
            else return { ...i, target_entity: "asset" } as Asset;
        });
    };
}

// {
//     "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
//     "created_at": "2019-08-24T14:15:22Z",
//     "updated_at": "2019-08-24T14:15:22Z",
//     "expression": null,
//     "model": "asset"
//     }

function deserializeLogicParserOptions(data: V3LogicParserOptions): LogicParserOptions {
    return {
        functions: data.functions.map(item => {
            return {
                function: item.function,
                multiple: item.multiple,
                input_type: item.input_type,
                options: item.options,
            };
        }),
        operators: data.operators,
    };
}

type V3LogicParserOptions = Infer<ReturnType<typeof sV3LogicParserOptions>>;
function sV3LogicParserOptions() {
    return type({
        functions: array(sV3FunctionExpressionOption()),
        operators: array(sV3OperatorExpressionOption()),
    });
}

type V3LogicJson = Infer<ReturnType<typeof sV3LogicJson>>;

function sV3LogicJson() {
    return type({
        id: string(),
        created_at: string(),
        updated_at: string(),
        expression: nullable(array(union([sV3FunctionExpression(), sV3OperatorExpression()]))),
        model: enums(["asset", "venue", "location"]),
    });
}

function deserializeLogicJson(data: V3LogicJson): LogicJson {
    return {
        id: data.id,
        created_at: Temporal.Instant.from(data.created_at),
        updated_at: Temporal.Instant.from(data.updated_at),
        expression: data.expression,
        model: data.model,
    };
}

// function deserializeExpression (expression: V3Expression): LogicJson["expression"] {
//     return expression.map((item) => {
//         if ("function" in item) {
//             const f = item
//             return {
//                 function: item.function,
//                 value: item.value
//             }
//         } else {
//             return {
//                 operator: item.operator
//             }
//         }
//     })
// }
//type V3Expression = Infer<ReturnType <typeof sV3Expression>>

function sV3FunctionExpression() {
    return type({
        function: string(),
        value: union([array(union([string(), number()])), number(), string()]),
    });
}

function sV3OperatorExpression() {
    return type({
        operator: enums(["AND", "OR", "XOR"]),
    });
}

// function sV3ExpressionOptions(){
//     return array(union([sV3FunctionExpressionOption(), sV3OperatorExpressionOption()]))
// }

function sV3FunctionExpressionOption() {
    return type({
        function: string(),
        multiple: boolean(),
        input_type: string(),
        options: nullable(array(array(union([string(), number()])))),
    });
}

function sV3OperatorExpressionOption() {
    return enums(["AND", "OR", "XOR"]);
}
