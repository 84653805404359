import { JSX, ParentProps, Show } from "solid-js";

export function RecordTable(props: ParentProps<{ class?: string }>) {
    return (
        <div
            class={`mb-4 grid gap-x-4 gap-y-1 ${props.class ?? ""}`}
            style={{ "grid-template-columns": "auto 1fr" }}
        >
            {props.children}
        </div>
    );
}

export function Entry(props: { name: string; value: JSX.Element }) {
    return (
        <>
            <div class="font-normal">{props.name}</div>
            <div>{props.value}</div>
        </>
    );
}

export function BubbleEntry(props: { name?: string; value: JSX.Element; class?: string }) {
    return (
        <div class={`flex gap-x-1 rounded-6 bg-dark-gray-200 px-2 py-1 ${props.class ?? ""}`}>
            <Show when={props.name}>
                <div class="text-xs">{props.name}</div>
            </Show>
            <div class="text-xs">{props.value}</div>
        </div>
    );
}
