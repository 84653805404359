import { createListEvaluationLabelsQuery } from "../../../../api/services/checklist/queries";
import { Show, Suspense } from "solid-js";
import { InputChoice } from "choices.js";
import { FieldProps } from "../../../forms/fields/parts/types";
import ChoiceField from "../../../forms/fields/choiceField/ChoiceField";

export default function EvaluationLabelField(
    props: FieldProps<string, HTMLSelectElement> & { evaluationID: string },
) {
    const evaluationLabelListQuery = createListEvaluationLabelsQuery(props.evaluationID);
    return (
        <Suspense>
            <Show when={evaluationLabelListQuery.data}>
                {results => (
                    <ChoiceField
                        {...props}
                        renderChoice={html}
                        userConfig={{
                            searchEnabled: false,
                        }}
                        fetchChoices={async () => {
                            return Object.values(results()).map(label => ({
                                value: label.id,
                                label: label.name,
                                labelClass: label.color,
                                labelDescription: label.icon,
                            }));
                        }}
                    />
                )}
            </Show>
        </Suspense>
    );
}

const html = (label: InputChoice) => `
<div class="flex items-center gap-x-2 w-full">
    <i class="${label.labelDescription}" style="color: ${label.labelClass}"></i>
    <span class="text-sm font-normal pt-0.5">${label.label}</span>
</div>`;
