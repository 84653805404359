import { Show } from "solid-js";
import { createWorkflowExecutionDetailQuery } from "../../../api/services/workflow/queries";
//import { TechnicalDetails, JsonDebug } from "../../../utils/debug";
import { useLocale } from "../../i18n/context";
import { RecordTable, Entry, BubbleEntry } from "../../ui/RecordTable";
import { WorkflowExecution } from "../../../api/services/workflow/interface";
export function ExecutionMetadata(props: { executionId: string; display?: "bubble" | "table" }) {
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => props.executionId);
    return (
        <Show when={executionDetailQuery.data}>
            {execution => {
                return (
                    <Show
                        when={props.display == "bubble"}
                        fallback={<TableDisplay execution={execution()} />}
                    >
                        <BubbleDisplay execution={execution()} />
                    </Show>
                );
            }}
        </Show>
    );
}
export function TableDisplay(props: { execution: WorkflowExecution }) {
    const [locale] = useLocale();

    return (
        <RecordTable>
            <Entry
                name={locale().workflows.createdAt}
                value={Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "short",
                    timeStyle: "short",
                }).format(props.execution.createdAt)}
            />
            <Entry
                name={locale().workflows.updatedAt}
                value={Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "short",
                    timeStyle: "short",
                }).format(props.execution.updatedAt)}
            />
            <Entry
                name={locale().workflows.status}
                value={locale().workflows.completionStatuses[props.execution.status]}
            />
        </RecordTable>
    );
}

function BubbleDisplay(props: { execution: WorkflowExecution }) {
    const [locale] = useLocale();
    return (
        <div class="flex space-x-2">
            <BubbleEntry value={locale().workflows.completionStatuses[props.execution.status]} />
            {/* TODO: the initiated date is the created date truly? */}
            <BubbleEntry
                name={locale().workflows.initiatedAt}
                value={Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "long",
                    timeStyle: "short",
                }).format(props.execution.createdAt)}
            />
        </div>
    );
}
