import { WorkflowElement } from "../../../api/services/workflow/interface";
import { JsonDebug } from "../../../utils/debug";
import { ActivityFormWithResponses } from "./events-components/ActivityFormWithResponses";
import { ActivityDetailCards } from "./events-components/ActivityDetailCards";
import { ActivityResponses } from "./events-components/ActivityResponses";
import { ActivityDescription } from "./events-components/ActivityDescription";
import { ActivityName } from "./events-components/ActivityName";
import { ActivityResponsible } from "./events-components/ActivityRespinsible";
import { ExecutionTimeframe } from "./events-components/ExecutionTimeFrame";
import { ExecutionUser } from "./events-components/ExecutionUser";
import { BasicActivityHeader } from "./events-components/BasicActivityHeader";
import { ActivityTasks } from "./events-components/ActivityTasks";

export type EventGeneralDataProps = { element: WorkflowElement; workflowId: string };
//Default
export function DefaultEventTypeGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            Render de type por defecto
            <BasicActivityHeader {...props} />
            <JsonDebug value={props} />
        </div>
    );
}
//VanillaStartEvent
export function VanillaStartEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <ExecutionUser {...props} />
            <ActivityFormWithResponses {...props} />
        </div>
    );
}

//MessageStartEvent
export function MessageStartEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <BasicActivityHeader {...props} />
            <ActivityResponses {...props} />
        </div>
    );
}

//UserTask
export function UserTaskGeneralData(props: EventGeneralDataProps) {
    return (
        <div class="flex flex-col gap-4">
            <ActivityDetailCards {...props} />
            <ActivityName {...props} />
            <ActivityResponsible {...props} />
            <ExecutionTimeframe {...props} />
            <ActivityDescription {...props} />
            <ActivityTasks {...props} />
        </div>
    );
}
//TimerBoundaryEvent
export function TimerBoundaryEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Duración: </h2>
            <JsonDebug value={props} />
        </div>
    );
}
//TimerIntermediateCatchEvent
export function TimerIntermediateCatchEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Información TimerIntermediateCatchEventGeneralData</h2>
            <JsonDebug value={props} />
        </div>
    );
}
//MessageIntermediateCatchEvent
export function MessageIntermediateCatchEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Información MessageIntermediateCatchEventGeneralData</h2>
            <JsonDebug value={props} />
        </div>
    );
}
//MessageIntermediateThrowEvent
export function MessageIntermediateThrowEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Información MessageIntermediateThrowEventGeneralData</h2>
            <JsonDebug value={props} />
        </div>
    );
}
//SuccessEndEvent
export function SuccessEndEventGerneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Información SuccessEndEventGerneralData</h2>
            <JsonDebug value={props} />
        </div>
    );
}
//ErrorEndEvent
export function ErrorEndEventGeneralData(props: EventGeneralDataProps) {
    return (
        <div>
            <h2>Información ErrorEndEventGeneralData</h2>
            <JsonDebug value={props} />
        </div>
    );
}
