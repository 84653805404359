import { For } from "solid-js";
import { Concept } from "../../../api/services/checklist/interface";
import CreateUpdateConceptForm from "./CreateUpdateConceptForm";

export default function HierarchicalConcepts(props: {
    conceptList?: Concept[];
    parentId: string | null;
    evaluationId: string;
    disabled: boolean;
}) {
    return (
        <div class={"space-y-4"}>
            <For each={props.conceptList?.filter(concept => concept.parent === props.parentId)}>
                {c => {
                    return (
                        <div class={"gap-y-4 border-l-4 border-light-gray-200 pl-3"}>
                            <CreateUpdateConceptForm
                                evaluationId={props.evaluationId}
                                concept={c}
                                disabled={props.disabled}
                                parentInputClass={"flex gap-x-4"}
                            />
                            <div class="ml-10">
                                <HierarchicalConcepts
                                    {...props}
                                    conceptList={props.conceptList}
                                    parentId={c.id}
                                />
                            </div>
                        </div>
                    );
                }}
            </For>
        </div>
    );
}
