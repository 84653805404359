import {
    Asset,
    Checklist,
    ChecklistCategory,
    ChecklistCategoryDetail,
    ChecklistExecutions,
    ChecklistExecutionsRetrieve,
    ChecklistFormResponse,
    ChecklistFormResponseJson,
    ChecklistFormResponsesQueryParams,
    ChecklistSections,
    ChecklistService,
    Concept,
    CreateChecklistFormResponse,
    CreateConceptParams,
    CreateConceptStepParams,
    CreateEvaluationLabelConditionPayload,
    CreateEvaluationLabelPayload,
    CreateEvaluationParams,
    CreateSectionStep,
    CreateUpdateChecklistCategory,
    EntityTargetModel,
    EvaluationFunction,
    EvaluationLabel,
    PatchChecklistSectionPayload,
    SectionStep,
    UpdateChecklistFormResponse,
    UpdateDefaultEvaluationAudiencesParams,
} from "../interface";
import { makeServiceQuery, sDateTimeString } from "../../../utils";
import { workflowClient } from "../../../../modules/client/client";
import {
    any,
    array,
    boolean,
    enums,
    Infer,
    integer,
    intersection,
    literal,
    nullable,
    number,
    optional,
    record,
    string,
    type,
    union,
} from "superstruct";
import {
    deserializeField,
    serializeField,
    serializeFormValues,
    sV3FormDescription,
} from "../../formbuilder/DynamicForm";
import { InputTypeEnum } from "../../formbuilder/interfaces/InputTypeEnum";
import { assetsClient } from "../../../clients/assets";
import { sV3WorkflowProfile } from "../../person/implementations/v3";
import _, { last } from "lodash";
import { sVenue, Venue } from "../../locations/interface";

export class V3ChecklistService implements ChecklistService {
    retrieveUniqChecklist = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/checklists/checklists/${id}/`).receiveJson(),
        responseSchema: sChecklist(),
        deserialize: deserializeChecklist,
    });
    retrieveUniqChecklistExecution = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/checklists/checklist-executions/${id}/`).receiveJson(),
        responseSchema: sChecklistExecutionRetrieve(),
        deserialize: deserializeChecklistExecutionsRetrieve,
    });
    retrieveUniqSection = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/checklists/checklist-sections/${id}/`).receiveJson(),
        responseSchema: sChecklistSection(),
        deserialize: deserializeChecklistSection,
    });
    getChecklistEntityTargetType = makeServiceQuery({
        fetchJson: async (targetEntityQuery?: string) =>
            assetsClient.get(`/logic_parser/${targetEntityQuery}`).receiveJson(),
        responseSchema: sEntityTargetType(),
        deserialize: array => array,
    });
    retrieveStep = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/forms/checklist-forms/${id}/`).receiveJson(),
        responseSchema: sV3ChecklistForm(),
        deserialize: deserializeChecklistForm,
    });
    listChecklist = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/checklists/checklists/").receiveJson(),
        responseSchema: array(sChecklist()),
        deserialize: array => array.map(deserializeChecklist),
    });

    listOwnChecklist = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/checklists/my-checklists/").receiveJson(),
        responseSchema: array(sChecklistUUID()),
        deserialize: array => array.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}),
    });

    listChecklistEntities = makeServiceQuery({
        fetchJson: async (targetEntityQuery?: string) =>
            assetsClient.get(`/logic_parser/${targetEntityQuery}/evaluate`).receiveJson(),
        responseSchema: array(sV3EntityTarget()),
        deserialize: array =>
            array.map(i => {
                if ("address" in i) return { ...i, target_entity: "venue" } as Venue;
                else if ("code" in i) return { ...i, target_entity: "asset" } as Asset;
                else return i;
            }),
    });
    listChecklistExecutions = makeServiceQuery({
        fetchJson: async () =>
            workflowClient.get("/checklists/list-checklist-executions/").receiveJson(),
        responseSchema: array(sChecklistExecution()),
        deserialize: array => array.map(deserializeChecklistExecutions),
    });
    listChecklistSections = makeServiceQuery({
        fetchJson: async (checklistID: string) =>
            workflowClient
                .get(`/checklists/checklist-sections/`)
                .query({ checklist: checklistID })
                .receiveJson(),
        responseSchema: array(sChecklistSection()),
        deserialize: array =>
            array.map(deserializeChecklistSection).sort((a, b) => a.order - b.order),
    });
    listSectionSteps = makeServiceQuery({
        fetchJson: async (sectionId: string) =>
            workflowClient
                .get("/forms/checklist-forms/")
                .query({ section: sectionId })
                .receiveJson(),
        responseSchema: array(sV3ChecklistForm()),
        deserialize: array => array.sort((a, b) => a.order - b.order).map(deserializeChecklistForm),
    });
    listFormChecklistResponses = makeServiceQuery({
        fetchJson: async (checklistExecutionID: string) =>
            workflowClient
                .get(`/forms/checklist-form-responses/`)
                .query({ checklist_execution: checklistExecutionID })
                .receiveJson(),
        responseSchema: array(sChecklistFormResponse()),
        deserialize: array => array,
    });
    listChecklistOverview = makeServiceQuery({
        fetchJson: async (checklistID: string) =>
            workflowClient.get(`/checklists/checklists/${checklistID}/overview/`).receiveJson(),
        responseSchema: array(sChecklistOverview()),
        deserialize: array => array,
    });
    listChecklistFormConceptsWithQuestion = makeServiceQuery({
        fetchJson: async (evaluationID: string) =>
            workflowClient
                .get("/checklists/checklist-form-concepts-with-question/")
                .query({ evaluation: evaluationID })
                .receiveJson(),
        responseSchema: array(sChecklistFormConceptsWithQuestion()),
        deserialize: array => array,
    });
    listGrades = makeServiceQuery({
        fetchJson: async (evaluationID: string, executionID: string) =>
            workflowClient
                .get("/checklists/grades/")
                .query({ evaluation: evaluationID, checklist_execution: executionID })
                .receiveJson(),
        responseSchema: array(sChecklistGrades()),
        deserialize: array => array,
    });
    listChecklistCategories = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/checklists/categories/").receiveJson(),
        responseSchema: array(sChecklistCategory()),
        deserialize: array => array.map(deserializeChecklistCategory),
    });

    createChecklistCategory(payload: CreateUpdateChecklistCategory): Promise<ChecklistCategory> {
        return workflowClient
            .post("/checklists/categories/")
            .sendJson(payload)
            .receive(sChecklistCategory())
            .then(deserializeChecklistCategory);
    }

    updateChecklistCategory(
        payload: Partial<CreateUpdateChecklistCategory> & { id: string },
    ): Promise<ChecklistCategory> {
        return workflowClient
            .patch(`/checklists/categories/${payload.id}/`)
            .sendJson(payload)
            .receive(sChecklistCategory())
            .then(deserializeChecklistCategory);
    }

    retrieveChecklistCategory = makeServiceQuery({
        fetchJson: async (id: string) => {
            return workflowClient.get(`/checklists/categories/${id}/`).receiveJson();
        },
        responseSchema: sChecklistCategoryDetail(),
        deserialize: deserializeChecklistCategoryDetail,
    });
    createSectionStep = async (payload: CreateSectionStep) =>
        await workflowClient
            .post("/forms/checklist-forms/")
            .sendJson(serializeChecklistForm(payload))
            .receive(any());

    updateSectionStep = async (payload: SectionStep) => {
        return await workflowClient
            .patch(`/forms/checklist-forms/${payload.id}/`)
            .sendJson(serializeChecklistForm(payload))
            .receiveNothing();
    };
    deleteSectionStep = async (id: string) =>
        await workflowClient.delete(`/forms/checklist-forms/${id}/`).receiveNothing();

    createChecklist = async (
        payload: V3CreateChecklistPayload,
    ): Promise<V3CreateChecklistResponse> =>
        await workflowClient
            .post("/checklists/checklists/")
            .sendJson(payload)
            .receive(sV3CreateChecklistResponse());
    updateChecklist = async (
        id: string,
        payload: V3CreateChecklistPayload,
    ): Promise<V3CreateChecklistResponse> =>
        await workflowClient
            .patch(`/checklists/checklists/${id}/`)
            .sendJson(payload)
            .receive(sV3CreateChecklistResponse());
    createChecklistExecutions = async (
        payload: V3CreateChecklistExecutionPayload,
    ): Promise<V3ChecklistExecutionsResponse> =>
        await workflowClient
            .post("/checklists/checklist-executions/")
            .sendJson(payload)
            .receive(sChecklistExecutionResponse());
    createChecklistSections = async (payload: V3CreateChecklistSectionsPayload) => {
        await workflowClient
            .post("/checklists/checklist-sections/")
            .sendJson(payload)
            .receiveNothing();
    };
    patchChecklistSection = async (payload: PatchChecklistSectionPayload) =>
        await workflowClient
            .patch(`/checklists/checklist-sections/${payload.id}/`)
            .sendJson(payload)
            .receive(sChecklistSection())
            .then(deserializeChecklistSection);
    deleteChecklistSection = async (id: string) =>
        await workflowClient.delete(`/checklists/checklist-sections/${id}/`).receiveNothing();
    createChecklistActivation = async (id: string) => {
        await workflowClient.put(`/checklists/checklists/${id}/activate/`).receiveNothing();
    };
    getCurrentStep = makeServiceQuery({
        fetchJson: async (executionId: string) =>
            workflowClient
                .get(`/checklists/checklist-executions/${executionId}/current-step/`)
                .receiveJson(),
        responseSchema: type({ id: nullable(string()) }),
        deserialize: x => x,
    });
    listChecklistFormResponses = makeServiceQuery({
        fetchJson: (queryParams?: ChecklistFormResponsesQueryParams) =>
            workflowClient.get("/forms/checklist-form-responses/").query(queryParams).receiveJson(),
        responseSchema: array(sChecklistFormResponse()),
        deserialize: arr => arr.map(deserializeChecklistFormResponse),
    });
    createChecklistFormResponse = async (params: CreateChecklistFormResponse) =>
        workflowClient
            .post("/forms/checklist-form-responses/")
            .sendJson({
                response: await serializeFormValues(params.formValues),
                checklist_form: params.stepId,
                checklist_execution: params.executionId,
            })
            .receive(any());
    updateChecklistFormResponse = async (params: UpdateChecklistFormResponse) =>
        workflowClient
            .put(`/forms/checklist-form-responses/${params.checklistFormResponseId}/`)
            .sendJson({
                response: await serializeFormValues(params.formValues),
            })
            .receive(any());
    completeChecklist = async (executionId: string) =>
        workflowClient
            .put(`/checklists/checklist-executions/${executionId}/complete/`)
            .receive(any());

    // #region evaluations

    updateDefaultEvaluationAudiences = async (params: UpdateDefaultEvaluationAudiencesParams) =>
        workflowClient
            .put(`/checklists/checklists/${params.checklistId}/default-evaluation-audiences/`)
            .sendJson(params)
            .receive(any());
    listEvaluations = makeServiceQuery({
        fetchJson: async (checklistID: string) =>
            workflowClient
                .get("/checklists/evaluations/")
                .query({ checklist: checklistID })
                .receiveJson(),
        responseSchema: array(sChecklistEvaluation()),
        deserialize: array => array,
    });
    listConcepts = makeServiceQuery({
        fetchJson: async (evaluationID: string) =>
            workflowClient
                .get("/checklists/concepts/")
                .query({ evaluation: evaluationID })
                .receiveJson(),
        responseSchema: array(sConcept()),
        deserialize: array => array.reverse().map(deserializeConcept),
    });
    listConceptSteps = makeServiceQuery({
        fetchJson: async (conceptID: string) =>
            workflowClient
                .get("/checklists/checklist-form-concepts/")
                .query({ concept: conceptID })
                .receiveJson(),
        responseSchema: array(sChecklistFormConcept()),
        deserialize: array => _.keyBy(array, "checklist_form"),
    });

    createEvaluation = async (params: CreateEvaluationParams) => {
        await workflowClient.post("/checklists/evaluations/").sendJson(params).receiveNothing();
    };

    updateEvaluation = async (params: CreateEvaluationParams & { id: string }) => {
        await workflowClient
            .put(`/checklists/evaluations/${params.id}/`)
            .sendJson(params)
            .receiveNothing();
    };

    activateEvaluation = async (id: string) => {
        await workflowClient.post(`/checklists/evaluations/${id}/activate/`).receiveNothing();
    };
    createConcept = async (params: CreateConceptParams) => {
        await workflowClient.post("/checklists/concepts/").sendJson(params).receiveNothing();
    };

    updateConcept = async (params: Partial<CreateConceptParams> & { id: string }) => {
        await workflowClient
            .patch(`/checklists/concepts/${params.id}/`)
            .sendJson(params)
            .receive(sConcept());
    };

    createConceptStep = async (params: CreateConceptStepParams) => {
        await workflowClient
            .post("/checklists/checklist-form-concepts/")
            .sendJson(params)
            .receiveNothing();
    };

    // #endregion

    //Evaluation Labels
    listEvaluationLabels = makeServiceQuery({
        fetchJson: async (evaluationID: string) =>
            workflowClient
                .get(`/checklists/evaluation-labels/?evaluation=${evaluationID}`)
                .receiveJson(),
        responseSchema: array(sEvaluationLabel()),
        deserialize: array => array.map(deserializeEvaluationLabel),
    });

    retrieveEvaluationLabel = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/checklists/evaluation-labels/${id}/`).receiveJson(),
        responseSchema: sEvaluationLabel(),
        deserialize: deserializeEvaluationLabel,
    });

    createEvaluationLabel = async (payload: CreateEvaluationLabelPayload) => {
        return await workflowClient
            .post("/checklists/evaluation-labels/")
            .sendJson(payload)
            .receive(sEvaluationLabel())
            .then(deserializeEvaluationLabel);
    };

    patchEvaluationLabel = async (
        payload: Partial<CreateEvaluationLabelPayload> & { id: string },
    ) => {
        return await workflowClient
            .patch(`/checklists/evaluation-labels/${payload.id}/`)
            .sendJson(payload)
            .receive(sEvaluationLabel())
            .then(deserializeEvaluationLabel);
    };

    putEvaluationLabel = async (payload: CreateEvaluationLabelPayload & { id: string }) => {
        return await workflowClient
            .put(`/checklists/evaluation-labels/${payload.id}/`)
            .sendJson(payload)
            .receive(sEvaluationLabel())
            .then(deserializeEvaluationLabel);
    };

    deleteEvaluationLabel = async (id: string) => {
        return await workflowClient.delete(`/checklists/evaluation-labels/${id}/`).receiveNothing();
    };

    listEvaluationLabelConditions = makeServiceQuery({
        fetchJson: async (evaluationID: string) =>
            workflowClient
                .get(`/checklists/evaluation-label-conditions/?evaluation=${evaluationID}`) // ?evaluation=${evaluationID}
                .receiveJson(),
        responseSchema: array(sEvaluationLabelCondition()),
        deserialize: array => array,
    });
    createEvaluationLabelCondition = async (payload: CreateEvaluationLabelConditionPayload) => {
        return await workflowClient
            .post("/checklists/evaluation-label-conditions/")
            .sendJson(payload)
            .receive(sEvaluationLabelCondition());
    };
    patchEvaluationLabelCondition = async (
        payload: Partial<CreateEvaluationLabelConditionPayload> & { id: string },
    ) => {
        return await workflowClient
            .patch(`/checklists/evaluation-label-conditions/${payload.id}/`)
            .sendJson(payload)
            .receive(sEvaluationLabelCondition());
    };
    deleteEvaluationLabelCondition = async (id: string) => {
        return await workflowClient
            .delete(`/checklists/evaluation-label-conditions/${id}/`)
            .receiveNothing();
    };
}

function sChecklistUUID() {
    return type({
        id: string(),
    });
}

// type V3ChecklistUUID = Infer<ReturnType<typeof sChecklistUUID>>;

type V3Checklist = Infer<ReturnType<typeof sChecklist>>;
function sChecklist() {
    return type({
        id: string(),
        created_at: optional(sDateTimeString()),
        updated_at: optional(sDateTimeString()),
        is_active: boolean(),
        name: string(),
        execution_audience: nullable(string()),
        color: string(),
        min_grade: number(),
        max_grade: number(),
        step_grade: number(),
        target_entity_query: string(),
        category: string(),
    });
}

function deserializeChecklist(payload: V3Checklist): Checklist {
    return {
        ...payload,
        created_at: payload.created_at ? Temporal.Instant.from(payload.created_at) : undefined,
        updated_at: payload.updated_at ? Temporal.Instant.from(payload.updated_at) : undefined,
    };
}

type V3ChecklistExecutionsResponse = Infer<ReturnType<typeof sChecklistExecutionResponse>>;
function sChecklistExecutionResponse() {
    return type({
        id: string(),
        // created_at: Temporal.Instant;
        // updated_at: Temporal.Instant;
        checklist: string(),
        completed: optional(nullable(string())),
    });
}

type V3ChecklistSection = Infer<ReturnType<typeof sChecklistSection>>;
function sChecklistSection() {
    return type({
        id: string(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        name: string(),
        order: number(),
        checklist: string(),
    });
}

function deserializeChecklistSection(payload: V3ChecklistSection): ChecklistSections {
    return {
        ...payload,
        created_at: Temporal.Instant.from(payload.created_at),
        updated_at: Temporal.Instant.from(payload.updated_at),
    };
}

type V3CreateChecklistPayload = Infer<ReturnType<typeof sV3CreateChecklistPayload>>;
function sV3CreateChecklistPayload() {
    return type({
        name: string(),
        execution_audience: nullable(string()),
        color: string(),
        min_grade: number(),
        max_grade: number(),
        step_grade: number(),
    });
}

type V3CreateChecklistResponse = Infer<ReturnType<typeof sV3CreateChecklistResponse>>;
function sV3CreateChecklistResponse() {
    return type({
        id: string(),
    });
}

type V3CreateChecklistExecutionPayload = Infer<
    ReturnType<typeof sV3CreateChecklistExecutionPayload>
>;
function sV3CreateChecklistExecutionPayload() {
    return type({
        checklist: string(),
        target_entity: string(),
    });
}

type V3CreateChecklistSectionsPayload = Infer<ReturnType<typeof sV3CreateChecklistSectionsPayload>>;

function sV3CreateChecklistSectionsPayload() {
    return type({
        name: string(),
        order: number(),
        checklist: string(),
    });
}

type V3ChecklistForm = Infer<ReturnType<typeof sV3ChecklistForm>>;

function sV3ChecklistForm() {
    return intersection([
        sV3CreateSectionStepPayload(),
        type({
            id: string(),
            created_at: sDateTimeString(),
            updated_at: sDateTimeString(),
        }),
    ]);
}

function sV3CreateSectionStepPayload() {
    return union([
        // non evaluable
        intersection([
            sV3BaseChecklistForm(),
            type({
                evaluable: literal(false),
                metadata: type({}),
            }),
        ]),

        // evaluable num_range
        intersection([
            sV3BaseChecklistForm(),
            type({
                evaluable: literal(true),
                main_field_type: literal(InputTypeEnum.value_in_num_range),
                metadata: type({}),
            }),
        ]),

        // evaluable multiple_choice
        intersection([
            sV3BaseChecklistForm(),
            type({
                evaluable: literal(true),
                main_field_type: literal(InputTypeEnum.multiple_choice),
                metadata: type({
                    choices_with_grade: array(sV3ChoiceWithGrade()),
                }),
            }),
        ]),

        // evaluable checkboxes
        intersection([
            sV3BaseChecklistForm(),
            type({
                evaluable: literal(true),
                main_field_type: literal(InputTypeEnum.checkboxes),
                metadata: type({
                    choices_with_grade: array(sV3ChoiceWithGrade()),
                    evaluation_function: enums(Object.values(EvaluationFunction)),
                }),
            }),
        ]),
    ]);
}

function sV3BaseChecklistForm() {
    return type({
        description: sV3FormDescription(),
        order: integer(),
        main_field_name: string(),
        main_field_type: enums([
            InputTypeEnum.value_in_num_range,
            InputTypeEnum.multiple_choice,
            InputTypeEnum.checkboxes,
        ]),
        evaluable: boolean(),
        section: string(),
        metadata: type({}),
    });
}

function deserializeChecklistForm(payload: V3ChecklistForm): SectionStep {
    const mainFieldDescription = payload.description.fields.find(
        field => field.name === payload.main_field_name,
    );
    if (!mainFieldDescription)
        throw new Error(
            `main_field_name is "${payload.main_field_name}" but no field with that name was found.`,
        );

    return {
        id: payload.id,
        fields: payload.description.fields
            .filter(field => field !== mainFieldDescription)
            .map(deserializeField),
        // createdAt: Temporal.Instant.from(payload.created_at),
        updatedAt: Temporal.Instant.from(payload.updated_at),
        mainField: deserializeField(mainFieldDescription) as SectionStep["mainField"],
        evaluable: payload.evaluable,
        metadata: payload.metadata,
        sectionId: payload.section,
        order: payload.order,
    };
}

function serializeChecklistForm(
    params: CreateSectionStep | SectionStep,
): V3CreateSectionStepPayload {
    return {
        section: params.sectionId,
        order: params.order,
        description: {
            fields: [
                serializeField(params.mainField),
                ...("fields" in params ? params.fields : []),
            ],
        },
        main_field_name: params.mainField.name,
        main_field_type: params.mainField.type,
        /* Using `as false` as a hack so TypeScript trust the params.
         * The params should be correct as they come from the Create Step form.
         * Making it completely type-safe would make the rest of the code too complex. */
        evaluable: params.evaluable as false,
        metadata: params.metadata,
    };
}

function sV3ChoiceWithGrade() {
    return type({
        choice: string(),
        grade: integer(),
    });
}

type V3CreateSectionStepPayload = Infer<ReturnType<typeof sV3CreateSectionStepPayload>>;

export function sV3EntityTarget() {
    return union([sVenue(), sAsset()]);
}

export function sAsset() {
    return type({
        category: nullable(optional(union([string(), number()]))),
        code: nullable(string()),
        form_response: nullable(any()),
        id: string(),
        maintenance_status: string(),
        name: nullable(string()),
        qr_code: nullable(string()),
        type: optional(nullable(integer())),
        type_name: string(),
        venue: nullable(string()),
        venue_name: string(),
    });
}

function sEntityTargetType() {
    return type({
        id: string(),
        model: enums([EntityTargetModel.asset, EntityTargetModel.venue]),
    });
}

function sChecklistFormResponse() {
    return type({
        id: string(),
        response: record(string(), any()),
        grade: nullable(string()),
        checklist_form: string(),
        user: number(),
        checklist_execution: string(),
    });
}

function deserializeChecklistFormResponse(json: ChecklistFormResponseJson): ChecklistFormResponse {
    return json;
}

function sChecklistOverview() {
    return type({
        id: string(),
        name: string(),
        checklist_forms: array(string()),
    });
}

type V3ChecklistExecution = Infer<ReturnType<typeof sChecklistExecution>>;
function sChecklistExecution() {
    return type({
        id: string(),
        checklist_name: string(),
        user: sV3WorkflowProfile(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        target_entity: string(),
        checklist: string(),
        completed: nullable(type({ created_at: sDateTimeString() })),
    });
}

type V3ChecklistExecutionRetrieve = Infer<ReturnType<typeof sChecklistExecutionRetrieve>>;
function sChecklistExecutionRetrieve() {
    return type({
        id: string(),
        checklist: string(),
        created_at: sDateTimeString(),
        completed: nullable(type({ created_at: sDateTimeString() })),
        user: sV3WorkflowProfile(),
    });
}

function deserializeChecklistExecutions(execution: V3ChecklistExecution): ChecklistExecutions {
    return {
        ...execution,
        user: {
            name: `${execution.user.first_name} ${execution.user.last_name}`,
            email: execution.user.email,
            pictureUrl: null,
        },
        created_at: Temporal.Instant.from(execution.created_at),
        updated_at: Temporal.Instant.from(execution.updated_at),
        completed_at: execution.completed
            ? Temporal.Instant.from(execution.completed.created_at)
            : null,
    };
}
function deserializeChecklistExecutionsRetrieve(
    execution: V3ChecklistExecutionRetrieve,
): ChecklistExecutionsRetrieve {
    return {
        ...execution,
        user: {
            name: `${execution.user.first_name} ${execution.user.last_name}`,
            email: execution.user.email,
            pictureUrl: null,
        },
        created_at: Temporal.Instant.from(execution.created_at),
        completed_at: execution.completed
            ? Temporal.Instant.from(execution.completed.created_at)
            : null,
    };
}

function sChecklistFormConcept() {
    return type({
        id: string(),
        order: integer(),
        weight: integer(),
        checklist_form: string(),
        concept: string(),
    });
}

function sChecklistFormConceptsWithQuestion() {
    return intersection([
        sChecklistFormConcept(),
        type({
            checklist_form_question: string(),
        }),
    ]);
}

function sChecklistGrades() {
    return type({
        id: string(),
        final_grade: number(),
        grades: array(
            type({
                id: string(),
                grade: string(),
                weight: integer(),
            }),
        ),
        evaluation: string(),
        checklist_execution: string(),
    });
}

type V3ChecklistCategory = Infer<ReturnType<typeof sChecklistCategory>>;
type V3ChecklistCategoryDetail = Infer<ReturnType<typeof sChecklistCategoryDetail>>;
function sChecklistCategory() {
    return type({
        id: string(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        name: string(),
        icon: string(),
        description: string(),
        starred: boolean(),
        parent: nullable(string()),
    });
}
function sChecklistCategoryDetail() {
    return intersection([
        sChecklistCategory(),
        type({
            is_deletable: boolean(),
        }),
    ]);
}
function deserializeChecklistCategory(payload: V3ChecklistCategory): ChecklistCategory {
    return {
        ...payload,
        created_at: Temporal.Instant.from(payload.created_at),
        updated_at: Temporal.Instant.from(payload.updated_at),
    };
}
function deserializeChecklistCategoryDetail(
    payload: V3ChecklistCategoryDetail,
): ChecklistCategoryDetail {
    return {
        ...payload,
        created_at: Temporal.Instant.from(payload.created_at),
        updated_at: Temporal.Instant.from(payload.updated_at),
    };
}
/** Evaluations **/

function sChecklistEvaluation() {
    return type({
        id: string(),
        name: string(),
        use_percentages: boolean(),
        report_audience: string(),
        read_audience: string(),
        is_active: boolean(),
        checklist: string(),
        default: boolean(),
    });
}

type V3Concept = Infer<ReturnType<typeof sConcept>>;

function sConcept() {
    return type({
        id: string(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        name: string(),
        weight: integer(),
        evaluation: string(),
        parent: nullable(string()),
    });
}

function deserializeConcept(payload: V3Concept): Concept {
    return {
        ...payload,
        created_at: Temporal.Instant.from(payload.created_at),
        updated_at: Temporal.Instant.from(payload.updated_at),
    };
}

export function after(currentItems: { order: number }[]): number {
    return (last(currentItems)?.order ?? 0) + 1;
}

export enum FileExplorerMode {
    LIST = "list",
    FOLDER = "folder",
}

export function sFileExplorer() {
    return type({
        mode: enums(Object.values(FileExplorerMode)),
        sidePanel: boolean(),
    });
}

function sEvaluationLabel() {
    return type({
        id: string(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        name: string(),
        icon: string(),
        color: string(),
        evaluation: string(),
    });
}
type V3EvaluationLabel = Infer<ReturnType<typeof sEvaluationLabel>>;
function deserializeEvaluationLabel(raw: V3EvaluationLabel): EvaluationLabel {
    return raw;
}

function sEvaluationLabelCondition() {
    return type({
        id: string(),
        min_grade: number(),
        max_grade: number(),
        evaluation_label: string(),
    });
}
