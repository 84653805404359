import { Infer, string, type } from "superstruct";

export function sAudience() {
    return type({
        id: string(),
        name: string(),
        description: string(),
        type: string(),
        expression: string(),
        tenant: string(),
    });
}

export type Audience = Infer<ReturnType<typeof sAudience>>;

export function sCreateUpdateAudience() {
    return type({
        name: string(),
        description: string(),
        type: string(),
        expression: string(),
    });
}

export type CreateUpdateAudience = Infer<ReturnType<typeof sCreateUpdateAudience>>;
