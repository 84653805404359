import { P } from "../../../../utils/typography";
import { EventGeneralDataProps } from "../ExecutionEvents";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ExecutionTimeframe(props: EventGeneralDataProps) {
    return (
        <div>
            <P>Plazo de ejecución</P>
            <P>Desde el FECHAX hasta el FECHAY</P>
        </div>
    );
}
