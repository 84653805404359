import { PageWrapper } from "../ui/pageWrappers";
import { P } from "../../utils/typography";
import { useNavigate, useParams } from "@solidjs/router";
import {
    createChecklistExecutionRetrieveQuery,
    createChecklistOverviewQuery,
    createChecklistRetrieveQuery,
    createCurrentStepQuery,
    createListChecklistFormResponsesQuery,
    createRetrieveStep,
    createRetrieveUniqSection,
} from "../../api/services/checklist/queries";
import { Show } from "solid-js";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import { StepFields } from "./checklistExecutions/utils";
import {
    createChecklistFormResponseMutation,
    createCompleteChecklistMutation,
    createUpdateChecklistFormResponseMutation,
} from "../../api/services/checklist/mutations";
import { FormValues } from "../forms/state";
import { Button } from "../ui/components";
import {
    ChecklistFormResponse,
    ChecklistStepOverview,
    SectionStep,
} from "../../api/services/checklist/interface";
import {
    ChecklistExecutionContext,
    ChecklistExecutionContextProvider,
} from "./ChecklistExecutionContext";
import { useRequiredContext } from "../../utils/solidjs";
import ChecklistExecutionBackButton, {
    getNextStepId,
} from "./checklistExecutions/ChecklistExecutionBackButton";
import ChecklistOutline from "./ChecklistOutline";
import { getApiInstance } from "../../api";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";

export default function ChecklistExecutionsExecutePage() {
    const params = useParams();
    const executionID = () => params.execution_id;
    const getExecutionQuery = createChecklistExecutionRetrieveQuery(executionID);
    const currentStepQuery = createCurrentStepQuery(executionID);

    return (
        <PageWrapper>
            <Show when={getExecutionQuery.data}>
                {data => (
                    <Show when={currentStepQuery.data}>
                        {step => (
                            <ChecklistExecutionContextProvider
                                executionId={executionID()}
                                checklistId={data().checklist}
                                defaultStepId={step().id}
                            >
                                <div class="flex max-w-full gap-x-10">
                                    <div class="flex-1">
                                        <ChecklistExecutionsDetail
                                            executionID={executionID()}
                                            checklistId={data().checklist}
                                        />
                                    </div>
                                    <div class="w-80">
                                        <ChecklistOutline checklistId={data().checklist} />
                                    </div>
                                </div>
                            </ChecklistExecutionContextProvider>
                        )}
                    </Show>
                )}
            </Show>
        </PageWrapper>
    );
}

function ChecklistExecutionsDetail(props: { executionID: string; checklistId: string }) {
    const { checklistId, currentStepId } = useRequiredContext(
        ChecklistExecutionContext,
        "ChecklistExecutionsDetail",
        "ChecklistExecutionContextProvider",
    );
    const getChecklist = createChecklistRetrieveQuery(checklistId);

    return (
        <div>
            <Show when={getChecklist.data}>
                {data => {
                    return (
                        <div>
                            <P class={"text-display-xs font-medium"}>Checklist: {data().name}</P>
                        </div>
                    );
                }}
            </Show>
            <Show
                when={currentStepId()}
                fallback={
                    <AllStepsCompleted
                        executionId={props.executionID}
                        checklistId={props.checklistId}
                    />
                }
            >
                {stepId => (
                    <SectionStepRender
                        executionID={props.executionID}
                        stepID={stepId()}
                        checklistId={props.checklistId}
                    />
                )}
            </Show>
        </div>
    );
}

function SectionStepRender(props: { executionID: string; stepID: string; checklistId: string }) {
    const getSectionStep = createRetrieveStep(() => props.stepID);

    return (
        <Show when={getSectionStep.data}>
            {step => {
                return <SectionStepForm step={step()} />;
            }}
        </Show>
    );
}

function SectionStepForm(props: { step: SectionStep }) {
    const ctx = useRequiredContext(
        ChecklistExecutionContext,
        "SectionStepForm",
        "ChecklistExecutionContextProvider",
    );
    const prevResponsesQuery = createListChecklistFormResponsesQuery(() => ({
        checklist_execution: ctx.executionId(),
    }));

    return (
        <Show
            when={prevResponsesQuery.data}
            fallback={<ErrorBlock error={prevResponsesQuery.error} />}
        >
            {prevResponses => (
                <SectionStepFormContent
                    step={props.step}
                    response={prevResponses().find(
                        response => response.checklist_form === ctx.currentStepId(),
                    )}
                />
            )}
        </Show>
    );
}

function SectionStepFormContent(props: {
    step: SectionStep;
    response: ChecklistFormResponse | undefined;
}) {
    const ctx = useRequiredContext(
        ChecklistExecutionContext,
        "SectionStepFormContent",
        "ChecklistExecutionContextProvider",
    );
    const sectionStepQuery = createRetrieveUniqSection(() => props.step.sectionId);
    const overviewQuery = createChecklistOverviewQuery(ctx.checklistId);
    const api = getApiInstance();
    const createResponseMutation = createChecklistFormResponseMutation();
    const updateResponseMutation = createUpdateChecklistFormResponseMutation();
    const handleSubmit = async (formValues: FormValues, overview: ChecklistStepOverview[]) => {
        const currentStepId = ctx.currentStepId();
        if (currentStepId == null) return;

        if (currentStepId === ctx.defaultStepId()) {
            await createResponseMutation.mutateAsync({
                executionId: ctx.executionId(),
                stepId: props.step.id,
                formValues,
            });
        } else {
            const prevResponses = await api.checklist.listChecklistFormResponses({
                checklist_execution: ctx.executionId(),
            });
            const response = prevResponses.find(
                response => response.checklist_form === props.step.id,
            );
            if (!response)
                throw new Error(
                    `checklist-form-response for checklist-form "${props.step.id}" not found`,
                );
            await updateResponseMutation.mutateAsync({
                executionId: ctx.executionId(),
                checklistFormResponseId: response.id,
                formValues,
            });
            ctx.setCurrentStepId(getNextStepId(currentStepId, overview));
        }
    };

    return (
        <Show when={overviewQuery.data} fallback={<ErrorBlock error={overviewQuery.error} />}>
            {overview => (
                <div>
                    <Show when={sectionStepQuery.data}>
                        {section => <P class={"text-lg font-medium"}>{section().name}</P>}
                    </Show>
                    <Show keyed when={props.step.id}>
                        <FormWrapper
                            onSubmit={values => handleSubmit(values, overview())}
                            defaultValues={props.response?.response}
                            class="pl-8"
                        >
                            <StepFields step={props.step} />
                            <div class="mt-4 flex w-full items-stretch justify-center gap-x-3">
                                <div class="flex-1">
                                    <ChecklistExecutionBackButton checklistId={ctx.checklistId()} />
                                </div>
                                <SubmitButton class="w-64">Continuar</SubmitButton>
                                <div class="flex-1" />
                            </div>
                        </FormWrapper>
                    </Show>
                </div>
            )}
        </Show>
    );
}

function AllStepsCompleted(props: { executionId: string; checklistId: string }) {
    const completeMutation = createCompleteChecklistMutation();
    const navigate = useNavigate();

    const completeChecklist = async () => {
        if (
            confirm("¿Está seguro de completar el checklist? Esta operación no se puede deshacer.")
        ) {
            await completeMutation.mutateAsync(props.executionId);
            navigate("/activities");
        }
    };

    return (
        <div class="flex flex-col items-center gap-y-6 py-12">
            <i class="fas fa-check-circle text-display-md text-success-500" />
            <P class="text-lg">Has completado todos los pasos</P>
            <div class="flex max-w-120 items-stretch justify-center gap-x-3">
                <div class="flex-1">
                    <ChecklistExecutionBackButton checklistId={props.checklistId} />
                </div>
                <Button onClick={completeChecklist}>Finalizar checklist</Button>
                <div class="flex-1" />
            </div>
        </div>
    );
}
