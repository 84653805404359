import { useParams } from "@solidjs/router";
import { Show } from "solid-js";
import {
    createFormByBpmnElementQuery,
    createFormResponseQuery,
} from "../../../../api/services/workflow/queries";
import { EventGeneralDataProps } from "../ExecutionEvents";
import { WorkflowForm } from "../../../../api/services/workflow/interface";
import { P } from "../../../../utils/typography";
import { RenderDynamicFields } from "../../../FormBuilder/RenderDynamicForm";
import { FormWrapper } from "../../../forms/FormWrapper";

export function ActivityFormWithResponses(props: EventGeneralDataProps) {
    const params = useParams<{ id: string }>();
    const elementsByIdQuery = createFormByBpmnElementQuery(() => ({
        bpmnElementId: props.element.bpmnElementId,
        workflowId: props.workflowId,
    }));

    return (
        <div>
            <Show when={elementsByIdQuery.data}>
                {wfForm => <FormWithResponses form={wfForm()} executionId={params.id} />}
            </Show>
        </div>
    );
}

function FormWithResponses(props: { form: WorkflowForm; executionId: string }) {
    const formResponsesQuery = createFormResponseQuery(() => ({
        executionId: props.executionId,
        formId: props.form.id,
    }));
    return (
        <div class="rounded-md border-2">
            <Show when={formResponsesQuery.data}>
                {formResponse => (
                    <Show when={formResponse().formValues} fallback={<P>-- No hay respuesta --</P>}>
                        {formValues => (
                            <FormWrapper readOnly defaultValues={formValues()}>
                                <RenderDynamicFields fields={props.form.fields} />
                            </FormWrapper>
                        )}
                    </Show>
                )}
            </Show>
        </div>
    );
}
