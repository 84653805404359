import {
    deserializeOrganizationChartGraph,
    OrganizationChartGraphParams,
    PositionService,
    sOrganizationChartGraph,
    sPosition,
} from "../interface";
import { makeServiceQuery } from "../../../utils";
import { newPersonClient } from "../../person/person";
import { array } from "superstruct";

export class V3PositionService implements PositionService {
    listPositions = makeServiceQuery({
        fetchJson: async () => newPersonClient.get("/positions/").receiveJson(),
        responseSchema: array(sPosition()),
        deserialize: x => x,
    });

    retrieveOrganizationChartGraph = makeServiceQuery({
        fetchJson: async (params: OrganizationChartGraphParams) =>
            newPersonClient.get("/organization-chart/graph/").query(params).receiveJson(),
        responseSchema: sOrganizationChartGraph(),
        deserialize: deserializeOrganizationChartGraph,
    });
}
