import {
    ActivityItem,
    ActivityTask,
    Calendar,
    CreateActivityFormResponseParams,
    CreateActivityPayload,
    CreateActivityTaskPayload,
    deserializeActivityForm,
    RecurrenceFormResponsePayload,
    sActivityFormJson,
    sCreateActivityTaskPayload,
    sRecurrenceActivity,
    sRecurrenceTask,
    TaskManagerService,
} from "../interface";
import { makeServiceQuery } from "../../../utils";
import { workflowClient } from "../../../../modules/client/client";
import { any, array, boolean, Infer, string, type } from "superstruct";
import ICAL from "ical.js";
import {
    ActivitySet,
    decomposeCalendar,
    decomposeTodo,
    VTodoRecurrence,
} from "../../../../modules/Activities/ActivitiesPlaygroundPage";
import {
    deserializeFormResponse,
    serializeFormValues,
    sV3FormResponse,
} from "../../formbuilder/DynamicForm";

export default class V3TaskManagerService implements TaskManagerService {
    //AKA get or create recurrence activity
    getOrCreateRecurrenceActivity = makeServiceQuery({
        fetchJson: async (activityId: string, recurrenceId: string) => {
            const allRecurrenceActivities = await workflowClient
                .get("/activities/recurrence-activities/")
                .receive(array(sRecurrenceActivity()));
            const found = allRecurrenceActivities.find(
                item => item.activity === activityId && item.recurrence_id === recurrenceId,
            );
            if (found) {
                return found;
            } else {
                return this.createRecurrenceActivity({
                    activity: activityId,
                    recurrence_id: recurrenceId,
                });
            }
        },
        responseSchema: sRecurrenceActivity(),
        deserialize: recurrenceActivity => recurrenceActivity,
    });

    retrieveActivityDetail = makeServiceQuery({
        fetchJson: async (activityId: string, recurrenceId: string) => {
            return {
                activityDetail: await workflowClient
                    .get(`/activities/activities/${activityId}/`)
                    .receiveJson(),
                recurrenceId,
            };
        },
        responseSchema: type({
            activityDetail: sV3RawActivity(),
            recurrenceId: string(),
        }),
        deserialize: object => {
            //deserializeRawActivity(rawActivity)
            const vtodo = decomposeTodo(
                new ICAL.Component(ICAL.parse(object.activityDetail.vtodo)),
            );
            const activity = ActivityItem.fromVTodo(
                vtodo as VTodoRecurrence,
                Temporal.Instant.from(object.recurrenceId).toZonedDateTimeISO(
                    Temporal.Now.timeZoneId(),
                ),
                object.activityDetail.name || vtodo.summary,
                object.activityDetail.execution_audience,
            );
            return activity;
        },
    });

    retrieveActivities = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/activities/activities/").receiveJson(),
        responseSchema: array(sV3RawActivity()),
        deserialize: rawActivities => {
            const activitySets: ActivitySet[] = deserializeRawActivities(rawActivities);
            return new V3Calendar(activitySets);
        },
    });

    retrieveMyCalendar = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/activities/calendar/").receiveJson(),
        responseSchema: type({ calendar: string() }),
        deserialize: payload => {
            try {
                const vCalendar = decomposeCalendar(
                    new ICAL.Component(ICAL.parse(payload.calendar)),
                );
                // if (vCalendar.todos.some(todo => !todo.rrule || todo.recurrenceId)) {
                //     throw new Error(
                //         "Only todos with rrule and no recurrenceId are supported by the frontend right now",
                //     );
                // }
                const activityRecurrences = vCalendar.todos
                    .filter(item => item.rrule && !item.recurrenceId)
                    .map(
                        todoRecurrence =>
                            new ActivitySet(
                                todoRecurrence as VTodoRecurrence,
                                todoRecurrence.summary,
                            ),
                    );
                return new V3Calendar(activityRecurrences);
            } catch (e) {
                console.error(e);
                throw new Error("Error fetching calendar");
            }
        },
    });

    async createActivity(activity: CreateActivityPayload) {
        console.log("Creating activity", activity);
        const schema = sCreateActivityTaskPayload();
        if (!schema.validate(activity)) {
            throw new Error("Invalid payload");
        }
        try {
            const res = await workflowClient
                .post("/activities/activities/")
                .sendJson(activity)
                .receive(sV3RawActivity());
            return { id: res.id };
        } catch (e) {
            console.error(e);
            throw new Error("Error creating activity");
        }
    }
    async createActivityTask(payload: CreateActivityTaskPayload) {
        const schema = sCreateActivityTaskPayload();
        if (!schema.validate(payload)) {
            throw new Error("Invalid payload");
        }
        try {
            const res = await workflowClient
                .post(`/activities/activity-tasks/`)
                .sendJson(payload)
                .receive(sV3ActivityTask());
            return { id: res.id };
        } catch (e) {
            throw new Error("Not implemented");
        }
    }

    async activateActivity(activityId: string) {
        const res = await workflowClient.patch(`/activities/${activityId}/activate/`).receiveJson();
        try {
            if (res) {
                console.log("Activity activated successfully");
            }
        } catch (e) {
            throw new Error(`Error activating activity: ${e}`);
        }
    }

    getActivityTasks = makeServiceQuery({
        fetchJson: async (activityId: string) =>
            workflowClient
                .get("/activities/activity-tasks/")
                .query({
                    activity: activityId,
                })
                .receiveJson(),
        responseSchema: array(sV3ActivityTask()),
        deserialize: tasks => tasks.map(deserializeActivityTask),
    });

    async createRecurrenceActivity(payload: V3CreateRecurrenceActivityPayload) {
        try {
            return await workflowClient
                .post("/activities/recurrence-activities/")
                .sendJson(payload)
                .receive(sV3CreateRecurrenceActivityResponse());
        } catch (e) {
            throw new Error("Error fetching recurrence activities");
        }
    }

    async startEvent(payload: V3RecurrenceTaskStartWorkflowPayload) {
        try {
            const res = await workflowClient
                .post(`/recurrence-tasks/start-event/`)
                .sendJson(payload)
                .receive(sV3RecurrenceTaskStartWorkflow());

            console.log("Recurrence task started successfully", res);
        } catch (e) {
            throw new Error(`Error starting event ${e}`);
        }
    }

    async messageStartEvent(payload: V3MessageStartEventPayload) {
        try {
            const res = await workflowClient
                .post(`/activities/recurrence-tasks/message-start-event/`)
                .sendJson(payload)
                .receive(sV3MessageStartEventPayload());

            console.log("Message event started successfully", res);
        } catch (e) {
            throw new Error(`Error starting message event ${e}`);
        }
    }

    async checklistExecution(payload: V3ChecklistExecutionStartEventPayload) {
        try {
            const res = await workflowClient
                .post(`/activities/recurrence-tasks/checklist-execution/`)
                .sendJson(payload)
                .receive(sV3ChecklistExecutionStartEventPayload());

            console.log("Checklist execution started successfully", res);
        } catch (e) {
            throw new Error(`Error starting checklist execution ${e}`);
        }
    }

    async formResponse(params: CreateActivityFormResponseParams) {
        try {
            const res = await workflowClient
                .post(`/activities/recurrence-tasks/form-response/`)
                .sendJson(params)
                .receive(any());

            console.log("Form response started successfully", res);
        } catch (e) {
            throw new Error(`Error starting form response ${e}`);
        }
    }

    retrieveForm = makeServiceQuery({
        fetchJson: async (formId: string) =>
            workflowClient.get(`/forms/forms/${formId}`).receiveJson(),
        responseSchema: sActivityFormJson(),
        deserialize: deserializeActivityForm,
    });

    getRecurrenceTasks = makeServiceQuery({
        fetchJson: async (recurrenceActivityId: string) =>
            workflowClient
                .get(`/activities/recurrence-tasks/`)
                .query({ recurrence_activity: recurrenceActivityId })
                .receiveJson(),
        responseSchema: array(sRecurrenceTask()),
        deserialize: x => x,
    });

    retrieveFormResponse = makeServiceQuery({
        fetchJson: async (formResponseId: string) =>
            workflowClient.get(`/forms/form-responses/${formResponseId}`).receiveJson(),
        responseSchema: sV3FormResponse(),
        deserialize: deserializeFormResponse,
    });

    createRecurrenceTaskWFFormResponse = async (payload: RecurrenceFormResponsePayload) => {
        try {
            return workflowClient
                .post("/activities/recurrence-tasks/workflow-form-response/")
                .sendJson({
                    ...payload,
                    response: await serializeFormValues(payload.response),
                })
                .receive(
                    type({
                        recurrence_activity: string(),
                        task: string(),
                    }),
                );
        } catch (e) {
            throw new Error("Error creating recurrence task workflow form response");
        }
    };

    completeRecurrenceActivity(recurrenceActivityId: string): void {
        try {
            workflowClient
                .put(`/activities/recurrence-activities/${recurrenceActivityId}/complete/`)
                .receiveNothing();
        } catch (e) {
            throw new Error(`Error completing recurrence activity ${e}`);
        }
    }

    activitiesByExecution = makeServiceQuery({
        fetchJson: async (executionId: string) =>
            workflowClient
                .get(`/activities/executions/${executionId}/activities/`)
                .query({
                    execution: executionId,
                })
                .receiveJson(),
        responseSchema: array(sV3ActivityByExecution()),
        deserialize: rawActivities => rawActivities,
    });
}
export function sV3ActivityByExecution() {
    return type({
        id: string(),
        name: string(),
        is_active: boolean(),
        user_task: string(),
    });
}
export function sV3ActivityTask() {
    return type({
        id: string(),
        activity: string(),
        object_id: string(),
        content_type_model: string(),
    });
}
type V3ActivityTask = Infer<ReturnType<typeof sV3ActivityTask>>;
function deserializeActivityTask(raw: V3ActivityTask): ActivityTask {
    return raw;
}

function sV3CreateRecurrenceActivityResponse() {
    return type({
        id: string(),
        activity: string(),
        recurrence_id: string(),
    });
}
function sV3RecurrenceTaskStartWorkflow() {
    return type({
        start_event: string(),
        workflow: string(),
        recurrence_activity: string(),
        task: string(),
    });
}
function sV3CreateRecurrenceActivityPayload() {
    return type({
        activity: string(),
        recurrence_id: string(),
    });
}

function sV3MessageStartEventPayload() {
    return type({
        start_event: string(),
        workflow: string(),
        recurrence_activity: string(),
        task: string(),
        data: string(),
    });
}

function sV3ChecklistExecutionStartEventPayload() {
    return type({
        recurrence_activity: string(),
        task: string(),
        object_id: string(),
    });
}

type V3CreateRecurrenceActivityPayload = Infer<
    ReturnType<typeof sV3CreateRecurrenceActivityPayload>
>;
type V3RecurrenceTaskStartWorkflowPayload = Infer<
    ReturnType<typeof sV3RecurrenceTaskStartWorkflow>
>;
type V3MessageStartEventPayload = Infer<ReturnType<typeof sV3MessageStartEventPayload>>;
type V3ChecklistExecutionStartEventPayload = Infer<
    ReturnType<typeof sV3ChecklistExecutionStartEventPayload>
>;

function sV3RawActivity() {
    return type({
        id: string(),
        name: string(),
        vtodo: string(),
        execution_audience: string(),
        is_active: boolean(),
    });
}
type V3RawActivity = Infer<ReturnType<typeof sV3RawActivity>>;

export class V3Calendar implements Calendar {
    constructor(public activityRecurrences: ActivitySet[]) {}

    getActivities(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): ActivityItem[] {
        return this.activityRecurrences
            .flatMap(ar => [...ar.generateActivityInstances(startDate, endDate)])
            .sort(ActivityItem.compare);
    }
}

function deserializeRawActivities(rawActivities: V3RawActivity[]) {
    return rawActivities.map(deserializeRawActivity);
}

function deserializeRawActivity(rawActivity: V3RawActivity): ActivitySet {
    const vtodo = decomposeTodo(new ICAL.Component(ICAL.parse(rawActivity.vtodo)));
    if (!vtodo.rrule || vtodo.recurrenceId) {
        throw new Error(
            "Only todos with rrule and no recurrenceId are supported by the frontend right now",
        );
    }
    return new ActivitySet(vtodo as VTodoRecurrence, vtodo.summary || rawActivity.name);
}
