import { useParams } from "@solidjs/router";
import { For, Show } from "solid-js";
import {
    createPaulsenAudienceVariablesQuery,
    createListPaulsenAudiencesQuery,
} from "../../api/services/audiences-paulsen/queries";
import { H1, P } from "../../utils/typography";
import { FormWrapper } from "../forms/FormWrapper";
import { createForm } from "../forms/state";
import { PageWrapper } from "../ui/pageWrappers";
import { AudiencesPeopleResultsTable } from "./AudiencesPeopleResultsTable";

export default function AudiencesDetailPage() {
    const allAudiencesQuery = createListPaulsenAudiencesQuery();
    const allVariablesQuery = createPaulsenAudienceVariablesQuery();
    const params = useParams<{ id: string }>();
    if (isNaN(Number(params.id))) {
        throw new Error("Not a valid id for audience detail page");
    }

    const audience = () => allAudiencesQuery.data?.find(a => Number(a.id) === Number(params.id));
    const audienceVariables = () =>
        allVariablesQuery.data
            ?.filter(v => Number(v.audience) === Number(params.id))
            .sort((a, b) => a.name.localeCompare(b.name));

    const form = createForm();
    return (
        <PageWrapper>
            <FormWrapper staticForm={form}>
                <Show when={audience()}>
                    {audience => {
                        return (
                            <>
                                <H1>{audience().name}</H1>
                                <P>{audience().expression}</P>
                                {JSON.stringify(
                                    expressionToVariablesAndOperators(audience().expression),
                                )}
                            </>
                        );
                    }}
                </Show>
                <Show when={audienceVariables()}>
                    {audienceVariables => {
                        return (
                            <For each={audienceVariables()}>
                                {variable => {
                                    return <div class="flex">{variable.name}</div>;
                                }}
                            </For>
                        );
                    }}
                </Show>
                {/* <div class="flex">
                    <AudienceGroupDefinitionForm identifier="1" />
                </div>
                <div class="flex">
                    <AudienceOperatorForm identifier="1" />
                </div> */}
            </FormWrapper>
            <div class={"flex gap-4 space-x-4"}>
                <AudiencesPeopleResultsTable id={params.id} />
                {/* <AudiencesFunctionsTable/> */}
            </div>
        </PageWrapper>
    );
}

function expressionToVariablesAndOperators(
    expression: string,
): [variables: string[], operators: string[]] {
    const variables: string[] = [];
    const operators: string[] = [];

    const cleanedExpression = expression.replace(/[()]/g, "");

    const components = cleanedExpression.split(/\s+(and|or)\s+/);

    for (let i = 0; i < components.length; i++) {
        const trimmedComponent = components[i].trim();
        if (i % 2 === 0) {
            const variable = trimmedComponent.split(/\s+/);
            variables.push(...variable);
        } else {
            operators.push(trimmedComponent);
        }
    }

    return [variables, operators];
}
