import { Show } from "solid-js";
import { WorkflowElement } from "../../../../api/services/workflow/interface";
import { P } from "../../../../utils/typography";
import { useLocale } from "../../../i18n/context";

export function ExecutionUser(props: { element: WorkflowElement }) {
    const [locale] = useLocale();
    return (
        <div>
            <Show when={props.element.user}>
                {user => (
                    <div>
                        <h3>¿Cómo se generó?</h3>
                        <P>
                            Por {user().first_name} {user().last_name}
                        </P>
                        <P>
                            Iniciado el{" "}
                            {Intl.DateTimeFormat(locale().codeWithCountry, {
                                dateStyle: "long",
                                timeStyle: "short",
                            }).format(props.element.updatedAt)}
                        </P>
                    </div>
                )}
            </Show>
        </div>
    );
}
