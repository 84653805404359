import { compact } from "lodash";
import { createApiMutation, queryClient } from "../../utils";
import {
    ChecklistSections,
    CreateChecklistExecutionsPayload,
    CreateChecklistFormResponse,
    CreateChecklistPayload,
    CreateChecklistSectionsPayload,
    CreateConceptParams,
    CreateConceptStepParams,
    CreateEvaluationParams,
    CreateSectionStep,
    CreateUpdateChecklistCategory,
    SectionStep,
    UpdateChecklistFormResponse,
} from "./interface";

export function createChecklistCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistCreateMutation"],
        mutationFn: (payload: CreateChecklistPayload) => api.checklist.createChecklist(payload),
    }));
}

export function createChecklistPatchMutation(id: string) {
    return createApiMutation(api => ({
        mutationKey: ["createChecklistPatchMutation"],
        mutationFn: (payload: CreateChecklistPayload) => api.checklist.updateChecklist(id, payload),
    }));
}

export function createChecklistExecutionsCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistExecutionsCreateMutation"],
        mutationFn: (payload: CreateChecklistExecutionsPayload) =>
            api.checklist.createChecklistExecutions(payload),
    }));
}

export function createChecklistSectionsCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistSectionsCreateMutation"],
        mutationFn: (payload: CreateChecklistSectionsPayload) =>
            api.checklist.createChecklistSections(payload),
        onSettled: (_data, _error, params: CreateChecklistSectionsPayload) => {
            queryClient.invalidateQueries({
                queryKey: ["checklistSectionsList", params.checklist],
            });
        },
    }));
}

export function createPatchChecklistSectionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["patchChecklistSectionMutation"],
        mutationFn: api.checklist.patchChecklistSection,
        onSettled: (updatedSection?: ChecklistSections) => {
            queryClient.invalidateQueries({
                queryKey: compact(["checklistSectionsList", updatedSection?.checklist]),
            });
        },
    }));
}

export function createDeleteChecklistSectionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteChecklistSectionMutation"],
        mutationFn: api.checklist.deleteChecklistSection,
        onSettled: (_data, _error, id: string) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveUniqSection", id] });
            queryClient.invalidateQueries({ queryKey: ["checklistSectionsList"] });
        },
    }));
}

export function createChecklistActivationMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistActivationMutation"],
        mutationFn: (id: string) => api.checklist.createChecklistActivation(id),
        onSettled: (_data, _error, checklistId: string) => {
            queryClient.invalidateQueries({ queryKey: ["checklist", checklistId] });
        },
    }));
}

export function createChecklistSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistSectionStepMutation"],
        mutationFn: (payload: CreateSectionStep) => api.checklist.createSectionStep(payload),
        onSettled: (_data, _error, params: CreateSectionStep) => {
            queryClient.invalidateQueries({ queryKey: ["sectionStepList", params.sectionId] });
        },
    }));
}

export function createUpdateChecklistSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateChecklistSectionStepMutation"],
        mutationFn: (payload: SectionStep) => api.checklist.updateSectionStep(payload),
        onSettled: (_data, _error, params: SectionStep) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveSectionStep", params.id] });
            queryClient.invalidateQueries({ queryKey: ["sectionStepList", params.sectionId] });
        },
    }));
}

export function createDeleteSectionStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteChecklistSectionStepMutation"],
        mutationFn: api.checklist.deleteSectionStep,
        onSettled: (_data, _error, id: string) => {
            queryClient.invalidateQueries({ queryKey: ["retrieveSectionStep", id] });
            queryClient.invalidateQueries({ queryKey: ["sectionStepList"] });
        },
    }));
}

export function createChecklistFormResponseMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createChecklistFormResponseMutation"],
        mutationFn: api.checklist.createChecklistFormResponse,
        onSettled: (_data, _error, params: CreateChecklistFormResponse) => {
            queryClient.invalidateQueries({ queryKey: ["currentStep", params.executionId] });
        },
    }));
}

export function createUpdateChecklistFormResponseMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateChecklistFormResponseMutation"],
        mutationFn: api.checklist.updateChecklistFormResponse,
        onSettled: (_data, _error, params: UpdateChecklistFormResponse) => {
            queryClient.invalidateQueries({
                queryKey: [
                    "listChecklistFormResponses",
                    { checklist_execution: params.executionId },
                ],
            });
        },
    }));
}

export function createCompleteChecklistMutation() {
    return createApiMutation(api => ({
        mutationKey: ["completeChecklistMutation"],
        mutationFn: api.checklist.completeChecklist,
        onSettled: (_data, _error, executionId: string) => {
            queryClient.invalidateQueries({ queryKey: ["checklistExecutionsList"] });
            queryClient.invalidateQueries({ queryKey: ["checklistExecution", executionId] });
        },
    }));
}

// #region evaluations

export function createUpdateDefaultEvaluationAudiencesMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateDefaultEvaluationAudiencesMutation"],
        mutationFn: api.checklist.updateDefaultEvaluationAudiences,
    }));
}

export function createChecklistEvaluationCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistEvaluationCreateMutation"],
        mutationFn: api.checklist.createEvaluation,
        onSettled: (_data, _error, params: CreateEvaluationParams) => {
            queryClient.invalidateQueries({
                queryKey: ["listChecklistEvaluation", params.checklist],
            });
        },
    }));
}

export function createChecklistEvaluationUpdateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistEvaluationUpdateMutation"],
        mutationFn: api.checklist.updateEvaluation,
        onSettled: (_data, _error, params: CreateEvaluationParams & { id: string }) => {
            queryClient.invalidateQueries({
                queryKey: ["listChecklistEvaluation", params.checklist],
            });
        },
    }));
}
export function createActivateEvaluationMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activateEvaluationMutation"],
        mutationFn: api.checklist.activateEvaluation,
        onSettled: (_data, _error, evaluationId: string) => {
            queryClient.invalidateQueries({ queryKey: ["listChecklistEvaluation", evaluationId] });
        },
    }));
}

export function createChecklistConceptCreateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistConceptCreateMutation"],
        mutationFn: api.checklist.createConcept,
        onSettled: async (_data, _error, params: CreateConceptParams) => {
            await queryClient.invalidateQueries({
                queryKey: ["listChecklistConcepts", params.evaluation],
            });
            await queryClient.invalidateQueries({
                queryKey: ["listChecklistFormConceptsWithQuestion", params.evaluation],
            });
        },
    }));
}

//updateConcept
export function createChecklistConceptUpdateMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistConceptUpdateMutation"],
        mutationFn: api.checklist.updateConcept,
        onSettled: async (_data, _error, params: Partial<CreateConceptParams> & { id: string }) => {
            await queryClient.invalidateQueries({
                queryKey: ["listChecklistConcepts", params.evaluation],
            });
            await queryClient.invalidateQueries({
                queryKey: ["listChecklistFormConceptsWithQuestion", params.evaluation],
            });
        },
    }));
}

export function createConceptStepMutation() {
    return createApiMutation(api => ({
        mutationKey: ["conceptStepCreateMutation"],
        mutationFn: api.checklist.createConceptStep,
        onSettled: (_data, _error, params: CreateConceptStepParams) => {
            queryClient.invalidateQueries({ queryKey: ["listConceptSteps", params.concept] });
            queryClient.invalidateQueries({ queryKey: ["listChecklistFormConceptsWithQuestion"] });
        },
    }));
}

// Checklist Categories

export function createChecklistCategoryMutation() {
    return createApiMutation(api => ({
        mutationKey: ["checklistCategoryMutation"],
        mutationFn: api.checklist.createChecklistCategory,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["listCategories"] });
        },
    }));
}

export function updateChecklistCategoryMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateChecklistCategoryMutation"],
        mutationFn: (payload: Partial<CreateUpdateChecklistCategory> & { id: string }) =>
            api.checklist.updateChecklistCategory(payload),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["listCategories"] });
        },
    }));
}

//Evaluation Labels

export function createEvaluationLabelMutation() {
    return createApiMutation(api => ({
        mutationKey: ["evaluationLabelMutation"],
        mutationFn: api.checklist.createEvaluationLabel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["listEvaluationLabels"] });
        },
    }));
}
//No need to (payload) =>api.xxxx.mutation(payload) ???
export function patchEvaluationLabelMutation() {
    return createApiMutation(api => ({
        mutationKey: ["patchEvaluationLabelMutation"],
        mutationFn: api.checklist.patchEvaluationLabel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["listEvaluationLabels"] });
        },
    }));
}
export function putEvaluationLabel() {
    return createApiMutation(api => ({
        mutationKey: ["putEvaluationLabel"],
        mutationFn: api.checklist.putEvaluationLabel,
    }));
}
export function deleteEvaluationLabelMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteEvaluationLabelMutation"],
        mutationFn: api.checklist.deleteEvaluationLabel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["listEvaluationLabels"] });
        },
    }));
}

export function createEvaluationLabelConditionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["evaluationLabelConditionMutation"],
        mutationFn: api.checklist.createEvaluationLabelCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["evaluationLabelConditionList"] });
        },
    }));
}

export function updateEvaluationLabelConditionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateEvaluationLabelConditionMutation"],
        mutationFn: api.checklist.patchEvaluationLabelCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["evaluationLabelConditionList", "evaluationLabelConditionList"],
            });
        },
    }));
}

export function deleteEvaluationLabelConditionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["deleteEvaluationLabelConditionMutation"],
        mutationFn: api.checklist.deleteEvaluationLabelCondition,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["evaluationLabelConditionList", "evaluationLabelConditionList"],
            });
        },
    }));
}
