import { H1, P } from "../../../../utils/typography";
import { FormWrapper, FormWrapperProps } from "../../../forms/FormWrapper";
import TextField from "../../../forms/fields/TextField";
import SelectField from "../../../forms/fields/SelectField";
import RadioField from "../../../forms/fields/RadioField";
import { For, Show } from "solid-js";
import { createField, createForm, useFormState } from "../../../forms/state";
import SwitchField from "../../../forms/fields/SwitchField";
import { Button } from "../../../ui/components";
import { range } from "../../../../utils/miniLodash";
import {
    CreateSectionStep,
    EvaluationFunction,
    SectionStep,
} from "../../../../api/services/checklist/interface";
import {
    createChecklistSectionStepMutation,
    createUpdateChecklistSectionStepMutation,
} from "../../../../api/services/checklist/mutations";
import { InputTypeEnum } from "../../../../api/services/formbuilder/interfaces/InputTypeEnum";
import { makeBlankField } from "../../../FormBuilder/makeBlankField";
import { InputUnion } from "../../../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { Modal, ModalController } from "../../../ui/Modal";

export function CreateSectionStepModal(props: {
    controller: ModalController<void, void>;
    sectionId: string;
    order: number;
}) {
    const createMutation = createChecklistSectionStepMutation();
    const form = createForm<StepFormValues>();

    async function onSubmit(values: StepFormValues) {
        await createMutation.mutateAsync(sendStep(values, props));
        form.reset();
        props.controller.dismiss();
    }

    return (
        <Modal controller={props.controller} confirmMode>
            <Modal.Header>
                <H1>Agregar paso</H1>
            </Modal.Header>
            <ConfigStepForm staticForm={form} onSubmit={onSubmit} controller={props.controller} />
        </Modal>
    );
}

export function UpdateSectionStepModal(props: {
    step: SectionStep;
    controller: ModalController<SectionStep, void>;
}) {
    const updateMutation = createUpdateChecklistSectionStepMutation();
    const form = createForm<StepFormValues>();

    async function onSubmit(values: StepFormValues) {
        const body: SectionStep = {
            ...sendStep(values, { order: props.step.order, sectionId: props.step.sectionId }),
            id: props.step.id,
            updatedAt: Temporal.Now.instant(),
            fields: props.step.fields,
        };
        console.log({
            FIELDS: props.step.fields,
            mainField: body.mainField,
        });
        await updateMutation.mutateAsync(body);
        props.controller.dismiss();
    }
    return (
        <ConfigStepForm
            defaultValues={receiveStep(props.step)}
            staticForm={form}
            onSubmit={onSubmit}
            class={"mt-5"}
            controller={props.controller}
        />
    );
}

export type StepFormValues = {
    name: string;
    response_type: "list" | "interval";
    response_format?: "unique" | "multiple";
    evaluable?: boolean;
    options?: OptionFormValues[];
    evaluation_function?: EvaluationFunction;
    allow_does_not_apply: boolean;
    mandatory_step: boolean;
};

function sendStep(
    values: StepFormValues,
    params: {
        sectionId: string;
        order: number;
    },
): CreateSectionStep {
    const type =
        values.response_type === "interval"
            ? InputTypeEnum.value_in_num_range
            : values.response_format === "unique"
            ? InputTypeEnum.multiple_choice
            : InputTypeEnum.checkboxes;

    return {
        sectionId: params.sectionId,
        order: params.order,
        mainField: makeBlankField(type, "-1", {
            name: "main",
            label: values.name,
            required: values.mandatory_step,
            skippable: values.allow_does_not_apply,
            choices: values.options?.map(option => ({
                choice: option.choice,
                image: null,
            })),
            start: 0,
            stop: 100,
            step: 10,
        }) as InputUnion & { type: typeof type },
        evaluable: values.evaluable ?? true,
        metadata: {
            choices_with_grade: values.options?.map(option => ({
                choice: option.choice,
                grade: Number(option.grade),
            })),
            evaluation_function: values.evaluation_function,
        },
    };
}

export function receiveStep(step: SectionStep): StepFormValues {
    return {
        name: step.mainField.label,
        response_type:
            step.mainField.type === InputTypeEnum.value_in_num_range ? "interval" : "list",
        response_format: (
            {
                [InputTypeEnum.multiple_choice]: "unique",
                [InputTypeEnum.checkboxes]: "multiple",
            } as Partial<Record<InputTypeEnum, "unique" | "multiple">>
        )[step.mainField.type],
        evaluable: step.evaluable,
        options: step.metadata.choices_with_grade?.map(({ choice, grade }) => ({
            choice,
            grade: grade.toString(),
        })),
        evaluation_function: step.metadata.evaluation_function,
        allow_does_not_apply: step.mainField.skippable,
        mandatory_step: step.mainField.required,
    };
}

export function ConfigStepForm<T>(
    props: FormWrapperProps<StepFormValues> & {
        controller: ModalController<T, void>;
    },
) {
    const form = props.staticForm ?? createForm();
    const noOptions = () => form.values.options?.length === 0;
    const isList = () => form.values.response_type === "list";

    return (
        <FormWrapper
            {...props}
            staticForm={form}
            class={"flex w-80 flex-col gap-y-4 " + props.class}
        >
            <TextField name={"name"} label={"Nombre"} />
            <SelectField
                name={"response_type"}
                label={"Tipo de respuesta"}
                options={[
                    ["list", "Lista"],
                    ["interval", "Intervalo"],
                ]}
            />
            <div classList={{ hidden: form.values.response_type != "list" }}>
                <div class={"flex justify-between"}>
                    <RadioField
                        name={"response_format"}
                        label={"Formato de respuesta"}
                        labelClass={"basis-1/2"}
                        parentCheckboxClass={"flex gap-x-2 mb-2"}
                        options={[
                            ["unique", "Única"],
                            ["multiple", "Múltiple"],
                        ]}
                    />
                    <Show when={form.values.response_format === "multiple"}>
                        <SelectField
                            name={"evaluation_function"}
                            label={"Función de evaluación"}
                            options={[
                                [EvaluationFunction.max, "Máximo"],
                                [EvaluationFunction.min, "Mínimo"],
                                [EvaluationFunction.avg, "Promedio"],
                            ]}
                        />
                    </Show>
                </div>
                <div class={"mb-4 flex items-center justify-between"}>
                    <P class={"!mb-0 font-medium"}>Lista de opciones</P>
                    <SwitchField
                        name={"evaluable"}
                        label={"Evaluable con nota"}
                        defaultValue={true}
                    />
                </div>
                <OptionList />

                <SwitchField name={"allow_does_not_apply"} label={"Permitir no aplica"} />
            </div>
            <SwitchField name={"mandatory_step"} label={"Paso obligatorio"} />
            <Modal.Actions>
                <Modal.CancelButton />
                <Modal.SubmitButton disabled={isList() && noOptions()}>
                    Guardar paso
                </Modal.SubmitButton>
            </Modal.Actions>
        </FormWrapper>
    );
}

const gradeArray: [value: string, label: string][] = range(0, 100 + 1, 10).map(value => [
    String(value),
    String(value),
]);

type OptionFormValues = {
    /** Same as {@link ChoiceWithGrade.choice} */
    choice: string;
    /** Same as {@link ChoiceWithGrade.grade}, but it's a string because
     * {@link SelectField} returns the selected value as a string. */
    grade: string;
};

function OptionList() {
    const field = createField<OptionFormValues[]>({ name: "options" }, []);
    const value = () => field.value;

    const changeOption = (newOption: OptionFormValues, index: number): void => {
        field.setValue(field.value.map((prevOption, i) => (i === index ? newOption : prevOption)));
    };

    const deleteOption = (index: number): void => {
        field.setValue(field.value.filter((_, i) => i !== index));
    };

    return (
        <div class={"space-y-2"}>
            <For each={value()}>
                {(option, index) => (
                    <OptionItem
                        option={option}
                        index={index()}
                        onChange={changeOption}
                        onDelete={deleteOption}
                    />
                )}
            </For>
            <Button
                bgStyle={"outline"}
                type={"button"}
                onClick={() => {
                    console.log("field.value", field.value);
                    return field.setValue([
                        ...(field.value ?? []),
                        { choice: "", grade: gradeArray[0][0] },
                    ]);
                }}
            >
                Agregar Opción
            </Button>
        </div>
    );
}

function OptionItem(props: {
    option: OptionFormValues;
    index: number;
    onChange: (option: OptionFormValues, index: number) => void;
    onDelete: (index: number) => void;
}) {
    const form = useFormState<StepFormValues>();

    return (
        <div class={"flex w-full items-center gap-x-2 rounded-xs bg-light-gray-200 p-2"}>
            <div>
                <P class={"!mb-0 flex items-center gap-x-1"}>
                    <div
                        class={`h-4 w-4 border border-dark-gray-400 ${
                            form.values.response_format === "unique" ? "rounded-full" : ""
                        }`}
                    />{" "}
                    <span>{props.index + 1}</span>
                </P>
            </div>
            <div class={"w-full"}>
                <TextField
                    name={`name_${props.index}`}
                    label={""}
                    placeholder={"Nombre"}
                    defaultValue={props.option.choice}
                    onChange={e =>
                        props.onChange(
                            { choice: e.currentTarget.value, grade: props.option.grade },
                            props.index,
                        )
                    }
                />
            </div>
            <Show when={form.values.evaluable}>
                <div class={"w-[100px]"}>
                    <SelectField
                        name={`nota_${props.index}`}
                        label={""}
                        options={gradeArray}
                        defaultValue={props.option.grade}
                        onChange={e =>
                            props.onChange(
                                { choice: props.option.choice, grade: e.currentTarget.value },
                                props.index,
                            )
                        }
                    />
                </div>
            </Show>
            <div>
                <P class={"!mb-0 cursor-pointer"}>
                    <i
                        class="far fa-trash-alt text-error-500"
                        onClick={() => props.onDelete(props.index)}
                    />
                </P>
            </div>
        </div>
    );
}
