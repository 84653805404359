import { WorkflowExecution } from "../../api/services/workflow/interface";
import { BorderedCard } from "../ui/cards";
import { Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { P } from "../../utils/typography";
import { useLocale } from "../i18n/context";
import { ThreeColumnsOnDesktop } from "./utils";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";
import { useNavigate, useParams } from "@solidjs/router";
import { createMyExecutionsQuery } from "../../api/services/workflow/workflow-types/queries";

export default function WorkflowExecutionList() {
    const params = useParams<{ workflowType: string }>();
    const myExecutionsQuery = createMyExecutionsQuery(() => params.workflowType);
    const navigate = useNavigate();
    const navigateToExecutionDetail = (id: string) => {
        navigate(`/workflows/${params.workflowType}/executions/${id}`);
    };
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show
                when={myExecutionsQuery.data}
                fallback={<ErrorBlock error={myExecutionsQuery.error} />}
            >
                {executionList => (
                    <ThreeColumnsOnDesktop
                        each={executionList()}
                        fallback={<P>No hay ejecuciones de workflow.</P>}
                    >
                        {execution => (
                            <WorkflowExecutionItem
                                execution={execution}
                                navigateToExecutionDetail={() =>
                                    navigateToExecutionDetail(execution.id)
                                }
                            />
                        )}
                    </ThreeColumnsOnDesktop>
                )}
            </Show>
        </Suspense>
    );
}

export function WorkflowExecutionItem(props: {
    execution: WorkflowExecution;
    navigateToExecutionDetail: () => void;
}) {
    const workflowDetailQuery = createWorkflowDetailQuery(() => props.execution.workflowId);
    //const navigate = useNavigate();
    const [locale] = useLocale();

    return (
        <BorderedCard
            // window.location.href as workaround for weird bug where the execution detail query is endlessly loading
            onClick={props.navigateToExecutionDetail}
            class={"col-span-1 md:col-span-4"}
        >
            <Show when={workflowDetailQuery.data}>
                {workflow => <P class={"font-semibold"}>{workflow().name}</P>}
            </Show>
            <div class={"flex items-center gap-x-2"}>
                <span
                    class={"block h-2 w-2 rounded-full"}
                    classList={{
                        "bg-success-400": props.execution.status === "Success",
                        "bg-error-300": props.execution.status === "InProcess",
                    }}
                />
                <P class={"!mb-0"}>
                    {props.execution.status === "Success" ? "Finalizada" : "En proceso"}
                </P>
            </div>
            <P class={"!mb-0"}>
                Creado:{" "}
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "long",
                    timeStyle: "short",
                }).format(props.execution.createdAt)}
            </P>
            <P>
                Última actualización:
                {Intl.DateTimeFormat(locale().codeWithCountry, {
                    dateStyle: "long",
                    timeStyle: "short",
                }).format(props.execution.updatedAt)}
            </P>
            {/*<JsonDebug value={props.execution} />*/}
        </BorderedCard>
    );
}
