import { ModalController } from "../Modal";
import SidePanel from "../SidePanel";
import EditFileExplorerSidePanel from "./EditFileExplorerSidePanel";
import { UpdateFolder } from "./FileExplorerPanel";
import { Folder } from "./GenericFileExplorer";

export function EditFolderSidePanel(props: {
    controller: ModalController<UpdateFolder, void>;
    updateFolder?: (body: UpdateFolder) => Promise<void>;
}) {
    const updateFolder = async (folder: UpdateFolder) => {
        await props.updateFolder?.(folder);
    };
    return (
        <SidePanel controller={props.controller} position="right">
            {folder => (
                <EditFileExplorerSidePanel
                    activeFolder={folder as Folder}
                    updateFolder={updateFolder}
                    controller={props.controller}
                />
            )}
        </SidePanel>
    );
}
