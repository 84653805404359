import { useNavigate, useParams } from "@solidjs/router";
import { PageWrapper } from "../ui/pageWrappers";
import { createFormByBpmnElementQuery } from "../../api/services/workflow/queries";
import { createEffect, createSignal, Match, Show, Suspense, Switch } from "solid-js";
import BackButton, { useGoBack } from "../ui/BackButton";
import {
    createActivateWorkflowMutation,
    createNewWorkflowVersionMutation,
} from "../../api/services/workflow/mutations";
import { useLocale } from "../i18n/context";
import { H1, H2, P } from "../../utils/typography";
import { JsonDebug } from "../../utils/debug";
import { Button } from "../ui/components";
import { FormValues } from "../forms/state";
import { RenderDynamicFields } from "../FormBuilder/RenderDynamicForm";
import { sleep } from "../../utils/mocks";
import { BpmnElement, BpmnModeler, IntermediateThrowEvent, StartEvent, UserTask } from "./bpmn";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { FormWrapper } from "../forms/FormWrapper";
import SubmitButton from "../forms/SubmitButton";
import { StartEventDetail } from "./StartEventDetail";
import { ElementTitle, ExplainSaveOnClose } from "./utils";
import MessageTemplate from "./MessageTemplate";
import BpmnJsViewer from "bpmn-js/lib/Viewer";
import { showErrorToast } from "../../utils/errorHandling";
import ElementRegistry from "diagram-js/lib/core/ElementRegistry";
import { generateAndDownload } from "../../api/utils";
import { Workflow } from "../../api/services/workflow/workflows/interface";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";
import Toastify from "toastify-js";
import UuidAudienceField from "../audiences/UuidAudienceField";
import DescriptionField from "../forms/fields/DescriptionField";

export default function WorkflowDetailPage() {
    const params = useParams();
    const workflowDetailQuery = createWorkflowDetailQuery(() => params.id);
    const goBack = useGoBack("/workflows");

    return (
        <PageWrapper>
            <BackButton onClick={goBack} />
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={workflowDetailQuery.data}>
                    {workflow => (
                        <div>
                            <H1>
                                {workflow().name}{" "}
                                <Show when={workflow().version}>
                                    <span class={"text-sm"}>versión {workflow().version}</span>
                                </Show>
                            </H1>
                            <WorkflowViewerActions workflow={workflow()} />
                            <BpmnModeler workflow={workflow()} />
                        </div>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}

function WorkflowViewerActions(props: { workflow: Workflow }) {
    const [locale] = useLocale();
    const activateWorkflowMutation = createActivateWorkflowMutation();

    // Using a isActivating signal to avoid showing a spinner during the confirm dialog.
    const [isActivating, setIsActivating] = createSignal(false);
    const handleActivateWorkflow = async () => {
        if (confirm(locale().workflows.activateWorkflowConfirm(props.workflow.name))) {
            setIsActivating(true);
            try {
                await validateWorkflow(props.workflow);
                await activateWorkflowMutation.mutateAsync(props.workflow.id);
            } catch (error) {
                showErrorToast(error);
            } finally {
                setIsActivating(false);
            }
        }
    };

    return (
        <div class="mb-2 flex items-baseline gap-3">
            <WorkflowStatus isActive={props.workflow.is_active} />
            <Show when={!props.workflow.is_active}>
                <Button
                    bgStyle="outline"
                    onClick={handleActivateWorkflow}
                    pending={isActivating()}
                    pendingText={locale().workflows.activatingWorkflow}
                >
                    {locale().workflows.activateWorkflow}
                </Button>
            </Show>
            <Show when={props.workflow.is_active}>
                <NewWorkflowVersion workflowId={props.workflow.id} name={props.workflow.name} />
            </Show>
            <div class="flex-1" />
            <Show when={props.workflow.bpmn}>
                {bpmn => (
                    <Button
                        icon="fas fa-download"
                        bgStyle="text-only"
                        onClick={() => {
                            const blob = new Blob([bpmn()], { type: "text/xml;charset=utf-8" });
                            generateAndDownload(`${props.workflow.name}.xml`, blob);
                        }}
                    >
                        Descargar BPMN
                    </Button>
                )}
            </Show>
        </div>
    );
}

async function validateWorkflow(workflow: Workflow): Promise<void> {
    const viewer = new BpmnJsViewer();
    if (!workflow.bpmn) throw new Error("No se puede publicar un workflow sin diagrama BPMN");
    await viewer.importXML(workflow.bpmn);
    const elementRegistry: ElementRegistry = viewer.get("elementRegistry");
    const elements = elementRegistry.getAll().map(e => BpmnElement.create(e, viewer));
    for (const element of elements) {
        element.validate();
    }
}

export function WorkflowStatus(props: { isActive: boolean }) {
    const [locale] = useLocale();

    return (
        <div class={"flex items-baseline gap-x-2"}>
            <span
                class={"block h-2 w-2 translate-y-[-0.1rem] rounded-full"}
                classList={{
                    "bg-success-500": props.isActive,
                    "bg-dark-gray-700": !props.isActive,
                }}
            />
            <P class={"!mb-0"}>
                {props.isActive ? locale().workflows.active : locale().workflows.inactive}
            </P>
        </div>
    );
}

export type ElementDetailProps<TElement extends BpmnElement> = {
    element: TElement;
    workflow: Workflow;
    close: () => void;
};

function ElementDetail(props: ElementDetailProps<BpmnElement>) {
    return (
        <div class={"relative p-4 px-6"}>
            <ElementTitle element={props.element} />
            <i class={"fas fa-times absolute right-6 top-4 cursor-pointer"} onClick={props.close} />
            <Switch fallback={<NotImplementedDetail {...props} />}>
                <Match when={props.element instanceof IntermediateThrowEvent && props.element}>
                    {element => <IntermediateThrowEventDetail {...props} element={element()} />}
                </Match>
                <Match when={props.element instanceof StartEvent && props.element}>
                    {element => <StartEventDetail {...props} element={element()} />}
                </Match>
                <Match when={props.element.type === "bpmn:Task"}>
                    <TaskDetail {...props} />
                </Match>
                <Match when={props.element instanceof UserTask && props.element}>
                    {element => <UserTaskDetail {...props} element={element()} />}
                </Match>
            </Switch>
        </div>
    );
}

function IntermediateThrowEventDetail(props: ElementDetailProps<IntermediateThrowEvent>) {
    const [locale] = useLocale();
    createEffect(() => console.debug(props.element));
    return (
        <Show
            when={props.element.message}
            fallback={<P>{locale().workflows.configure.noMessage}</P>}
        >
            {message => (
                <MessageTemplate message={message()} element={props.element} mode="viewer" />
            )}
        </Show>
    );
}

function TaskDetail(props: ElementDetailProps<BpmnElement>) {
    const [locale] = useLocale();
    return <P>{locale().workflows.useUserTaskInstead(props.element.name)}</P>;
}

function UserTaskDetail(props: ElementDetailProps<UserTask>) {
    const [locale] = useLocale();
    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        workflowId: props.workflow.id,
        bpmnElementId: props.element.id,
    }));

    async function simulateSubmit(formValues: FormValues): Promise<void> {
        await sleep(1000);
        alert(locale().forms.simulationResult + "\n\n" + JSON.stringify(formValues, null, 4));
    }

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <FormWrapper readOnly>
                <UuidAudienceField
                    name="executionAudience"
                    label="Audiencia de ejecución"
                    defaultValue={props.element.executionAudience}
                />
                <DescriptionField
                    name="summary"
                    label="Nombre que visualizará el usuario en la sección Mis Actividades"
                    defaultValue={props.element.summary}
                    supportsProcessVars
                />
                <DescriptionField
                    name="description"
                    label="Descripción"
                    defaultValue={props.element.description}
                    supportsProcessVars
                />
                <ExplainSaveOnClose mode="viewer" />
            </FormWrapper>
            <Show when={formByBpmnElementQuery.data}>
                {form => (
                    <div>
                        <H2>Formulario</H2>
                        <a href={`/workflows/${props.workflow.id}/form-builder/${form().id}`}>
                            Editar formulario
                        </a>
                        <FormWrapper class="flex flex-col gap-3" onSubmit={simulateSubmit}>
                            <RenderDynamicFields fields={form().fields} />
                            <SubmitButton>{locale().forms.simulateSubmit}</SubmitButton>
                        </FormWrapper>
                    </div>
                )}
            </Show>
        </Suspense>
    );
}

function NotImplementedDetail(props: ElementDetailProps<BpmnElement>) {
    return <JsonDebug value={props.element} />;
}

function NewWorkflowVersion(props: { workflowId: string; name: string }) {
    const createNewVersion = createNewWorkflowVersionMutation();
    const navigate = useNavigate();

    async function onSubmit() {
        await createNewVersion.mutateAsync(
            { workflow: props.workflowId },
            {
                onSuccess: data => {
                    Toastify({
                        text: `Nuevo workflow ${props.name} creada con éxito`,
                    }).showToast();
                    navigate(`/workflows/${data.id}`);
                },
            },
        );
    }
    return (
        <FormWrapper onSubmit={onSubmit}>
            <SubmitButton bgStyle={"text-only"}>Crear nueva versión</SubmitButton>
        </FormWrapper>
    );
}
