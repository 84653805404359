import { Accessor } from "solid-js";
import { createApiQuery } from "../../../utils";
import { WorkflowsQueryParams } from "./interface";

export function createWorkflowListQuery(queryParams?: Accessor<WorkflowsQueryParams>) {
    return createApiQuery(api => ({
        queryKey: ["workflowList", queryParams?.()],
        queryFn: () => api.workflow.listWorkflows.fetchJson(queryParams?.()),
        select: api.workflow.listWorkflows.select,
    }));
}

export function createWorkflowDetailQuery(workflowId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["workflowDetail", workflowId()],
        queryFn: () => api.workflow.retrieveWorkflow(workflowId()!),
        enabled: !!workflowId(),
    }));
}
