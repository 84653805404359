import { AuthService } from "./services/auth/interface";
import { HomeService } from "./services/home/interface";
import AtlasHomeService from "./services/home/implementations/atlas";
import { PersonService } from "./services/person/interface";
import MockAuthService from "./services/auth/implementations/mock";
import { NotificationsService } from "./services/notifications/interface";
import { MockNotificationsService } from "./services/notifications/implementations/mock";
import V3AuthService from "./services/auth/implementations/v3";
import V3PersonService from "./services/person/implementations/v3";
import MockHomeService from "./services/home/implementations/mock";
import { OrganizationService } from "./services/organization/interface";
import { V3OrganizationService } from "./services/organization/implementations/v3";
import { WorkflowService } from "./services/workflow/interface";
import V3WorkflowService from "./services/workflow/v3";
import { TaskManagerService } from "./services/task-manager/interface";
import MockTaskManagerService from "./services/task-manager/implementations/mock";
import { AudiencesPaulsenService } from "./services/audiences-paulsen/interface";
import { V3NotificationsService } from "./services/notifications/implementations/v3";
import { ChecklistService } from "./services/checklist/interface";
import { V3ChecklistService } from "./services/checklist/implementations/v3";
import { LogicParserService } from "./services/logic-parser/interface";
import { V3LogicParserService } from "./services/logic-parser/implementations/v3";
import _ from "lodash";
import V3TaskManagerService from "./services/task-manager/implementations/v3";
import { PositionService } from "./services/positions/interface";
import { V3PositionService } from "./services/positions/implementations/v3";
import { MockPositionService } from "./services/positions/implementations/mock";
import V3AudiencesPaulsenService from "./services/audiences-paulsen/implementations/v3";
import { AudiencesLuchoService } from "./services/audiences-lucho/interface";
import V3AudiencesLuchoService from "./services/audiences-lucho/implementations/v3";
import { LocationsService } from "./services/locations/interface";
import { V3LocationsService } from "./services/locations/implementations/v3";

/** Abstraction used by the UI to access endpoints while being agnostic of
 * whether the data comes from a mock backend or a real one. Endpoints are
 * split into "services" to ease organization. */
export interface Api {
    audiencesLucho: AudiencesLuchoService;
    audiencesPaulsen: AudiencesPaulsenService;
    auth: AuthService;
    checklist: ChecklistService;
    home: HomeService;
    locations: LocationsService;
    logicParser: LogicParserService;
    notifications: NotificationsService;
    organization: OrganizationService;
    person: PersonService;
    position: PositionService;
    taskManager: TaskManagerService;
    workflow: WorkflowService;
}

const services: { [P in keyof Api]: Partial<Record<string, { new (): Api[P] }>> } = {
    audiencesLucho: { v3: V3AudiencesLuchoService },
    audiencesPaulsen: { v3: V3AudiencesPaulsenService },
    auth: { mock: MockAuthService, v3: V3AuthService },
    checklist: { v3: V3ChecklistService },
    home: { mock: MockHomeService, atlas: AtlasHomeService },
    locations: { v3: V3LocationsService },
    logicParser: { v3: V3LogicParserService },
    notifications: { mock: MockNotificationsService, v3: V3NotificationsService },
    organization: { v3: V3OrganizationService },
    person: { v3: V3PersonService },
    position: { mock: MockPositionService, v3: V3PositionService },
    taskManager: { mock: MockTaskManagerService, v3: V3TaskManagerService },
    workflow: { v3: V3WorkflowService },
};

let apiSingleton: Api | undefined = undefined;

export function getApiInstance(): Api {
    return (apiSingleton ??= _.mapValues(services, (implementations, serviceName) => {
        const envVarName = `VITE_SERVICE_${_.snakeCase(serviceName).toUpperCase()}`;
        console.log("GARGAMEL_MOCK_BACKEND", window.GARGAMEL_MOCK_BACKEND);
        // eslint-disable-next-line no-restricted-syntax
        const backendName = window.GARGAMEL_MOCK_BACKEND ? "mock" : import.meta.env[envVarName];
        if (!backendName) throw new Error(`${envVarName} is not defined`);
        const ServiceImpl = implementations[backendName];
        if (!ServiceImpl) {
            const className = `${_.capitalize(
                backendName,
            )}${serviceName[0].toUpperCase()}${serviceName.slice(1)}Service`;
            const error = new Error(
                `Implementation for ${className} not found. Did you add it to src/api/index.ts?`,
            );

            /* If a mock service is not implemented, delay the error until an endpoint is called
             * so we can make tests without having all the mocks.
             */
            if (backendName === "mock")
                return new Proxy(
                    {},
                    {
                        get() {
                            throw error;
                        },
                    },
                );

            throw error;
        }
        return new ServiceImpl();
    }) as Api);
}

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        /** If true, ignore VITE_SERVICE_* environment variables,
         * making every service use its mock implementation.
         * Useful for tests that are NOT e2e.
         *
         * @remarks Set this before calling any endpoint!
         */
        GARGAMEL_MOCK_BACKEND?: boolean;
    }
}
