import { Switch, Match } from "solid-js";
import { WorkflowElement } from "../../api/services/workflow/interface";
import { ModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import { ExecutionUser } from "./execution-components/events-components/ExecutionUser";
import {
    DefaultEventTypeGeneralData,
    VanillaStartEventGeneralData,
    MessageStartEventGeneralData,
    UserTaskGeneralData,
    TimerIntermediateCatchEventGeneralData,
    MessageIntermediateCatchEventGeneralData,
    MessageIntermediateThrowEventGeneralData,
    SuccessEndEventGerneralData,
    ErrorEndEventGeneralData,
    TimerBoundaryEventGeneralData,
} from "./execution-components/ExecutionEvents";

export function ExecutionElementDetailSidePanel(props: {
    controller: ModalController<WorkflowElement, void>;
    workflowId: string;
}) {
    return (
        <SidePanel position="right" controller={props.controller}>
            {element => (
                <div>
                    <ExecutionUser element={element} />
                    TYPE
                    {element.type}
                    <Switch
                        fallback={
                            <DefaultEventTypeGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        }
                    >
                        <Match when={element.type === "VanillaStartEvent"}>
                            <VanillaStartEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "MessageStartEvent"}>
                            <MessageStartEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "UserTask"}>
                            <UserTaskGeneralData element={element} workflowId={props.workflowId} />
                        </Match>
                        <Match when={element.type === "TimerIntermediateCatchEvent"}>
                            <TimerIntermediateCatchEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "MessageIntermediateCatchEvent"}>
                            <MessageIntermediateCatchEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "MessageIntermediateThrowEvent"}>
                            <MessageIntermediateThrowEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "SuccessEndEvent"}>
                            <SuccessEndEventGerneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "ErrorEndEvent"}>
                            <ErrorEndEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                        <Match when={element.type === "TimerBoundaryEvent"}>
                            <TimerBoundaryEventGeneralData
                                element={element}
                                workflowId={props.workflowId}
                            />
                        </Match>
                    </Switch>
                </div>
            )}
        </SidePanel>
    );
}
