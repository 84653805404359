import { P } from "../../../../utils/typography";
import { InfoBubble } from "../../../ui/InfoBubble";
import { EventGeneralDataProps } from "../ExecutionEvents";

export function ActivityResponsible(props: EventGeneralDataProps) {
    return (
        <div>
            <P>Responsables de la actividad</P>
            <div class="flex">
                <InfoBubble text={props.element.user?.first_name || "--"} />
                <InfoBubble text={props.element.user?.first_name || "--"} />
                <InfoBubble text={props.element.user?.first_name || "--"} />
            </div>
        </div>
    );
}
