import { ChecklistSections, SectionStep } from "../../../../api/services/checklist/interface";
import { P } from "../../../../utils/typography";
import { Tab, Tabs } from "../../../ui/Tabs";
import { For, Show, Suspense, untrack } from "solid-js";
import { CreateSectionStepModal, UpdateSectionStepModal } from "./CreateChecklistConfigStep";
import { StepForm } from "./CreateChecklistTask";
import { createSectionStepListQuery } from "../../../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../../../ui/skeletons";
import { useLocale } from "../../../i18n/context";
import SidePanel from "../../../ui/SidePanel";
import { createModalController, ModalController } from "../../../ui/Modal";
import { PageWrapper } from "../../../ui/pageWrappers";
import { FormWrapper } from "../../../forms/FormWrapper";
import SubmitButton from "../../../forms/SubmitButton";
import { StepFields } from "../../checklistExecutions/utils";
import DeleteButton from "../../../ui/DeleteButton";
import {
    createDeleteChecklistSectionMutation,
    createDeleteSectionStepMutation,
    createPatchChecklistSectionMutation,
} from "../../../../api/services/checklist/mutations";
import { Button } from "../../../ui/components";
import { after } from "../../../../api/services/checklist/implementations/v3";
import EditableName from "../../../ui/EditableName";
import { LabelCard } from "../../commons/LabelCard";
import { SectionCard } from "../../commons/SectionCard";

export function ChecklistSection(props: { section: ChecklistSections; disabled?: boolean }) {
    const addStepModal = createModalController<void, void>();
    const patchSectionMutation = createPatchChecklistSectionMutation();
    const deleteSectionMutation = createDeleteChecklistSectionMutation();

    const handleRename = async (name: string) => {
        await patchSectionMutation.mutateAsync({ id: props.section.id, name });
    };

    const handleClickDelete = async () => {
        if (confirm(`¿Seguro de eliminar la sección "${props.section.name}"?`))
            await deleteSectionMutation.mutateAsync(props.section.id);
    };

    return (
        <SectionCard
            {...props}
            IterableItem={SectionStepCard}
            customName={
                <>
                    <EditableName
                        nameOnBackend={props.section.name}
                        onRename={handleRename}
                        disabled={props.disabled}
                    />
                    <DeleteButton
                        onClick={handleClickDelete}
                        class="text-lg"
                        disabled={props.disabled}
                    />
                </>
            }
            actions={steps => (
                <>
                    <Button
                        icon="fas fa-plus"
                        bgStyle="text-only"
                        onClick={() => addStepModal.open()}
                        disabled={props.disabled}
                    >
                        Agregar paso
                    </Button>
                    <CreateSectionStepModal
                        controller={addStepModal}
                        sectionId={props.section.id}
                        order={after(steps())}
                    />
                </>
            )}
        />
    );
}

export function ChecklistSection_(props: { section: ChecklistSections; disabled?: boolean }) {
    const [locale] = useLocale();
    const stepsQuery = createSectionStepListQuery(() => props.section.id);
    const addStepModal = createModalController<void, void>();
    const patchSectionMutation = createPatchChecklistSectionMutation();
    const deleteSectionMutation = createDeleteChecklistSectionMutation();

    const handleRename = async (name: string) => {
        await patchSectionMutation.mutateAsync({ id: props.section.id, name });
    };

    const handleClickDelete = async () => {
        if (confirm(`¿Seguro de eliminar la sección "${props.section.name}"?`))
            await deleteSectionMutation.mutateAsync(props.section.id);
    };

    return (
        <div class={"rounded-md border border-light-gray-300"}>
            <header class={"pr-2.5 flex items-baseline border-b border-light-gray-300 py-4 pl-4"}>
                <EditableName
                    nameOnBackend={props.section.name}
                    onRename={handleRename}
                    disabled={props.disabled}
                />
                <DeleteButton
                    onClick={handleClickDelete}
                    class="text-lg"
                    disabled={props.disabled}
                />
            </header>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={stepsQuery.data} fallback={<p>Loading...</p>}>
                    {steps => (
                        <div class="p-2">
                            <div class="mb-1 flex flex-col gap-2">
                                <For each={steps()} fallback={<P>{locale().checklists.noSteps}</P>}>
                                    {(step, index) => (
                                        <SectionStepCard
                                            step={step}
                                            index={index()}
                                            disabled={props.disabled}
                                        />
                                    )}
                                </For>
                            </div>
                            <Button
                                icon="fas fa-plus"
                                bgStyle="text-only"
                                onClick={() => addStepModal.open()}
                                disabled={props.disabled}
                            >
                                Agregar paso
                            </Button>
                            <CreateSectionStepModal
                                controller={addStepModal}
                                sectionId={props.section.id}
                                order={after(steps())}
                            />
                        </div>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function SectionStepCard(props: { step: SectionStep; index: number; disabled?: boolean }) {
    const deleteStepMutation = createDeleteSectionStepMutation();
    const stepPanel = createModalController<SectionStep, void>();

    const handleClickDelete = async (event: Event) => {
        event.stopPropagation();
        if (confirm(`¿Seguro de eliminar el paso "${untrack(() => props.step.mainField.label)}"`)) {
            await deleteStepMutation.mutateAsync(props.step.id);
        }
    };

    return (
        <>
            <LabelCard
                {...props}
                onClick={() => {
                    if (props.disabled) {
                        return;
                    }
                    stepPanel.open(props.step);
                }}
                actions={<DeleteButton onClick={handleClickDelete} disabled={props.disabled} />}
            />
            <SidePanel position="left" controller={stepPanel}>
                {step => <StepDetails step={step} controller={stepPanel} />}
            </SidePanel>
        </>
    );
}

function StepDetails(props: { step: SectionStep; controller: ModalController<SectionStep, void> }) {
    return (
        <PageWrapper>
            <Tabs>
                <Tab title="Configuración del paso">
                    <UpdateSectionStepModal step={props.step} controller={props.controller} />
                </Tab>
                <Tab title="Previsualización de la ejecución">
                    <FormWrapper
                        onSubmit={values => alert(JSON.stringify(values, null, 4))}
                        class={"mt-5"}
                    >
                        <StepFields step={props.step} />
                        <SubmitButton>Simular envío</SubmitButton>
                    </FormWrapper>
                </Tab>
                <Tab title="Tareas">
                    <div class={"mt-5"}>
                        <StepForm step={props.step} />
                    </div>
                </Tab>
            </Tabs>
        </PageWrapper>
    );
}
