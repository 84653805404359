import { Show } from "solid-js";
import { WorkflowElement } from "../../../api/services/workflow/interface";
import { createWorkflowElementListQuery } from "../../../api/services/workflow/queries";
import { createModalController } from "../../ui/Modal";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { ExecutionElementDetailSidePanel } from "../ExecutionElementDetailSidePanel";
import { ExecutionFollowingDAG } from "./ExecutionDAG";

export function ExecutionElements(props: { executionId: string; workflowId: string }) {
    const workflowElementListQuery = createWorkflowElementListQuery(() => props.executionId);
    const detailSidepanelController = createModalController<WorkflowElement, void>();
    const onWFElementClick = (node: WorkflowElement) => {
        detailSidepanelController.open(node);
    };
    return (
        <div>
            <Show when={workflowElementListQuery.data} fallback={<GenericSuspenseFallback />}>
                {workflowElementList => (
                    <Show
                        when={workflowElementList().length > 0}
                        fallback={
                            <div class="text-gray-500 text-center">
                                No hay elementos de seguimiento
                            </div>
                        }
                    >
                        <ExecutionFollowingDAG
                            elements={workflowElementList()}
                            onNodeClick={onWFElementClick}
                        />
                    </Show>
                )}
            </Show>
            <ExecutionElementDetailSidePanel
                controller={detailSidepanelController}
                workflowId={props.workflowId}
            />
        </div>
    );
}
