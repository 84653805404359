import { createApiQuery } from "../../utils";

/** @deprecated */
export function createPaulsenGroupTypeListQuery() {
    return createApiQuery(api => ({
        queryKey: ["groupTypeList"],
        queryFn: () => api.audiencesPaulsen.listGroupTypes.fetchJson(),
        select: api.audiencesPaulsen.listGroupTypes.select,
    }));
}

/** @deprecated */
export function createListPaulsenAudiencesQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceList"],
        queryFn: () => api.audiencesPaulsen.listAudiences.fetchJson(),
        select: api.audiencesPaulsen.listAudiences.select,
    }));
}

/** @deprecated */
export function createPaulsenAudienceVariablesQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceVariables"],
        queryFn: () => api.audiencesPaulsen.getVariables.fetchJson(),
        select: api.audiencesPaulsen.getVariables.select,
    }));
}

/** @deprecated */
export function createListPaulsenPositionsQuery() {
    return createApiQuery(api => ({
        queryKey: ["positionList"],
        queryFn: () => api.audiencesPaulsen.listPositions.fetchJson(),
        select: api.audiencesPaulsen.listPositions.select,
    }));
}

/** @deprecated */
export function createPaulsenAudienceFunctionsQuery() {
    return createApiQuery(api => ({
        queryKey: ["audienceFunctions"],
        queryFn: () => api.audiencesPaulsen.audienceFunctions.fetchJson(),
        select: api.audiencesPaulsen.audienceFunctions.select,
    }));
}

/** @deprecated */
export function createPaulsenAudienceResultListQuery(audienceId: string) {
    return createApiQuery(api => ({
        queryKey: ["audienceResultList", audienceId],
        queryFn: () => api.audiencesPaulsen.audienceResultList.fetchJson(audienceId),
        select: api.audiencesPaulsen.audienceResultList.select,
    }));
}

/** @deprecated */
export function createListPaulsenGroupsQuery() {
    return createApiQuery(api => ({
        queryKey: ["groupList"],
        queryFn: () => api.audiencesPaulsen.listGroups.fetchJson(),
        select: api.audiencesPaulsen.listGroups.select,
    }));
}
