import { createEffect, For, Show } from "solid-js";
import { ChecklistStepOverview, SectionStep } from "../../api/services/checklist/interface";
import {
    createChecklistOverviewQuery,
    createSectionStepListQuery,
} from "../../api/services/checklist/queries";
import { P } from "../../utils/typography";
import { useRequiredContext } from "../../utils/solidjs";
import { ChecklistExecutionContext } from "./ChecklistExecutionContext";
import { createRef, MutableRefObject } from "../../utils/reactRefs";

export default function ChecklistOutline(props: { checklistId: string }) {
    const checklistId = () => props.checklistId;
    const checklistOverviewQuery = createChecklistOverviewQuery(checklistId);
    return (
        <div>
            <P class={"mb-4 font-medium"}>SECCIONES Y PREGUNTAS</P>
            <Show when={checklistOverviewQuery.data}>
                {overview => <ChecklistOutlineContent overview={overview()} />}
            </Show>
        </div>
    );
}

function ChecklistOutlineContent(props: { overview: ChecklistStepOverview[] }) {
    const { currentStepId } = useRequiredContext(
        ChecklistExecutionContext,
        "ChecklistOutlineContent",
        "ChecklistExecutionContextProvider",
    );
    const currentSectionId = () => {
        return props.overview.find(section =>
            section.checklist_forms.some(formId => formId === currentStepId()),
        )?.id;
    };

    return (
        <div class={"space-y-4"}>
            <For each={props.overview}>
                {section => (
                    <Show
                        when={currentSectionId() === section.id}
                        fallback={
                            <div>
                                <P class={"text-sm font-normal"}>{section.name}</P>
                            </div>
                        }
                    >
                        <SectionOverview overview={section} sectionId={section.id} />
                    </Show>
                )}
            </For>
        </div>
    );
}

function SectionOverview(props: { overview: ChecklistStepOverview; sectionId: string }) {
    const stepsQuery = createSectionStepListQuery(() => props.overview.id);
    //checklist-forms/sectionID=overview.id
    const { defaultStepId, currentStepId } = useRequiredContext(
        ChecklistExecutionContext,
        "SectionOverview",
        "ChecklistExecutionContextProvider",
    );
    const scrollRef = createRef<HTMLDivElement>();

    return (
        <div class={"rounded-md border border-light-gray-200"}>
            <div class={"border-b border-light-gray-200 px-4 py-2"}>
                <P class={"text-sm font-medium"}>{props.overview.name}</P>
            </div>
            <Show when={stepsQuery.data}>
                {sections => (
                    <div
                        class={"h-96 overflow-scroll"}
                        id={`section_${props.sectionId}`}
                        ref={scrollRef}
                    >
                        <For each={sections()}>
                            {(step, index) => (
                                <StepOverview
                                    step={step}
                                    iterIndex={index()}
                                    scrollRef={scrollRef}
                                    defaultIndex={sections().findIndex(
                                        step => step.id === defaultStepId(),
                                    )}
                                    currentStepIndex={sections().findIndex(
                                        step => step.id === currentStepId(),
                                    )}
                                />
                            )}
                        </For>
                    </div>
                )}
            </Show>
        </div>
    );
}

function StepOverview(props: {
    step: SectionStep;
    iterIndex: number;
    defaultIndex?: number;
    currentStepIndex?: number;
    scrollRef: MutableRefObject<HTMLDivElement>;
}) {
    const { setCurrentStepId } = useRequiredContext(
        ChecklistExecutionContext,
        "StepOverview",
        "ChecklistExecutionContextProvider",
    );
    const navigateToStep = () => {
        if (props.iterIndex <= (props.defaultIndex ?? 0)) {
            setCurrentStepId(props.step.id);
        }
    };

    const stepRef = createRef<HTMLDivElement>();

    createEffect(() => {
        if (props.iterIndex === props.currentStepIndex) {
            const centerDiv = () => {
                if (props.scrollRef.current && stepRef.current) {
                    const containerHeight = props.scrollRef.current.clientHeight;
                    const stepHeight = stepRef.current.clientHeight;
                    const stepOffsetTop = stepRef.current.offsetTop;

                    // Calculate the scroll position to center the div
                    const scrollPosition = stepOffsetTop - (containerHeight - stepHeight);

                    props.scrollRef.current.scrollTo({
                        top: scrollPosition,
                        behavior: "smooth",
                    });
                }
            };

            // Use setTimeout to ensure the DOM has been updated
            setTimeout(centerDiv, 500);
        }
    });

    return (
        <div
            class={"px-2"}
            id={`step_${props.step.id}`}
            ref={stepRef}
            classList={{
                "bg-primary-50": props.iterIndex === props.currentStepIndex,
                "bg-light-gray-100": props.iterIndex < (props.defaultIndex ?? 0),
                "text-light-gray-500": props.iterIndex > (props.defaultIndex ?? 0),
                "cursor-pointer": props.iterIndex <= (props.defaultIndex ?? 0),
            }}
            onClick={navigateToStep}
        >
            <P class={"flex items-start gap-x-2 py-1"}>
                <i
                    class={`fas fa-check-circle mt-1`}
                    classList={{
                        "text-success-500": props.iterIndex < (props.defaultIndex ?? 0),
                        "text-light-gray-300": props.iterIndex > (props.defaultIndex ?? 0),
                    }}
                />
                {props.step.mainField.label}
            </P>
        </div>
    );
}
