import { Show } from "solid-js";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";
import { H1 } from "../../utils/typography";
import { ModalController } from "../ui/Modal";
import { BpmnElement } from "./bpmn";
import { BpmnExecutionViewer } from "./execution-components/BPMNExecutionViewer";
import { ExecutionMetadata } from "./execution-components/ExecutionMetaData";

export function WorkflowDetailWrapper(props: {
    workflowId: string;
    executionId: string;
    panelController: ModalController<BpmnElement, void>;
    hideMetadata?: boolean;
}) {
    const workflowDetailQuery = createWorkflowDetailQuery(() => props.workflowId);
    return (
        <Show when={workflowDetailQuery.data}>
            {workflow => (
                <div class="space-y-2">
                    <H1>{workflow().name}</H1>
                    <Show when={props.hideMetadata !== true}>
                        <ExecutionMetadata executionId={props.executionId} />
                    </Show>
                    <div class="rounded-md border-2 p-4">
                        <BpmnExecutionViewer
                            bpmn={workflow().bpmn}
                            executionId={props.executionId}
                            onClickElement={props.panelController.open}
                        />
                    </div>
                </div>
            )}
        </Show>
    );
}
