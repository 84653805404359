import { useLocale } from "../i18n/context";
import { useNavigate } from "@solidjs/router";
import { Button } from "../ui/components";
import { createEffect, createSignal, Show } from "solid-js";
import { H1 } from "../../utils/typography";
import LanguageSelector from "../i18n/LanguageSelector";
import VersionNumber from "../../utils/VersionNumber";
import { PageWrapper } from "../ui/pageWrappers";
import { parsedEnv } from "../../utils/parsedEnv";
import { FormWrapper } from "../forms/FormWrapper";
import CheckboxField from "../forms/fields/CheckboxField";
import { Role } from "../../api/services/auth/interface";
import { rolesStorage } from "../../api/services/auth/queries";
import SelectField from "../forms/fields/SelectField";
import {
    defaultMenuProposal,
    MenuProposal,
    menuProposalStorage,
} from "../menu/proposals/menuProposals";

export default function SettingsPage() {
    const [locale] = useLocale();

    return (
        <div class="pb-12">
            <PageWrapper>
                <H1>{locale().settings.title}</H1>
                <SettingsBody />
            </PageWrapper>
            <div class="absolute bottom-1 right-3">
                <VersionNumber />
            </div>
        </div>
    );
}

function SettingsBody() {
    const [locale] = useLocale();
    const navigate = useNavigate();

    return (
        <div>
            <div class="flex max-w-80 flex-col gap-3">
                <LanguageSelector />
            </div>
            <Show when={parsedEnv.MODE === "development"}>
                <h2 class="mb-3 mt-10 text-display-sm">Configuración de desarrollo</h2>
                <div class="flex max-w-80 flex-col gap-3">
                    <Show when={parsedEnv.VITE_FEATURE_ONBOARDING_PERSONAL_DATA_FORM}>
                        <Button onClick={() => navigate("/onboarding")} bgStyle="outline">
                            {locale().settings.simulateOnboarding}
                        </Button>
                    </Show>
                    <Show when={parsedEnv.VITE_FEATURE_FIREBASE_INVITE_FROM_FRONTEND}>
                        <Button onClick={() => navigate("/invite")} bgStyle="outline">
                            {locale().settings.createInvite}
                        </Button>
                    </Show>
                    <Show when={parsedEnv.VITE_FEATURE_SIMULATE_ERROR_BUTTON}>
                        <SimulateError />
                    </Show>
                    <MenuSettings />
                    <SimulateAdminRole />
                </div>
            </Show>
        </div>
    );
}

function MenuSettings() {
    const query = menuProposalStorage.useQuery(defaultMenuProposal);
    const mutation = menuProposalStorage.useMutation();

    return (
        <Show when={query.status === "success"}>
            <FormWrapper>
                <SelectField
                    name={"menuProposal"}
                    label="Propuesta de menú"
                    options={Object.values(MenuProposal).map(v => [v, v])}
                    defaultValue={query.data!}
                    onChange={e => mutation.mutate(e.target.value as MenuProposal)}
                />
            </FormWrapper>
        </Show>
    );
}

function SimulateAdminRole() {
    const query = rolesStorage.useQuery([]);
    const mutation = rolesStorage.useMutation();

    return (
        <Show when={query.status === "success"}>
            <FormWrapper>
                <CheckboxField
                    name={"simulateAdmin"}
                    label="Simular rol de administrador"
                    defaultValue={query.data!.includes(Role.ADMIN)}
                    onChange={e =>
                        mutation.mutate(
                            e.target.checked
                                ? [...query.data!, Role.ADMIN]
                                : query.data!.filter(role => role !== Role.ADMIN),
                        )
                    }
                />
            </FormWrapper>
        </Show>
    );
}

function SimulateError() {
    const [locale] = useLocale();
    const t = () => locale().settings;

    const [error, setError] = createSignal(false);
    createEffect(() => {
        if (error()) {
            setError(false);
            throw new Error(t().simulatedError);
        }
    });

    return (
        <Button
            variant="danger"
            icon="fas fa-exclamation-circle"
            bgStyle="outline"
            onClick={() => setError(true)}
        >
            {t().simulateError}
        </Button>
    );
}
