import { JSX, Show } from "solid-js";
import { Button } from "../components";
import { SeparatedFor } from "../../../utils/solidjs";
import { Folder } from "./GenericFileExplorer";

export default function FileExplorerNavbar<T extends Folder>(props: {
    path: T["id"][];
    currentFolder: T | undefined;
    setCurrentFolderId: (folderId: string | undefined) => void;
    folders: T[];
    root?: JSX.Element;
}) {
    const btn = { bgStyle: "text-only", class: "!text-dark-gray-700 text-sm" } as const;
    const separator = () => <i class="fas fa-chevron-right text-xs text-light-gray-300" />;
    return (
        <div class="flex items-baseline text-xl">
            <Button
                {...btn}
                icon="fas fa-chevron-left"
                onClick={() => props.setCurrentFolderId(props.currentFolder?.parent ?? undefined)}
                disabled={props.path.length === 0}
            />
            <Show
                when={props.root}
                fallback={
                    <Button
                        {...btn}
                        icon="fas fa-home"
                        onClick={() => props.setCurrentFolderId(undefined)}
                    />
                }
            >
                {props.root}
            </Show>
            {props.path.length > 0 && separator()}
            <SeparatedFor each={props.path} separator={separator()}>
                {folderId => (
                    <Button
                        {...btn}
                        onClick={() => props.setCurrentFolderId(folderId)}
                        icon={props.folders.find(folder => folder.id === folderId)?.icon}
                    >
                        {props.folders.find(folder => folder.id === folderId)?.name}
                    </Button>
                )}
            </SeparatedFor>
        </div>
    );
}
