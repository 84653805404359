import { Show } from "solid-js";
import { CreateEvaluationParams, Evaluation } from "../../../api/services/checklist/interface";
import {
    createActivateEvaluationMutation,
    createChecklistEvaluationCreateMutation,
    createChecklistEvaluationUpdateMutation,
} from "../../../api/services/checklist/mutations";
import { createChecklistRetrieveQuery } from "../../../api/services/checklist/queries";
import UuidAudienceField from "../../audiences/UuidAudienceField";
import CheckboxField from "../../forms/fields/CheckboxField";
import TextField from "../../forms/fields/TextField";
import { FormWrapper } from "../../forms/FormWrapper";
import { Button } from "../../ui/components";
import Toastify from "toastify-js";
import { createModalController, Modal, ModalController } from "../../ui/Modal";
import CreateConceptForm from "./CreateUpdateConceptForm";
import ConceptList from "./ConceptList";
import EvaluationLabelSection from "./EvaluationSection/EvaluationLabelSection";
export type CreateEvaluationFormValues = Omit<CreateEvaluationParams, "checklist">;

export function EvaluationForm(props: {
    checklistId: string;
    evaluation?: Evaluation;
    evaluationLabelModal: ModalController<{ evaluationID: string }, void>;
    evaluationLabelConditionModal: ModalController<{ evaluationID: string }, void>;
}) {
    const checklistQuery = createChecklistRetrieveQuery(() => props.checklistId);
    const createEvaluationMutation = createChecklistEvaluationCreateMutation();
    const updateEvaluationMutation = createChecklistEvaluationUpdateMutation();
    const activateEvaluationMutation = createActivateEvaluationMutation();
    const createConceptModal = createModalController<void, void>();
    const create = (values: CreateEvaluationFormValues) => {
        createEvaluationMutation.mutateAsync(
            {
                ...values,
                checklist: props.checklistId,
                default: false,
            },
            {
                onSuccess: () => {
                    Toastify({
                        text: "Evaluación creada",
                    }).showToast();
                },
                onError: () => {
                    Toastify({
                        text: "Error al crear la evaluación",
                        backgroundColor: "red",
                    }).showToast();
                },
            },
        );
    };
    const edit = (evaluation: Evaluation, values: CreateEvaluationFormValues) => {
        updateEvaluationMutation.mutateAsync(
            {
                ...values,
                checklist: props.checklistId,
                id: evaluation.id,
                default: !!props.evaluation?.default,
            },
            {
                onSuccess: () => {
                    Toastify({
                        text: "Evaluación actualizada",
                    }).showToast();
                },
                onError: () => {
                    Toastify({
                        text: "Error al actualizar la evaluación",
                        backgroundColor: "red",
                    }).showToast();
                },
            },
        );
    };
    const activateEvaluation = () => {
        if (props.evaluation) {
            activateEvaluationMutation.mutate(props.evaluation.id, {
                onSuccess: () => {
                    Toastify({
                        text: "Evaluación activada",
                    }).showToast();
                },
                onError: () => {
                    Toastify({
                        text: "Error al activar la evaluación",
                        backgroundColor: "red",
                    }).showToast();
                },
            });
        }
    };
    const onSubmit = (values: CreateEvaluationFormValues) => {
        if (props.evaluation) {
            edit(props.evaluation, values);
        } else {
            create(values);
        }
    };

    const createConcept = () => {
        createConceptModal.open();
    };

    return (
        <>
            <FormWrapper onSubmit={onSubmit}>
                <div class={"mb-8 flex flex-col justify-end gap-y-6"}>
                    <TextField
                        name="name"
                        label={"Nombre de la evaluación"}
                        disabled={props.evaluation?.default || props.evaluation?.is_active}
                        defaultValue={
                            props.evaluation?.default
                                ? "Evaluación por defecto"
                                : props.evaluation?.name
                        }
                    />
                    <CheckboxField
                        name="use_percentages"
                        label="Usar Porcentajes"
                        disabled={props.evaluation?.default || props.evaluation?.is_active}
                        defaultValue={props.evaluation?.use_percentages}
                    />
                    <UuidAudienceField
                        name="read_audience"
                        label="Audiencia de Visualización"
                        disabled={props.evaluation?.default || props.evaluation?.is_active}
                        defaultValue={props.evaluation?.read_audience}
                    />
                    <UuidAudienceField
                        name="report_audience"
                        label="Audiencia del Reporte"
                        disabled={props.evaluation?.default || props.evaluation?.is_active}
                        defaultValue={props.evaluation?.report_audience}
                    />
                    {/* <CheckboxField name="is_active" label="Activar Evaluación"
                    defaultValue={props.evaluation?.is_active}
                /> */}
                    {/* <CheckboxField name="default" label="Establecer como predeterminada" 
                    disabled={props.evaluation?.default}
                    defaultValue={props.evaluation?.default}
                /> */}

                    <div class="flex gap-2">
                        <Show when={props.evaluation && !props.evaluation.default}>
                            <Button
                                onClick={activateEvaluation}
                                type="button"
                                bgStyle="solid"
                                disabled={props.evaluation?.is_active}
                                disabledReason="Esta evaluación ya se encuentra activa"
                                pending={activateEvaluationMutation.isPending}
                            >
                                Activar Evaluación
                            </Button>
                        </Show>
                        <Show when={!props.evaluation?.default && !props.evaluation?.is_active}>
                            <div class="flex space-x-2">
                                <Button
                                    type="submit"
                                    disabled={checklistQuery.data?.is_active}
                                    disabledReason="El checklist se encuentra activo y no se puede editar"
                                    pending={
                                        activateEvaluationMutation.isPending ||
                                        createEvaluationMutation.isPending ||
                                        updateEvaluationMutation.isPending
                                    }
                                >
                                    {props.evaluation
                                        ? "Editar Evaluación"
                                        : "Crear Nueva Evaluación"}
                                </Button>
                                <Button type="button" onClick={createConcept}>
                                    Crear Concepto
                                </Button>
                            </div>
                        </Show>
                    </div>
                </div>
            </FormWrapper>
            <Show when={props.evaluation}>
                {evaluation => (
                    <>
                        <ConceptList
                            evaluationId={evaluation().id}
                            disabled={evaluation().is_active}
                        />
                        <EvaluationLabelSection
                            evaluationId={evaluation().id}
                            evaluationLabelConditionModal={props.evaluationLabelConditionModal}
                            evaluationLabelModal={props.evaluationLabelModal}
                            disabled={evaluation().is_active}
                        />
                    </>
                )}
            </Show>
            <Show when={props.evaluation}>
                {evaluation => (
                    <Modal controller={createConceptModal}>
                        <Modal.Header>
                            <h2 class="text-xl font-bold">Agregar Concepto</h2>
                        </Modal.Header>
                        <CreateConceptForm evaluationId={evaluation().id} disabled={false} />
                    </Modal>
                )}
            </Show>
        </>
    );
}
