import { Match, Show, Switch } from "solid-js";
import { RecurrenceTask } from "../../../api/services/task-manager/interface";
import { UnknownTaskCard } from "./TaskCardDispatcher";
import { TaskCard } from "./TaskCard";
import { createActivityFormResponseQuery } from "../../../api/services/task-manager/queries";
import { ErrorBlock } from "../../../utils/GenericErrorBoundary";
import { JsonDebug } from "../../../utils/debug";
import {
    createWorkflowExecutionDetailQuery,
    createFormByBpmnElementQuery,
    createFormResponseQuery,
    createUserTaskByRecurrenceDetailQuery,
} from "../../../api/services/workflow/queries";

export default function RecurrenceTaskCardDispatcher(props: { recurrenceTask: RecurrenceTask }) {
    return (
        <Switch fallback={<UnknownRecurrenceTaskCard recurrenceTask={props.recurrenceTask} />}>
            <Match when={props.recurrenceTask.content_type_model === "formresponse"}>
                <FormResponseRecurrenceTaskCard recurrenceTask={props.recurrenceTask} />
            </Match>
            <Match when={props.recurrenceTask.content_type_model === "workflowformresponse"}>
                {/* <TaskCard
                    isReady={false}
                    isOptional={false}
                    isDone
                    taskType={""}
                    title={""}
                    description={"Tome una foto de la basura acumulada"}q
                /> */}

                <WorkflowFormResponseRecurrenceTaskCard recurrenceTask={props.recurrenceTask} />
            </Match>
        </Switch>
    );
}

function WorkflowFormResponseRecurrenceTaskCard(props: { recurrenceTask: RecurrenceTask }) {
    const userTaskDetailQuery = createUserTaskByRecurrenceDetailQuery(
        () => props.recurrenceTask.id,
    );
    const executionDetailQuery = createWorkflowExecutionDetailQuery(
        () => userTaskDetailQuery.data?.execution,
    );

    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        workflowId: executionDetailQuery.data?.workflowId!, //props.workflowId
        bpmnElementId: userTaskDetailQuery.data?.bpmn_id,
    }));
    const formResponseQuery = createFormResponseQuery(() => ({
        formId: formByBpmnElementQuery.data?.id,
        executionId: userTaskDetailQuery.data?.execution,
    }));
    return (
        <div>
            <Show when={userTaskDetailQuery.data}>
                {userTaskDetail => (
                    <Show when={formResponseQuery.data}>
                        {formResponse => (
                            <>
                                <TaskCard
                                    formValues={formResponse().formValues}
                                    title={userTaskDetail().name || "--TITLE--"}
                                    description={userTaskDetail().description || "--DESCRIPTION--"}
                                    isReady={true}
                                    taskType={"Tarea de usuario"}
                                    isOptional={false}
                                    completedAt={
                                        props.recurrenceTask.completed_at
                                            ? Intl.DateTimeFormat("default", {
                                                  day: "2-digit",
                                                  month: "short",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                              }).format(new Date(props.recurrenceTask.completed_at))
                                            : null
                                    }
                                    data={{
                                        recurrenceTask: props.recurrenceTask,
                                        formResponse: formResponse(),
                                    }}
                                />
                            </>
                        )}
                    </Show>
                )}
            </Show>
        </div>
    );
}

function FormResponseRecurrenceTaskCard(props: { recurrenceTask: RecurrenceTask }) {
    const query = createActivityFormResponseQuery(() => props.recurrenceTask.object_id);

    return (
        <Show when={query.data} fallback={<ErrorBlock error={query.error} />}>
            {formResponse => (
                <TaskCard
                    title={props.recurrenceTask.id}
                    description=""
                    isReady={true}
                    taskType={props.recurrenceTask.content_type_model}
                    isOptional={false}
                    data={{ recurrenceTask: props.recurrenceTask, formResponse: formResponse() }}
                />
            )}
        </Show>
    );
}

function UnknownRecurrenceTaskCard(props: { recurrenceTask: RecurrenceTask }) {
    return (
        <div>
            <JsonDebug value={props.recurrenceTask} />
            <UnknownTaskCard task={props.recurrenceTask} />
        </div>
    );
}
