import {
    Component,
    createEffect,
    createMemo,
    createSignal,
    For,
    JSX,
    ParentProps,
    Show,
} from "solid-js";
import { createRef } from "../../../utils/reactRefs";
import { useNavigate, useParams } from "@solidjs/router";
import FileExplorerHeader from "./FileExplorerHeader";
import FileExplorerPanel, { UpdateFolder } from "./FileExplorerPanel";
import FileExplorerNavbar from "./FileExplorerNavbar";
import FolderGrid from "./FolderGrid";
import { createFileExplorerQuery } from "../../../api/services/checklist/queries";
import { createModalController } from "../Modal";
import { EditFolderSidePanel } from "./EditCategorySidePanel";
import DeleteCategoryModalConfirm from "./DeleteCategoryModalConfirm";
import CreateCategoryButton from "./CreateCategoryButton";
import { P } from "../../../utils/typography";
import { JsonDebug } from "../../../utils/debug";
import { Button } from "../components";

export type Folder = {
    id: string;
    name: string;
    parent: Folder["id"] | null;
    icon?: string;
    color?: string;
    description?: string;
    starred?: boolean;
    updated_at?: Temporal.Instant;
    created_at?: Temporal.Instant;
};

export default function GenericFileExplorer<T extends Folder, TEntity>(props: {
    folders: T[];
    filterEntities: (categoryId: string | null) => TEntity[];
    renderEntity?: (entity: TEntity) => JSX.Element;
    EntitiesContainer?: Component<ParentProps>;
    urlPrefix?: string;
    createFolder?: (name: string, parent: string | null) => Promise<T>;
    updateFolder?: (body: UpdateFolder) => Promise<void>;
    deleteFolder?: (body: T) => Promise<void>;
    entityName: string;
    entityDescription?: string;
    createEntity?: (category: T | undefined) => void | Promise<void>;
    headerChildren?: JSX.Element;
}) {
    const actionBarRef = createRef<HTMLElement>();
    const panelRef = createRef<HTMLElement>();
    const fileExplorerQuery = createFileExplorerQuery();
    const editSidePanelController = createModalController<UpdateFolder, void>();
    const deleteModalConfirmController = createModalController<T, void>();

    const [activeFolderId, setActiveFolderId] = createSignal<string>();
    const [currentFolderId, setCurrentFolderId] = createSignal<string>();
    const activeFolder = createMemo(() =>
        props.folders.find(folder => folder.id === activeFolderId()),
    );
    const currentFolder = createMemo(() =>
        props.folders.find(folder => folder.id === currentFolderId()),
    );
    const path = createMemo(() => {
        const path: T["id"][] = [];
        let folder = currentFolder();
        while (folder) {
            path.unshift(folder.id);
            folder = props.folders.find(f => f.id === folder?.parent) ?? undefined;
        }
        return path;
    });
    const openFolder = (folder: T) => setCurrentFolderId(folder.id);
    const params = useParams<{ category_id: string }>();
    createEffect(() => setCurrentFolderId(params.category_id));
    const navigate = useNavigate();
    createEffect(() => {
        if (props.urlPrefix) {
            navigate(
                currentFolderId() ? `${props.urlPrefix}/${currentFolderId()}` : props.urlPrefix,
            );
        }
    });

    const createCategoryButton = () => (
        <CreateCategoryButton
            createFolder={props.createFolder}
            currentFolder={currentFolder()}
            setActiveFolderId={setActiveFolderId}
        />
    );
    const createEntityButton = () => (
        <Show when={props.createEntity}>
            <Button onClick={() => props.createEntity?.(currentFolder())}>
                Crear {props.entityName}
            </Button>
        </Show>
    );

    return (
        <div class={"space-y-6"}>
            <FileExplorerHeader
                currentFolder={currentFolder()}
                entityName={props.entityName}
                entityDescription={props.entityDescription}
                createEntityButton={createEntityButton()}
                headerChildren={props.headerChildren}
                createCategoryButton={createCategoryButton()}
            />
            <div class="flex gap-x-3">
                <div class="flex flex-1 flex-col gap-6">
                    <FileExplorerNavbar
                        path={path()}
                        currentFolder={currentFolder()}
                        setCurrentFolderId={setCurrentFolderId}
                        folders={props.folders}
                    />
                    <FolderGrid
                        folders={props.folders}
                        path={path()}
                        activeFolder={activeFolder()}
                        currentFolder={currentFolder()}
                        onSelect={folder => setActiveFolderId(folder?.id)}
                        onOpen={openFolder}
                        ignoreRefs={[actionBarRef, panelRef]}
                        urlPrefix={props.urlPrefix}
                        deleteFolder={
                            props.deleteFolder ? deleteModalConfirmController.open : undefined
                        }
                        editFolder={props.updateFolder ? editSidePanelController.open : undefined}
                        setActiveFolderId={setActiveFolderId}
                        entityName={props.entityName}
                        createEntityButton={createEntityButton()}
                        createCategoryButton={createCategoryButton()}
                    />
                    <FileEntities
                        entitiesInCategory={props.filterEntities(currentFolderId() ?? null)}
                        currentFolderId={currentFolderId()}
                        entityName={props.entityName}
                        renderEntity={props.renderEntity}
                        EntitiesContainer={
                            props.EntitiesContainer ??
                            (props => <div class="flex flex-wrap gap-3">{props.children}</div>)
                        }
                    />
                </div>
                <Show when={fileExplorerQuery.data?.sidePanel}>
                    <div ref={panelRef} class="w-[20rem] rounded-md border border-light-gray-200">
                        <FileExplorerPanel activeFolder={activeFolder()} />
                    </div>
                </Show>
                <Show when={props.updateFolder}>
                    <EditFolderSidePanel
                        controller={editSidePanelController}
                        updateFolder={props.updateFolder}
                    />
                </Show>
                <Show when={props.deleteFolder}>
                    <DeleteCategoryModalConfirm
                        controller={deleteModalConfirmController}
                        deleteFolder={props.deleteFolder}
                    />
                </Show>
            </div>
        </div>
    );
}

function FileEntities<TEntity>(props: {
    entitiesInCategory: TEntity[];
    currentFolderId: string | undefined;
    entityName: string;
    renderEntity: ((entity: TEntity) => JSX.Element) | undefined;
    EntitiesContainer: Component<ParentProps>;
}) {
    return (
        <div class="container">
            <Show
                when={props.entitiesInCategory.length > 0}
                fallback={
                    props.currentFolderId ? (
                        <P class="text-xs">No tienes {props.entityName} para esta categoría</P>
                    ) : null
                }
            >
                <P>{props.entityName}s</P>
            </Show>
            <props.EntitiesContainer>
                <Show when={props.entitiesInCategory}>
                    {entities => (
                        <For each={entities()}>
                            {entity =>
                                props.renderEntity ? (
                                    props.renderEntity(entity)
                                ) : (
                                    <JsonDebug value={entity as object} />
                                )
                            }
                        </For>
                    )}
                </Show>
            </props.EntitiesContainer>
        </div>
    );
}
