import { Expr } from "./expressions";
import { Grammar, Parser } from "nearley";
// @ts-expect-error - As this is an autogenerated .js file
import grammar from "./grammar";

export type ParseResult = { type: "ok"; expr: Expr } | { type: "error"; message: string };

export function parseCondition(condition: string): ParseResult {
    /* Create a new parser each time as it has state.
     * This is actually a cheap operation, see https://github.com/kach/nearley/issues/129#issuecomment-280495163*/
    const parser = new Parser(Grammar.fromCompiled(grammar));

    try {
        parser.feed(condition);
        const results = parser.results;
        if (results.length === 0) {
            return { type: "error", message: "Syntax error" };
        } else if (results.length === 1) {
            return { type: "ok", expr: results[0] };
        } else {
            console.debug({ results });
            return { type: "error", message: "Ambiguous syntax" };
        }
    } catch (error) {
        if (error instanceof Error && error.message.includes("^"))
            return {
                type: "error",
                message: error.message
                    .slice(error.message.indexOf("1  "), error.message.indexOf("^") + 1)
                    .replace("1  ", "")
                    .replace("   ^", "^"),
            };
        else throw error;
    }
}
