import { P } from "../../../../utils/typography";
import { EventGeneralDataProps } from "../ExecutionEvents";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ActivityDescription(props: EventGeneralDataProps) {
    return (
        <div>
            <P>Description</P>
            <P>Lorem Impsum</P>
        </div>
    );
}
