import { isBlank } from "../../forms/state";
import { Show } from "solid-js";
import { FormWrapper } from "../../forms/FormWrapper";
import TextField from "../../forms/fields/TextField";
import TextAreaField from "../../forms/fields/TextAreaField";
import IconField from "../../forms/fields/IconField";
import ColorField from "../../forms/fields/ColorField";
import SubmitButton from "../../forms/SubmitButton";
import { Folder } from "./GenericFileExplorer";
import _ from "lodash";
import { Button } from "../components";
import { ModalController } from "../Modal";
import SwitchField from "../../forms/fields/SwitchField";
import { UpdateFolder } from "./FileExplorerPanel";

type UpdateFormValues = Omit<Folder, "updated_at" | "created_at" | "parent">;
export default function EditFileExplorerSidePanel<T extends Folder>(props: {
    activeFolder: T | undefined;
    updateFolder: ((body: UpdateFolder) => Promise<void>) | undefined;
    controller: ModalController<UpdateFolder, void>;
}) {
    const saveChanges = async (formValues: UpdateFormValues) => {
        // Needed because backend gives 400 with blank description
        if (isBlank(formValues.description)) delete formValues.description;

        await props.updateFolder?.(formValues);
        props.controller.dismiss();
    };

    return (
        <Show when={props.activeFolder}>
            {folder => (
                <Show keyed when={folder()}>
                    <FormWrapper
                        onSubmit={saveChanges}
                        defaultValues={_.omit(folder(), ["updated_at", "parent"])}
                        class="flex flex-col gap-3 p-4"
                    >
                        <TextField name="name" label="Nombre" />
                        <Show when={"description" in folder()}>
                            <TextAreaField name="description" label="Descripción" optional />
                        </Show>
                        <Show when={"starred" in folder()}>
                            <SwitchField
                                name={"starred"}
                                label={"Categoría destacada"}
                                labelClass={"flex flex-row-reverse justify-end"}
                            />
                        </Show>
                        <Show when={"icon" in folder()}>
                            <IconField name="icon" label="Icono" placeholder="fas fa-folder" />
                        </Show>
                        <Show when={"color" in folder()}>
                            <ColorField name="color" label="Color" optional />
                        </Show>
                        <div class={"flex justify-end gap-x-6"}>
                            <Button
                                onClick={props.controller.dismiss}
                                type="button"
                                bgStyle={"outline"}
                            >
                                Cancelar
                            </Button>
                            <Show when={props.updateFolder}>
                                <SubmitButton submittingText="Guardando cambios...">
                                    Guardar cambios
                                </SubmitButton>
                            </Show>
                        </div>
                    </FormWrapper>
                </Show>
            )}
        </Show>
    );
}
