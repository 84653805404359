import { P } from "../../../../utils/typography";
import { EventGeneralDataProps } from "../ExecutionEvents";

export function ActivityName(props: EventGeneralDataProps) {
    return (
        <div>
            <P>Nombre de la actividad</P>
            <P>{props.element.name}</P>
        </div>
    );
}
