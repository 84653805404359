import { Button } from "../../ui/components";
import { createModalController, Modal } from "../../ui/Modal";
import { Suspense } from "solid-js";
import { P } from "../../../utils/typography";
import { EvaluationForm } from "./EvaluationForm";
import { EvaluationList } from "./EvaluationList";
import EvaluationLabelModal from "./EvaluationSection/EvaluationLabelModal";
import EvaluationLabelConditionModal from "./EvaluationSection/EvaluationLabelConditionModal";

export default function EvaluationSection(props: { checklistId: string }) {
    const createEvaluationModal = createModalController<void, void>();
    const evaluationLabelModal = createModalController<{ evaluationID: string }, void>();
    const evaluationLabelConditionModal = createModalController<{ evaluationID: string }, void>();

    return (
        <>
            <div class={"grid grid-cols-12"}>
                <div class={"col-span-12 mb-4 md:col-span-6"}>
                    <Button
                        type={"button"}
                        onClick={() => createEvaluationModal.open()}
                        class={"ml-auto"}
                    >
                        Crear evaluación
                    </Button>
                    <EvaluationList
                        checklistId={props.checklistId}
                        evaluationLabelModal={evaluationLabelModal}
                        evaluationLabelConditionModal={evaluationLabelConditionModal}
                    />
                </div>

                <Modal controller={createEvaluationModal}>
                    {() => (
                        <Suspense>
                            <Modal.Header class={"pb-4"}>
                                <P class={"text-display-xs font-medium"}>Crear evaluacion</P>
                            </Modal.Header>
                            <EvaluationForm
                                checklistId={props.checklistId}
                                evaluationLabelModal={evaluationLabelModal}
                                evaluationLabelConditionModal={evaluationLabelConditionModal}
                            />
                        </Suspense>
                    )}
                </Modal>
                <EvaluationLabelModal controller={evaluationLabelModal} />
                <EvaluationLabelConditionModal controller={evaluationLabelConditionModal} />
            </div>
        </>
    );
}
