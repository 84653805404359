import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";
import JsonStorage from "../../../utils/JsonStorage";
import { sEditBpnmStorage } from "./v3";

export function createRetrieveWorkflowFormQuery(formId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["workflowForm", formId()],
        queryFn: () => api.workflow.retrieveWorkflowForm(formId()!),
        enabled: !!formId(),
    }));
}

export function createFormByBpmnElementQuery(
    params: Accessor<{
        workflowId: string;
        bpmnElementId: string | undefined;
    }>,
) {
    return createApiQuery(api => ({
        queryKey: ["formByBpmnElement", params()],
        queryFn: () =>
            api.workflow.getFormByBpmnElement({
                workflowId: params().workflowId,
                bpmnElementId: params().bpmnElementId!,
            }),
        enabled: !!params().workflowId && !!params().bpmnElementId,
    }));
}

export function createWorkflowExecutionListQuery() {
    return createApiQuery(api => ({
        queryKey: ["workflowExecutionList"],
        queryFn: () => api.workflow.listWorkflowExecutions.fetchJson(),
        select: api.workflow.listWorkflowExecutions.select,
    }));
}

export function createWorkflowExecutionDetailQuery(executionId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["workflowExecutionDetail", executionId()],
        queryFn: () => api.workflow.retrieveWorkflowExecution.fetchJson(executionId()!),
        select: api.workflow.retrieveWorkflowExecution.select,
        enabled: !!executionId(),
    }));
}

export function createWorkflowElementListQuery(executionId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["workflowElementList", executionId()],
        queryFn: () => api.workflow.listWorkflowElements(executionId()),
    }));
}

export function createFormResponseQuery(
    params: Accessor<{ formId: string | undefined; executionId: string | undefined }>,
) {
    return createApiQuery(api => ({
        queryKey: ["formResponse", params()],
        queryFn: () =>
            api.workflow.retrieveFormResponse(params() as { formId: string; executionId: string }),
        enabled: !!(params().formId && params().executionId),
    }));
}

export function createMyStartEventsQuery(workflowTypeId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["myStartEvents", workflowTypeId()],
        queryFn: () => api.workflow.myStartEvents(workflowTypeId()),
        enabled: !!workflowTypeId(),
    }));
}

export function createExecutionProcessVarsQuery(executionId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["executionProcessVars", executionId],
        queryFn: () => api.workflow.processVars(executionId()),
        enabled: !!executionId(),
    }));
}

export const editBpmnStorage = JsonStorage("edit_bpmn", sEditBpnmStorage());
export function createEditBpmnQuery() {
    return editBpmnStorage.useQuery({
        sidePanel: true,
    });
}
export function createUserTaskDetailQuery(userTaskId: Accessor<string>) {
    return createApiQuery(api => ({
        queryKey: ["userTaskDetail", userTaskId()],
        queryFn: () => api.workflow.userTaskDetail(userTaskId()),
    }));
}

export function createUserTaskByRecurrenceDetailQuery(recurrenceId: Accessor<string | undefined>) {
    return createApiQuery(api => ({
        queryKey: ["userTaskDetailByRecurrence", recurrenceId()],
        queryFn: () => api.workflow.userTaskByRecurrenceDetail(recurrenceId()!),
        enabled: !!recurrenceId(),
    }));
}
