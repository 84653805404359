import { PageWrapper } from "../ui/pageWrappers";
import {
    createChecklistListQuery,
    createListCategoriesQuery,
    createOwnChecklistQuery,
} from "../../api/services/checklist/queries";
import { Show } from "solid-js";
import { Button } from "../ui/components";
import { useNavigate } from "@solidjs/router";
import { Checklist } from "../../api/services/checklist/interface";
import { BorderedCard } from "../ui/cards";
import { P } from "../../utils/typography";
import { defaultFormattedDate } from "../../utils/formatDate";
import GenericFileExplorer from "../ui/genericFileExplorer/GenericFileExplorer";
import {
    createChecklistCategoryMutation,
    updateChecklistCategoryMutation,
} from "../../api/services/checklist/mutations";
import { UpdateFolder } from "../ui/genericFileExplorer/FileExplorerPanel";
import Toastify from "toastify-js";

export function ChecklistPage(props: { type: "EXECUTION" | "ADMINISTRATION" }) {
    const navigate = useNavigate();
    const listChecklist = createChecklistListQuery();
    const listCategories = createListCategoriesQuery();
    const createCategoryMutation = createChecklistCategoryMutation();
    const type = () => props.type;
    const myChecklistsQuery = createOwnChecklistQuery(type() === "EXECUTION");

    const createFolder = async (name: string, parent: string | null) => {
        return await createCategoryMutation.mutateAsync({
            description: "HC DES",
            icon: "far fa-file", // "fas fa-folder",
            name,
            parent,
            starred: false,
        });
    };

    const updateMutation = updateChecklistCategoryMutation();

    const updateFolder = async (folder: UpdateFolder) => {
        await updateMutation.mutateAsync(folder, {
            onSuccess: () => {
                Toastify({
                    text: "Categoria actualizada",
                }).showToast();
            },
        });
    };

    const deleteFolder = async (folder: UpdateFolder) => {
        await new Promise(resolve => {
            resolve(folder.id);
        });
    };

    const checklistsByUser = () => {
        if (props.type === "ADMINISTRATION") {
            return listChecklist.data ?? [];
        } else {
            return listChecklist.data?.filter(cl => !!myChecklistsQuery.data?.[cl.id]) ?? [];
        }
    };

    return (
        <PageWrapper>
            <GenericFileExplorer
                filterEntities={categoryId =>
                    checklistsByUser()?.filter(cl => cl.category === categoryId) ?? []
                }
                renderEntity={checklist => <ChecklistItem checklist={checklist} />}
                EntitiesContainer={props => (
                    <div class="mt-12 grid grid-cols-12 gap-3">{props.children}</div>
                )}
                createFolder={createFolder}
                updateFolder={updateFolder}
                deleteFolder={deleteFolder}
                folders={listCategories.data ?? []}
                urlPrefix={
                    props.type === "ADMINISTRATION" ? "/checklist-administration" : "/checklists"
                }
                entityName={"Checklist"}
                entityDescription={"Descripción de checklist"}
                createEntity={
                    props.type === "ADMINISTRATION"
                        ? category => navigate(`/checklists/create/${category?.id}`)
                        : undefined
                }
                headerChildren={
                    <Button bgStyle={"outline"} onClick={() => navigate("/checklists/executions")}>
                        Ver ejecuciones
                    </Button>
                }
            />
        </PageWrapper>
    );
}

function ChecklistItem(props: { checklist: Checklist }) {
    const navigate = useNavigate();

    return (
        <BorderedCard
            class={`col-span-12 md:col-span-4`}
            color={props.checklist.color}
            onClick={() => navigate(`/checklists/update/${props.checklist.id}`)}
        >
            <div class={"mb-3 flex items-start justify-between gap-x-3"}>
                <P class={"!mb-0 font-medium"}>{props.checklist.name}</P>
                <div class="space-y-2">
                    <Show when={props.checklist.is_active}>
                        <Button
                            bgStyle={"outline"}
                            size={"sm"}
                            onClick={e => {
                                e.stopPropagation();
                                navigate(`/checklists/${props.checklist.id}/execute`);
                            }}
                            icon="fas fa-play"
                        >
                            Ejecutar
                        </Button>
                    </Show>
                </div>
            </div>
            <Show when={props.checklist.created_at}>
                {created_at => (
                    <P class={"!mb-0"}>
                        <strong>Creado el:</strong> {defaultFormattedDate(created_at())}
                    </P>
                )}
            </Show>
            <Show when={props.checklist.updated_at}>
                {updated_at => (
                    <P>
                        <strong>Última actualización:</strong> {defaultFormattedDate(updated_at())}
                    </P>
                )}
            </Show>
            <P class={"font-medium"}>
                Checklist {props.checklist.is_active ? "publicado" : "borrador"}
            </P>
        </BorderedCard>
    );
}

export function ChecklistExecutionPage() {
    return <ChecklistPage type="EXECUTION" />;
}

export function ChecklistsAdministrationPage() {
    return <ChecklistPage type="ADMINISTRATION" />;
}
