import _ from "lodash";
import { ChecklistStepOverview } from "../../../api/services/checklist/interface";
import { createChecklistOverviewQuery } from "../../../api/services/checklist/queries";
import { useRequiredContext } from "../../../utils/solidjs";
import { ChecklistExecutionContext } from "../ChecklistExecutionContext";
import { Button } from "../../ui/components";

export default function ChecklistExecutionBackButton(props: { checklistId: string }) {
    const checklistId = () => props.checklistId;
    const checklistOverviewQuery = createChecklistOverviewQuery(checklistId);
    const { currentStepId, setCurrentStepId } = useRequiredContext(
        ChecklistExecutionContext,
        "ChecklistExecutionBackButton",
        "ChecklistExecutionContextProvider",
    );
    const prevStepId = () => {
        if (!checklistOverviewQuery.data) {
            return null;
        }
        return getPrevStepId(currentStepId(), checklistOverviewQuery.data) ?? null;
    };

    const backButton = () => {
        if (!checklistOverviewQuery.data) {
            return;
        }
        if (prevStepId()) {
            setCurrentStepId(prevStepId());
        }
    };

    return (
        <div>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <Button bgStyle="text-only" onClick={backButton} disabled={!prevStepId()}>
                        Anterior
                    </Button>
                </div>
            </div>
        </div>
    );
}

function getPrevStepId(
    currentStepId: string | null,
    overview: ChecklistStepOverview[],
): string | undefined {
    const flatStepIds = overview.flatMap(section => section.checklist_forms);
    if (currentStepId === null) return _.last(flatStepIds);
    const currentStepIndex = flatStepIds.findIndex(stepId => stepId === currentStepId);
    if (currentStepIndex === -1)
        throw new Error(`checklist_form "${currentStepId}" not found in checklist overview`);
    return flatStepIds[currentStepIndex - 1];
}

export function getNextStepId(
    currentStepId: string,
    overview: ChecklistStepOverview[],
): string | null {
    const flatStepIds = overview.flatMap(section => section.checklist_forms);
    const currentStepIndex = flatStepIds.findIndex(stepId => stepId == currentStepId);
    if (currentStepIndex === -1)
        throw new Error(`checklist_form "${currentStepId}" not found in checklist overview`);
    return flatStepIds[currentStepIndex + 1] ?? null;
}
