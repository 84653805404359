import { Accessor } from "solid-js";
import { createApiQuery } from "../../utils";
import { OrganizationChartGraphParams } from "./interface";

export function createListPositionsQuery() {
    return createApiQuery(api => ({
        queryKey: ["listPositions"],
        queryFn: () => api.position.listPositions.fetchJson(),
        select: api.position.listPositions.select,
    }));
}

export function createOrganizationChartGraphQuery(params: Accessor<OrganizationChartGraphParams>) {
    return createApiQuery(api => ({
        queryKey: ["organizationChartGraph", params()],
        queryFn: () => api.position.retrieveOrganizationChartGraph.fetchJson(params()),
        select: api.position.retrieveOrganizationChartGraph.select,
    }));
}
