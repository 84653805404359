import {
    CreateWorkflow,
    deserializeWorkflow,
    sWorkflow,
    Workflow,
    WorkflowEndpoints,
    WorkflowsQueryParams,
} from "./interface";
import { workflowClient } from "../../../../modules/client/client";
import { array, string, type } from "superstruct";
import { makeServiceQuery } from "../../../utils";

export default class V3WorkflowEndpoints implements WorkflowEndpoints {
    listWorkflows = makeServiceQuery({
        fetchJson: (queryParams?: WorkflowsQueryParams) =>
            workflowClient.get("/workflows/workflows/").query(queryParams).receiveJson(),
        responseSchema: array(sWorkflow()),
        deserialize: arr => arr.map(deserializeWorkflow),
    });

    createWorkflow = async (body: CreateWorkflow): Promise<{ id: string }> =>
        workflowClient
            .post("/workflows/workflows/")
            .sendJson(body)
            .receive(type({ id: string() }));

    retrieveWorkflow = async (workflowId: string): Promise<Workflow> =>
        workflowClient
            .get(`/workflows/workflows/${workflowId}`)
            .receive(sWorkflow())
            .then(deserializeWorkflow);
}
