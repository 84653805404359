import { createApiMutation, queryClient } from "../../utils";
import {
    ExecuteWorkflowParams,
    NewWorkflowVersionPayload,
    PerformWorkflowActionParams,
    SetBpmnParams,
} from "./interface";
import { InputUnion } from "../formbuilder/interfaces/inputs/BaseInput";

export function createWorkflowFormMutation() {
    return createApiMutation(api => ({
        mutationKey: ["createForm"],
        mutationFn: (params: {
            fields: InputUnion[];
            processVars: Record<string, string>;
            workflowId: string;
        }) => api.workflow.createWorkflowForm(params),
    }));
}

export function createSetBpmnMutation() {
    return createApiMutation(api => ({
        mutationKey: ["setBpmn"],
        mutationFn: (params: SetBpmnParams) => api.workflow.setBpmn(params),
        onSettled: (_data, _error, params: SetBpmnParams) => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowDetail"] });
            queryClient.invalidateQueries({
                queryKey: ["formByBpmnElement", { workflowId: params.workflowId }],
            });
        },
    }));
}

export function createActivateWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["activateWorkflow"],
        mutationFn: (workflowId: string) => api.workflow.activateWorkflow(workflowId),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["workflowList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowDetail"] });
        },
    }));
}

export function createExecuteWorkflowMutation() {
    return createApiMutation(api => ({
        mutationKey: ["executeWorkflow"],
        mutationFn: (params: ExecuteWorkflowParams) => api.workflow.executeWorkflow(params),
    }));
}

export function createUpdateWorkflowFormMutation() {
    return createApiMutation(api => ({
        mutationKey: ["updateWorkflowForm"],
        mutationFn: (params: {
            formId: string;
            fields: InputUnion[];
            processVars: Record<string, string>;
        }) => api.workflow.updateWorkflowForm(params),
    }));
}

export function createPerformWorkflowActionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["performWorkflowAction"],
        mutationFn: (params: PerformWorkflowActionParams) =>
            api.workflow.performWorkflowAction(params),
        onSettled: (_data, _error, params: PerformWorkflowActionParams) => {
            queryClient.invalidateQueries({ queryKey: ["workflowExecutionList"] });
            queryClient.invalidateQueries({ queryKey: ["workflowExecutionDetail"] });
            queryClient.invalidateQueries({ queryKey: ["workflowElementList"] });
            queryClient.invalidateQueries({ queryKey: ["formResponse", params.executionId] });
        },
    }));
}

export function createNewWorkflowVersionMutation() {
    return createApiMutation(api => ({
        mutationKey: ["newWorkflowVersion"],
        mutationFn: (payload: NewWorkflowVersionPayload) => api.workflow.newVersion(payload),
        onSettled: (
            _data: { id: string } | undefined,
            _error,
            params: NewWorkflowVersionPayload,
        ) => {
            queryClient.invalidateQueries({ queryKey: ["workflowDetail", params.workflow] });
        },
    }));
}
