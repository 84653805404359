import { PageWrapper } from "../ui/pageWrappers";
import { useParams } from "@solidjs/router";
import { createWorkflowListQuery } from "../../api/services/workflow/workflows/queries";
import { createMyStartEventsQuery } from "../../api/services/workflow/queries";
import { Show, Suspense } from "solid-js";
import { WorkflowsByStartEvent } from "./WorkflowsByStartEvent";

export default function NewExecutionPage() {
    const params = useParams<{ workflowType: string }>();
    const workflowsQuery = createWorkflowListQuery();
    const myStartEvents = createMyStartEventsQuery(() => params.workflowType);
    return (
        <PageWrapper>
            <Suspense>
                <Show when={workflowsQuery.data}>
                    {workflows => (
                        <Show when={myStartEvents.data}>
                            {startEvents => (
                                <WorkflowsByStartEvent
                                    workflows={workflows()}
                                    startEvents={startEvents()}
                                    workflowType={params.workflowType}
                                />
                            )}
                        </Show>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}
