import { createEffect, onCleanup, onMount } from "solid-js";
import * as go from "gojs";
import { createRef } from "../../../utils/reactRefs";
import { WorkflowElement } from "../../../api/services/workflow/interface";

interface SimpleDAGProps {
    elements: WorkflowElement[];
    onNodeClick: (node: WorkflowElement) => void;
}

export const ExecutionFollowingDAG = (props: SimpleDAGProps) => {
    let diagram: go.Diagram;
    const divRef = createRef<HTMLDivElement>();

    onMount(() => {
        // Create a new Diagram
        diagram = go.GraphObject.make(go.Diagram, divRef.current!, {
            initialAutoScale: go.AutoScale.Uniform,
            layout: go.GraphObject.make(go.LayeredDigraphLayout, { direction: 0 }),
        });

        // Define the node template with a square and border, showing name and id
        diagram.nodeTemplate = go.GraphObject.make(
            go.Node,
            "Auto",
            {
                click: (e, obj) => {
                    const nodeData = obj.part?.data as WorkflowElement;
                    props.onNodeClick(nodeData); // Trigger the callback when the node is clicked
                },
            },
            go.GraphObject.make(
                go.Shape,
                "Rectangle",
                { width: 60, height: 60, stroke: "black", strokeWidth: 2 },
                new go.Binding("fill", "isActive", isActive => {
                    // Set color based on isActive
                    return isActive === "active" ? "lightgreen" : "lightcoral";
                }),
            ),
            go.GraphObject.make(
                go.TextBlock,
                { margin: 5, font: "bold 10px sans-serif", textAlign: "center" },
                new go.Binding("text", "", (data: WorkflowElement) => {
                    // Display the node's name and id inside the text block
                    return `${data.name}`;
                }),
            ),
        );

        // Define the link template (arrows between nodes)
        diagram.linkTemplate = go.GraphObject.make(
            go.Link,
            go.GraphObject.make(go.Shape), // Link path
            go.GraphObject.make(go.Shape, { toArrow: "Standard" }), // Arrowhead
        );

        // Cleanup when the component is unmounted
        onCleanup(() => {
            diagram.div = null;
        });
    });

    createEffect(() => {
        const gojsNodes = props.elements.map(node => ({
            key: node.id, // Use `id` as the key in GoJS
            ...node,
        }));

        // Create links between nodes (in sequence)
        const gojsLinks = gojsNodes.slice(1).map((node, index) => ({
            from: gojsNodes[index].key,
            to: node.key,
        }));

        // Update the diagram model with nodes and links
        diagram.model = new go.GraphLinksModel(gojsNodes, gojsLinks);
    });

    return (
        <div ref={divRef} style={{ width: "100%", height: "400px", border: "1px solid black" }} />
    );
};
