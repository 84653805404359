import { Show, Suspense } from "solid-js";
import { createListChecklistConceptsQuery } from "../../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { P } from "../../../utils/typography";
import HierarchicalConcepts from "./HierarchicalConcepts";

export default function ConceptList(props: { evaluationId: string; disabled: boolean }) {
    const conceptListQuery = createListChecklistConceptsQuery(() => props.evaluationId);

    return (
        <div>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={conceptListQuery.data}
                    fallback={<div>No hay conceptos creados para esta evaluación</div>}
                >
                    {conceptList => (
                        <Show
                            when={conceptList().length > 0}
                            fallback={<div>No hay conceptos creados para esta evaluación</div>}
                        >
                            <div class="">
                                <P>Listado de conceptos</P>
                                <HierarchicalConcepts
                                    conceptList={conceptList()}
                                    parentId={null}
                                    disabled={props.disabled}
                                    evaluationId={props.evaluationId}
                                />
                            </div>
                        </Show>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

// function ConceptListItem(props: {concept: Concept}){
//     return (
//         <div>
//             <JsonDebug
//                 value={props.concept}
//             />
//         </div>
//     );
// }
