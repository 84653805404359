import { P } from "../../../utils/typography";
import { Tab } from "../../ui/Tabs";
import AccordionCard, { AccordionCardItem } from "../../ui/AccordionCard";
import { Button } from "../../ui/components";
import { ModalController } from "../../ui/Modal";
import { CircleNumber } from "../../ui/CircleNumber";
import {
    createActivityTasksQuery,
    createGetOrCreateRecurrenceActivityQuery,
    createRecurrenceTasksQuery,
} from "../../../api/services/task-manager/queries";
import { For, Show } from "solid-js";
import { ErrorBlock } from "../../../utils/GenericErrorBoundary";
import TaskCardDispatcher from "./TaskCardDispatcher";
import RecurrenceTaskCardDispatcher from "./RecurrenceTaskCardDispatcher";
import { createUserTaskByRecurrenceDetailQuery } from "../../../api/services/workflow/queries";

type TaskTabProps = {
    modalController: ModalController<void, void>;
    activityId: string;
    recurrenceId: string;
    isExpired: boolean;
};

export function TaskTab(props: TaskTabProps) {
    const id = () => props.activityId;
    const recurrenceId = () => props.recurrenceId;
    const recurrenceActivityQuery = createGetOrCreateRecurrenceActivityQuery(id, recurrenceId);
    const activityTasksQuery = createActivityTasksQuery(id);
    const recurrenceTasksQuery = createRecurrenceTasksQuery(() => recurrenceActivityQuery.data?.id);
    const userTaskByRecurrenceQuery = createUserTaskByRecurrenceDetailQuery(
        () => recurrenceTasksQuery.data?.[0]?.id,
    );
    return (
        <Tab title={"Tareas"}>
            <div class={"mt-3 space-y-3"}>
                <AccordionCard>
                    <AccordionCardItem title={"Detalles"} isOpen>
                        <P class={"text-default"}>
                            {/* {fakeString.substring(0, 115).trimEnd()}... */}
                            <Show when={userTaskByRecurrenceQuery.data}>
                                {data => {
                                    return data().description.substring(0, 115).trimEnd() + "...";
                                }}
                            </Show>
                        </P>
                        <Button
                            bgStyle={"text-only"}
                            class={"ml-auto"}
                            onClick={() => props.modalController.open()}
                        >
                            <span>
                                <i class="fas fa-plus" />
                            </span>
                            Leer más
                        </Button>
                    </AccordionCardItem>

                    <Show
                        when={activityTasksQuery.data}
                        fallback={<ErrorBlock error={activityTasksQuery.error} />}
                    >
                        {data => {
                            return (
                                <AccordionCardItem
                                    title={
                                        <P class={"!mb-0 flex items-center gap-x-3"}>
                                            <span>Tareas</span>
                                            <CircleNumber
                                                number={
                                                    data().filter(
                                                        task =>
                                                            !recurrenceTasksQuery.data?.find(
                                                                rTask => rTask.task === task.id,
                                                            ),
                                                    ).length
                                                }
                                            />
                                        </P>
                                    }
                                    isOpen
                                >
                                    <Show when={recurrenceActivityQuery.data}>
                                        {recurrenceActivity => (
                                            <For
                                                each={data().filter(
                                                    task =>
                                                        !recurrenceTasksQuery.data?.find(
                                                            rTask => rTask.task === task.id,
                                                        ),
                                                )}
                                            >
                                                {task => (
                                                    <TaskCardDispatcher
                                                        task={task}
                                                        isExpired={props.isExpired}
                                                        recurrenceActivityId={
                                                            recurrenceActivity().id
                                                        }
                                                    />
                                                )}
                                            </For>
                                        )}
                                    </Show>
                                    {/* <TaskCard
                                        isReady={false}
                                        isOptional={false}
                                        taskType={"Tomar y subir foto"}
                                        title={"Tome una foto de la basura"}
                                        description={"Tome una foto de la basura acumulada"}
                                    /> */}
                                    {/* <TaskCard
                                        isReady={false}
                                        isOptional={false}
                                        taskType={"Tomar y subir foto"}
                                        title={"Tome una foto de la basura"}
                                        description={"Tome una foto de la basura acumulada"}
                                    />
                                    <TaskCard
                                        isReady={false}
                                        isOptional={false}
                                        taskType={"Tomar y subir foto"}
                                        title={"Tome una foto de la basura depositada en el punto de acopio"}
                                        description={"Tome una foto del punto de acopio"}
                                    /> */}
                                </AccordionCardItem>
                            );
                        }}
                    </Show>

                    <AccordionCardItem
                        title={
                            <P class={"!mb-0 flex items-center gap-x-3"}>
                                <span>Tareas resueltas</span>
                                <CircleNumber number={recurrenceTasksQuery.data?.length ?? 0} />
                            </P>
                        }
                        isOpen
                    >
                        {/* <TaskCard
                            isReady
                            isOptional={false}
                            isDone
                            taskType={"Confirmación manual"}
                            title={"¿Tienes los implementos necesarios sacar la basura?"}
                            description={"Se solicita confirmación manual."}
                        />
                        <TaskCard
                            isReady={false}
                            isOptional={false}
                            isDone
                            taskType={"Tomar y subir foto"}
                            title={"Tome una foto de la basura"}
                            description={"Tome una foto de la basura acumulada"}
                        />  */}
                        <Show
                            when={recurrenceTasksQuery.data}
                            fallback={<ErrorBlock error={recurrenceTasksQuery.error} />}
                        >
                            {recurrenceTasks => (
                                <For each={recurrenceTasks()}>
                                    {recurrenceTask => (
                                        <RecurrenceTaskCardDispatcher
                                            recurrenceTask={recurrenceTask}
                                        />
                                    )}
                                </For>
                            )}
                        </Show>
                    </AccordionCardItem>
                </AccordionCard>
            </div>
        </Tab>
    );
}
