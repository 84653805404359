import {
    formatDateAndMonth,
    formattedDate,
    getHmsDurationBetween,
    printRemainingTime,
} from "../../utils/formatDate";
import { P } from "../../utils/typography";
import { Badge, Button } from "../ui/components";
import Dropdown from "../ui/Dropdown";
import { ActivityItem, Priority } from "../../api/services/task-manager/interface";
import { createMemo, Match, Show, Switch } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { useClock } from "../../utils/clock";

export default function ActivityItemDesktop(props: { activity: ActivityItem }) {
    const navigate = useNavigate();
    const { nowZdt } = useClock();
    const remainingTime = createMemo(() => getHmsDurationBetween(nowZdt(), props.activity.dueDate));
    const disabled = () => remainingTime().hours > 24;

    return (
        <div class={"relative grid grid-cols-6 gap-2 bg-white p-4"}>
            <Show when={disabled()}>
                <div class={"z-10 absolute left-0 top-0 h-full w-full bg-black/5"} />
            </Show>
            <div class={"col-span-4"} role="button" onClick={() => navigate(props.activity.href)}>
                <div class={"flex gap-x-2"}>
                    <ActivityIcon priority={props.activity.priority} />
                    <div class={"flex flex-col justify-start gap-y-1"}>
                        <P class={"!mb-0 text-default"}>
                            {props.activity.title}{" "}
                            {formattedDate(props.activity.dueDate.toPlainDate(), "only-numbers")}
                        </P>
                        {/* <Badge
                            class={
                                "flex w-fit items-center gap-x-1 border border-light-gray-200 !bg-white"
                            }
                        >
                            <i class="far fa-calendar" />
                            <P class={"text- !mb-0"}>Programada - Aún no puede ser iniciada</P>
                        </Badge> */}
                    </div>
                </div>
            </div>
            <div class={"col-span-2 flex items-start justify-end gap-x-2"}>
                <Show when={props.activity.venue}>
                    {venue => (
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            {venue().name}
                        </Badge>
                    )}
                </Show>
                <Switch
                    fallback={
                        <Countdown
                            remainingTime={remainingTime()}
                            priority={props.activity.priority}
                        />
                    }
                >
                    <Match when={props.activity.priority === Priority.Reminder}>
                        <Dropdown
                            trigger={onClick => (
                                <button class="" onClick={onClick}>
                                    <i class="fas fa-ellipsis-v" />
                                </button>
                            )}
                        >
                            <div
                                class={
                                    "rounded-md border-light-gray-200 bg-white shadow-light-gray-200"
                                }
                            >
                                <Button
                                    bgStyle={"text-only"}
                                    class={"whitespace-nowrap p-0 text-left !text-primary-700"}
                                >
                                    Marcar como resuelto
                                </Button>
                                <Button
                                    bgStyle={"text-only"}
                                    class={"whitespace-nowrap p-0 text-left !text-primary-700"}
                                >
                                    Postergar recordatorio
                                </Button>
                                <Button
                                    bgStyle={"text-only"}
                                    class={"whitespace-nowrap p-0 text-left !text-error-600"}
                                >
                                    Eliminar recordatorio
                                </Button>
                            </div>
                        </Dropdown>
                    </Match>
                    <Match when={disabled() || remainingTime().hours >= 24}>
                        <P class={"!mb-0 text-sm"}>
                            <i class="far fa-calendar text-sm" />{" "}
                            {formatDateAndMonth(props.activity.dueDate.toPlainDate())}
                        </P>
                    </Match>
                </Switch>
            </div>
        </div>
    );
}

export function Countdown(props: { remainingTime: Temporal.Duration; priority: Priority }) {
    const remainingTime = createMemo(() => props.remainingTime.round({ largestUnit: "hour" }));
    const urgent = () => props.priority < Priority.Medium;
    const expired = createMemo(() => remainingTime().sign <= 0);

    return (
        <div>
            <Badge
                class={
                    urgent()
                        ? "flex items-center gap-x-1 border border-error-100 bg-error-50 font-medium text-error-700"
                        : "flex items-center gap-x-1 border border-warning-200 bg-warning-50 font-medium text-warning-700"
                }
            >
                <i
                    classList={{
                        "fas fa-hourglass-half text-warning-500": !expired() && !urgent(),
                        "fas fa-hourglass-half text-error-500": !expired() && urgent(),
                        "fas fa-times text-error-500": expired(),
                    }}
                />{" "}
                <Show when={remainingTime().sign < 0}>&mdash;</Show>
                {printRemainingTime(
                    remainingTime().abs().hours,
                    remainingTime().abs().minutes,
                    remainingTime().abs().seconds,
                )}
            </Badge>
        </div>
    );
}

export function ActivityIcon(props: { priority: Priority }) {
    return (
        <Switch>
            <Match when={props.priority === Priority.Reminder}>
                <i class="far fa-bell text-sm text-primary-400" />
            </Match>
            <Match when={props.priority === Priority.TwoUpArrows}>
                <i class="fas fa-angle-double-up text-sm text-warning-500" />
            </Match>
            <Match when={props.priority === Priority.ThreeUpArrows}>
                <div class={"flex flex-col justify-center"}>
                    <i class="fas fa-angle-up -mb-2 text-sm text-error-600" />
                    <i class="fas fa-angle-double-up mt-0.5 text-sm text-error-600" />
                </div>
            </Match>
        </Switch>
    );
}
