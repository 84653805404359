import { For, Show, createEffect, createSignal } from "solid-js";
import { createRetrieveParserLogicQuery } from "../../api/services/logic-parser/queries";
import { ModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import { SearchBar } from "../ui/SearchBar";
import { LogicJson } from "../../api/services/logic-parser/interface";
import { createEvaluateLogicParserMutation } from "../../api/services/logic-parser/mutations";
import { P } from "../../utils/typography";
import { Venue } from "../../api/services/locations/interface";

export function VenuesByUserPreviewSidePanel(props: { controller: ModalController<void, void> }) {
    const id = () => "5cd90921-a438-425c-9915-415ed1eada59";
    const query = createRetrieveParserLogicQuery(id);
    return (
        <SidePanel position="left" controller={props.controller}>
            <SearchVenues />
            <Show when={query.data}>
                {data => {
                    return <VenueList expression={data()} />;
                }}
            </Show>
        </SidePanel>
    );
}

function SearchVenues() {
    return (
        <div>
            <SearchBar placeholder="Buscar reciento" variant="white" />
        </div>
    );
}

function VenueList(props: { expression: LogicJson }) {
    const [results, setResults] = createSignal<Venue[]>([]);
    const evaluateMutation = createEvaluateLogicParserMutation();

    createEffect(() => {
        evaluateMutation.mutate(
            {
                model: props.expression.model,
                expression: props.expression.expression!,
            },
            {
                onSuccess(data) {
                    const venues = data as Venue[];
                    setResults(venues);
                },
            },
        );
    });
    return (
        <div>
            <div class="flex space-x-2">
                <P>Nombre</P>
                <P>Código</P>
            </div>
            <For each={results()}>{venue => <VenueListItem venue={venue} />}</For>
        </div>
    );
}

function VenueListItem(props: { venue: Venue }) {
    return (
        <div class="flex space-x-2">
            <P>{props.venue.name}</P>
            <P>{props.venue.code}</P>
        </div>
    );
}
