import { workflowElementTypesEs } from "../../../../api/services/workflow/interface";
import { H1 } from "../../../../utils/typography";
import { useLocale } from "../../../i18n/context";
import { RecordTable, Entry } from "../../../ui/RecordTable";
import { EventGeneralDataProps } from "../ExecutionEvents";

export function BasicActivityHeader(props: EventGeneralDataProps) {
    const [locale] = useLocale();
    return (
        <div>
            <H1>{props.element.name || "--"}</H1>
            <RecordTable>
                <Entry name="Tipo de evento" value={workflowElementTypesEs[props.element.type]} />
                <Entry
                    name="Iniciado el"
                    value={Intl.DateTimeFormat(locale().codeWithCountry, {
                        dateStyle: "long",
                        timeStyle: "short",
                    }).format(props.element.createdAt)}
                />
            </RecordTable>
        </div>
    );
}
