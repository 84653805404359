import { Modal, ModalController } from "../../../ui/Modal";
import { P } from "../../../../utils/typography";
import { FormWrapper } from "../../../forms/FormWrapper";
import HiddenField from "../../../forms/fields/HiddenField";
import { createEvaluationLabelMutation } from "../../../../api/services/checklist/mutations";
import { CreateEvaluationLabelPayload } from "../../../../api/services/checklist/interface";
import TextField from "../../../forms/fields/TextField";
import IconField from "../../../forms/fields/IconField";
import { Button } from "../../../ui/components";
import SubmitButton from "../../../forms/SubmitButton";
import { createForm } from "../../../forms/state";
import { ColorPickerChecklistField } from "../ColorPickerChecklistField";

export default function EvaluationLabelModal(props: {
    controller: ModalController<{ evaluationID: string }, void>;
}) {
    const createEvaluationLabel = createEvaluationLabelMutation();
    async function onSubmit(values: CreateEvaluationLabelPayload) {
        await createEvaluationLabel.mutateAsync(values, {
            onSuccess: () => {
                props.controller.dismiss();
            },
        });
    }

    return (
        <Modal controller={props.controller}>
            {data => (
                <>
                    <Modal.Header>
                        <P class={"text-lg font-medium"}>Agregar interpretación</P>
                    </Modal.Header>
                    <EvaluationLabelForm
                        evaluationID={data.evaluationID}
                        onCancel={props.controller.dismiss}
                        onSubmit={onSubmit}
                    />
                </>
            )}
        </Modal>
    );
}

export function EvaluationLabelForm(props: {
    onCancel: () => void;
    onSubmit: (values: CreateEvaluationLabelPayload) => void;
    evaluationID: string;
    defaultValues?: CreateEvaluationLabelPayload;
}) {
    const form = createForm<CreateEvaluationLabelPayload>();
    return (
        <FormWrapper staticForm={form} onSubmit={props.onSubmit} class={"w-full space-y-4"}>
            <HiddenField name={"evaluation"} defaultValue={props.evaluationID} />
            <TextField name={"name"} label={"Nombre"} defaultValue={props.defaultValues?.name} />
            <ColorPickerChecklistField
                name={"color"}
                label={"Color"}
                defaultValue={props.defaultValues?.color}
            />
            <IconField name={"icon"} label={"Icono"} defaultValue={props.defaultValues?.icon} />

            <div class={"flex justify-between gap-x-4"}>
                <Button type={"button"} onClick={props.onCancel} bgStyle={"outline"}>
                    Cancelar
                </Button>
                <SubmitButton>{props.defaultValues ? "Guardar" : "Crear"}</SubmitButton>
            </div>
        </FormWrapper>
    );
}
