import { BpmnElement, KnownElementType } from "./bpmn";
import { useLocale } from "../i18n/context";
import { H1, P } from "../../utils/typography";
import { isBlank } from "../forms/state";
import { Accessor, ErrorBoundary, For, JSX, Show } from "solid-js";
import _ from "lodash";
import xmlFormat from "xml-formatter";

export function ElementTitle(props: { element: BpmnElement }) {
    const [locale] = useLocale();

    return (
        <H1>
            {props.element.name}
            <Show when={isBlank(props.element.name)}>
                <span class="italic text-light-gray-400">
                    {locale().workflows.untitled(
                        locale().workflows.bpmnElementTypes[
                            props.element.type as KnownElementType
                        ] ?? locale().workflows.element,
                    )}
                </span>
            </Show>
        </H1>
    );
}

export function ThreeColumnsOnDesktop<T>(props: {
    each: T[];
    fallback: JSX.Element;
    children: (item: T, index: Accessor<number>) => JSX.Element;
}) {
    return (
        <Show when={!_.isEmpty(props.each)} fallback={props.fallback}>
            <div class={"my-3 grid grid-cols-1 flex-wrap gap-3 md:grid-cols-12"}>
                <For each={props.each}>
                    {(item, index) => (
                        <div class={"col-span-1 md:col-span-4"}>{props.children(item, index)}</div>
                    )}
                </For>
            </div>
        </Show>
    );
}

export function ExplainSaveOnClose(props: { mode: "viewer" | "modeler" }) {
    return (
        <>
            <Show when={props.mode === "viewer"}>
                <P>Para editar estos datos, cierra este panel y presiona Editar BPMN.</P>
            </Show>
            <Show when={props.mode === "modeler"}>
                <P>Para guardar los cambios, cierra este panel y presiona Guardar cambios.</P>
            </Show>
        </>
    );
}

export function XmlDebug(props: { value: string }) {
    return (
        <pre>
            <ErrorBoundary fallback={props.value}>{xmlFormat(props.value)}</ErrorBoundary>
        </pre>
    );
}
