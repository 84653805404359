import { createRef, MutableRefObject } from "../../../utils/reactRefs";
import { useOnClickOutside } from "../Dropdown";
import {
    Accessor,
    createEffect,
    createMemo,
    For,
    JSX,
    Match,
    Show,
    Switch,
    untrack,
} from "solid-js";
import { P } from "../../../utils/typography";
import { Folder } from "./GenericFileExplorer";
import FolderItem from "./FolderItem";
import ListGridRender from "./ListGridRender";
import { createFileExplorerQuery } from "../../../api/services/checklist/queries";
import { FileExplorerMode } from "../../../api/services/checklist/implementations/v3";

export type FolderGridProps<T extends Folder> = {
    folders: T[];
    path: T["id"][];
    activeFolder: T | undefined;
    currentFolder: T | undefined;
    onSelect: (folder: T | undefined) => void;
    onOpen: (folder: T) => void;
    ignoreRefs: MutableRefObject<HTMLElement>[];
    urlPrefix?: string;
    deleteFolder: ((folder: T) => void) | undefined;
    editFolder?: ((folder: T) => void) | undefined;
    setActiveFolderId: (folderId: string | undefined) => void;
    entityName: string;
    createEntityButton?: JSX.Element;
    createCategoryButton: JSX.Element;
};
export default function FolderGrid<T extends Folder>(props: FolderGridProps<T>) {
    const fileExplorerQuery = createFileExplorerQuery();
    const gridMode = () => fileExplorerQuery.data?.mode;
    const ref = createRef<HTMLDivElement>();

    useOnClickOutside(
        ref,
        () => props.onSelect(undefined),
        untrack(() => props.ignoreRefs),
    );
    const onClickFolder = (e: Event, folder: T) => {
        e.stopPropagation();
        props.onSelect(folder);
    };
    const onDblClickFolder = (e: Event, folder: T) => {
        e.stopPropagation();
        props.onSelect(undefined);
        props.onOpen(folder);
    };

    const filteredFolders = createMemo(() =>
        props.folders
            .filter(folder => folder.parent === (props.currentFolder?.id ?? null))
            .toSorted((a, b) => Number(b.starred) - Number(a.starred)),
    );

    createEffect(() => {
        console.log({ filteredFolders: filteredFolders() });
    });
    return (
        <div
            ref={ref}
            classList={{ "grid gap-6": gridMode() === FileExplorerMode.FOLDER }}
            style={
                gridMode() === FileExplorerMode.FOLDER
                    ? { "grid-template-columns": "repeat(auto-fill, 13rem)" }
                    : {}
            }
        >
            <Show when={filteredFolders().length === 0}>
                <div class={"flex flex-col items-center justify-center gap-y-5 py-6"}>
                    <div>
                        <div
                            class={
                                "flex h-10 w-10 items-center justify-center rounded-md border border-light-gray-200"
                            }
                        >
                            <i class="far fa-folder"></i>
                        </div>
                    </div>
                    <P class={"text-xl font-medium"}>No existen {props.entityName}</P>
                    <div class={"flex gap-x-6"}>
                        {props.createCategoryButton}
                        {props.createEntityButton}
                    </div>
                </div>
            </Show>
            <Switch>
                <Match when={gridMode() === FileExplorerMode.FOLDER}>
                    <FolderGridRender
                        {...props}
                        filteredFolders={filteredFolders}
                        onClickFolder={onClickFolder}
                        onDblClickFolder={onDblClickFolder}
                    />
                </Match>
                <Match when={gridMode() === FileExplorerMode.LIST}>
                    <ListGridRender
                        {...props}
                        filteredFolders={filteredFolders}
                        onClickFolder={onClickFolder}
                        onDblClickFolder={onDblClickFolder}
                    />
                </Match>
            </Switch>
        </div>
    );
}

export function FolderGridRender<T extends Folder>(
    props: FolderGridProps<T> & {
        filteredFolders: Accessor<T[]>;
        onClickFolder: (e: Event, folder: T) => void;
        onDblClickFolder: (e: Event, folder: T) => void;
    },
) {
    return (
        <For each={props.filteredFolders()}>
            {folder => (
                <FolderItem
                    folder={folder}
                    onClick={e => props.onClickFolder(e, folder)}
                    onDblClick={e => props.onDblClickFolder(e, folder)}
                    isActive={folder.id === props.activeFolder?.id}
                    deleteFolder={props.deleteFolder}
                    editFolder={props.editFolder}
                    setActiveFolderId={props.setActiveFolderId}
                />
            )}
        </For>
    );
}
