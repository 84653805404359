import { lazy } from "solid-js";

const BpmnModeler = lazy(() => import("./BpmnModeler"));
const BpmnOverlay = lazy(() => import("./BpmnOverlay"));
const BpmnViewer = lazy(() => import("./BpmnViewer"));

export { BpmnModeler, BpmnOverlay, BpmnViewer };
export {
    BoundaryEvent,
    BpmnElement,
    IntermediateThrowEvent,
    SequenceFlow,
    StartEvent,
    UnknownElementType,
    UserTask,
    TimerIntermediateCatchEvent,
} from "./BpmnElement";
export type { KnownElementType, Message } from "./BpmnElement";
