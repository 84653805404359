import {
    Infer,
    Struct,
    array,
    boolean,
    enums,
    integer,
    literal,
    nullable,
    number,
    string,
    type,
    union,
} from "superstruct";
import { WorkflowElementType } from "../interface";
import {
    defensive,
    deserializeInstantDefensive,
    sDateTimeString,
    ServiceQuery,
} from "../../../utils";

export interface WorkflowEndpoints {
    listWorkflows: ServiceQuery<[queryParams?: WorkflowsQueryParams], Workflow[]>;
    createWorkflow(body: CreateWorkflow): Promise<{ id: string }>;
    retrieveWorkflow(workflowId: string): Promise<Workflow>;
}

/* ┳
   ┃┏┓┏┓┓┏╋
   ┻┛┗┣┛┗┻┗
      ┛    */

export type WorkflowsQueryParams = {
    workflow_type?: string;
};

export type CreateWorkflow = {
    name: string;
    category: string;
    read_executions_audience: string;
};

/* ┏┓
   ┃┃┓┏╋┏┓┓┏╋
   ┗┛┗┻┗┣┛┗┻┗
        ┛    */

// === Workflow ===

export type Workflow = Omit<WorkflowJson, "start_events" | "workflow_group"> & {
    start_events: StartEvent[];
    workflow_group: WorkflowGroup;
    name: string;
};

export type WorkflowJson = {
    id: string;
    is_active: boolean;
    bpmn: string | null;
    start_events: V3StartEvent[];
    version: number | null;
    workflow_group: WorkflowGroupJson;
};

export function sWorkflow(): Struct<WorkflowJson> {
    return type({
        id: string(),
        is_active: defensive(boolean(), false),
        bpmn: defensive(nullable(string()), null),
        start_events: defensive(array(sV3StartEvent()), []),
        version: defensive(nullable(integer()), null),
        workflow_group: sWorkflowGroup(),
    });
}

export function deserializeWorkflow(json: WorkflowJson): Workflow {
    return {
        ...json,
        bpmn: json.bpmn !== "" ? json.bpmn : null,
        start_events: json.start_events.map(deserializeStartEvent),
        workflow_group: deserializeWorkflowGroup(json.workflow_group),
        name: json.workflow_group.name,
    };
}

// === WorkflowGroup ===

export type WorkflowGroup = Omit<WorkflowGroupJson, "created_at" | "updated_at"> & {
    created_at?: Temporal.Instant;
    updated_at?: Temporal.Instant;
};

export type WorkflowGroupJson = {
    id: string;
    created_at?: string;
    updated_at?: string;
    read_executions_audience: string;
    name: string;
    category: string;
};

export function sWorkflowGroup(): Struct<WorkflowGroupJson> {
    return type({
        id: string(),
        created_at: defensive(sDateTimeString(), undefined),
        updated_at: defensive(sDateTimeString(), undefined),
        read_executions_audience: string(),
        name: string(),
        category: string(),
    });
}

export function deserializeWorkflowGroup(json: WorkflowGroupJson): WorkflowGroup {
    return {
        ...json,
        created_at: deserializeInstantDefensive(json.created_at),
        updated_at: deserializeInstantDefensive(json.updated_at),
    };
}

// === StartEvent ===

export type StartEvent =
    | {
          type: WorkflowElementType.VanillaStartEvent;
          bpmnElementId: string;
      }
    | {
          type: WorkflowElementType.MessageStartEvent;
          bpmnElementId: string;
          name: string;
      };

export type V3StartEvent = Infer<ReturnType<typeof sV3StartEvent>>;

export function sV3StartEvent() {
    return union([
        type({
            type: literal(V3WorkflowElementType.StartEvent),
            bpmn_id: string(),
        }),
        type({
            type: literal(V3WorkflowElementType.MessageStartEvent),
            bpmn_id: string(),
            name: string(),
        }),
    ]);
}
export function sV3UserStartEvent() {
    return type({
        id: string(),
        created_at: string(),
        updated_at: string(),
        bpmn_id: string(),
        start_event_type: enums(["start_event", "message_start_event"]),
        user: number(),
        workflow: string(),
    });
}
export function deserializeStartEvent(payload: V3StartEvent): StartEvent {
    switch (payload.type) {
        case V3WorkflowElementType.StartEvent:
            return {
                type: WorkflowElementType.VanillaStartEvent,
                bpmnElementId: payload.bpmn_id,
            };
        case V3WorkflowElementType.MessageStartEvent:
            return {
                type: WorkflowElementType.MessageStartEvent,
                bpmnElementId: payload.bpmn_id,
                name: payload.name,
            };
    }
}

// === WorkflowElementType ===

export enum V3WorkflowElementType {
    StartEvent = "start_event",
    MessageStartEvent = "message_start_event",
    UserTask = "user_task",
    TimerIntermediateCatchEvent = "timer_intermediate_catch_event",
    MessageIntermediateCatchEvent = "message_intermediate_catch_event",
    MessageIntermediateThrowEvent = "message_intermediate_throw_event",
    EndEvent = "end_event",
    ErrorEndEvent = "error_end_event",
    BoundaryEvent = "timer_boundary_event",
}

export function deserializeWorkflowElementType(v3Type: V3WorkflowElementType): WorkflowElementType {
    switch (v3Type) {
        case V3WorkflowElementType.StartEvent:
            return WorkflowElementType.VanillaStartEvent;
        case V3WorkflowElementType.MessageStartEvent:
            return WorkflowElementType.MessageStartEvent;
        case V3WorkflowElementType.UserTask:
            return WorkflowElementType.UserTask;
        case V3WorkflowElementType.TimerIntermediateCatchEvent:
            return WorkflowElementType.TimerIntermediateCatchEvent;
        case V3WorkflowElementType.MessageIntermediateCatchEvent:
            return WorkflowElementType.MessageIntermediateCatchEvent;
        case V3WorkflowElementType.MessageIntermediateThrowEvent:
            return WorkflowElementType.MessageIntermediateThrowEvent;
        case V3WorkflowElementType.EndEvent:
            return WorkflowElementType.SuccessEndEvent;
        case V3WorkflowElementType.ErrorEndEvent:
            return WorkflowElementType.ErrorEndEvent;
        case V3WorkflowElementType.BoundaryEvent:
            return WorkflowElementType.TimerBoundaryEvent;
    }
}
