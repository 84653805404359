import { P } from "../../../../utils/typography";
import { Button } from "../../../ui/components";
import { Accessor, createSignal, For, ParentProps, Setter, Show } from "solid-js";
import { ModalController } from "../../../ui/Modal";
import {
    createEvaluationLabelConditionListQuery,
    createListEvaluationLabelsQuery,
    createRetrieveEvaluationLabelQuery,
} from "../../../../api/services/checklist/queries";
import {
    CreateEvaluationLabelConditionPayload,
    CreateEvaluationLabelPayload,
    EvaluationLabel,
    EvaluationLabelCondition,
} from "../../../../api/services/checklist/interface";
import { EvaluationLabelForm } from "./EvaluationLabelModal";
import {
    deleteEvaluationLabelConditionMutation,
    deleteEvaluationLabelMutation,
    patchEvaluationLabelMutation,
    updateEvaluationLabelConditionMutation,
} from "../../../../api/services/checklist/mutations";
import { EvaluationLabelConditionForm } from "./EvaluationLabelConditionModal";

type EvaluationLabelSectionProps = {
    evaluationLabelModal: ModalController<{ evaluationID: string }, void>;
    evaluationLabelConditionModal: ModalController<{ evaluationID: string }, void>;
    evaluationId: string;
    disabled: boolean;
};

export default function EvaluationLabelSection(props: EvaluationLabelSectionProps) {
    return (
        <div class={"mt-10 space-y-8"}>
            <div>
                <P class={"text-xl font-medium"}>Interpretación</P>
                <P class={"text-sm"}>Cómo será interpretado el resultado de la evaluación</P>
            </div>
            <EvaluationLabelList {...props} />
            <EvaluationLabelConditionList {...props} />
        </div>
    );
}

function EvaluationLabelList(props: EvaluationLabelSectionProps) {
    const evaluationLabelListQuery = createListEvaluationLabelsQuery(props.evaluationId);
    return (
        <div class={"space-y-3"}>
            <div class={"flex items-center justify-between"}>
                <P class={"text-md font-medium"}>Configuración de interpretaciones</P>
                <Button
                    bgStyle={"outline"}
                    type={"button"}
                    disabled={props.disabled}
                    onClick={() => {
                        props.evaluationLabelModal.open({ evaluationID: props.evaluationId });
                    }}
                >
                    Agregar interpretación
                </Button>
            </div>
            <div class={"flex flex-col gap-y-4"}>
                <For
                    each={evaluationLabelListQuery.data}
                    fallback={
                        <P class={"text-sm text-light-gray-700"}>
                            No hay ninguna interpretación creada
                        </P>
                    }
                >
                    {evaluationLabel => (
                        <InterpretationItem {...evaluationLabel} disabled={props.disabled} />
                    )}
                </For>
            </div>
        </div>
    );
}

function EvaluationLabelConditionList(props: EvaluationLabelSectionProps) {
    const labelConditionListQuery = createEvaluationLabelConditionListQuery(props.evaluationId);
    return (
        <div class={"space-y-3"}>
            <div class={"flex items-center justify-between"}>
                <P class={"text-md font-medium"}>Asignación según rango de notas</P>
                <Button
                    bgStyle={"outline"}
                    type={"button"}
                    disabled={props.disabled}
                    onClick={() => {
                        props.evaluationLabelConditionModal.open({
                            evaluationID: props.evaluationId,
                        });
                    }}
                >
                    Agregar asignación
                </Button>
            </div>
            <div class={"flex flex-col gap-y-4"}>
                <For
                    each={labelConditionListQuery.data}
                    fallback={<P class={"text-sm text-light-gray-700"}>No existen asignaciones</P>}
                >
                    {evaluationLabel => (
                        <InterpretationAssignationItem
                            {...evaluationLabel}
                            evaluationID={props.evaluationId}
                            disabled={props.disabled}
                        />
                    )}
                </For>
            </div>
        </div>
    );
}

function InterpretationItem(props: EvaluationLabel & { disabled: boolean }) {
    const [isEditing, setIsEditing] = createSignal(false);
    const updateEvaluationLabel = patchEvaluationLabelMutation();
    const deleteEvaluationLabel = deleteEvaluationLabelMutation();

    async function onSubmit(values: Partial<CreateEvaluationLabelPayload>) {
        await updateEvaluationLabel.mutateAsync(
            { ...values, id: props.id },
            {
                onSuccess: () => {
                    setIsEditing(false);
                },
            },
        );
    }

    async function onDelete(id: string) {
        await deleteEvaluationLabel.mutateAsync(id);
    }

    return (
        <InterpretationContainer>
            <Show when={isEditing()}>
                <EvaluationLabelForm
                    evaluationID={props.evaluation}
                    onCancel={() => setIsEditing(false)}
                    onSubmit={onSubmit}
                    defaultValues={props}
                />
            </Show>
            <Show when={!isEditing()}>
                <InterpretationName {...props} />
            </Show>
            <InterpretationActionButton
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onDelete={() => onDelete(props.id)}
                disabled={props.disabled}
            />
        </InterpretationContainer>
    );
}

function InterpretationAssignationItem(
    props: EvaluationLabelCondition & { evaluationID: string; disabled: boolean },
) {
    const [isEditing, setIsEditing] = createSignal(false);
    const retrieveLabel = createRetrieveEvaluationLabelQuery(props.evaluation_label);

    const updateEvaluationConditionLabel = updateEvaluationLabelConditionMutation();
    const deleteEvaluationLabelCondition = deleteEvaluationLabelConditionMutation();

    async function onSubmit(values: Partial<CreateEvaluationLabelConditionPayload>) {
        await updateEvaluationConditionLabel.mutateAsync(
            { ...values, id: props.id },
            {
                onSuccess: () => {
                    setIsEditing(false);
                },
            },
        );
    }

    async function onDelete(id: string) {
        await deleteEvaluationLabelCondition.mutateAsync(id);
    }

    return (
        <InterpretationContainer>
            <Show when={isEditing()}>
                <EvaluationLabelConditionForm
                    evaluationID={props.evaluationID}
                    onCancel={() => setIsEditing(false)}
                    onSubmit={onSubmit}
                    defaultValues={props}
                />
            </Show>
            <Show when={!isEditing()}>
                <div class={"flex items-center gap-x-3"}>
                    <div>
                        <P class={"text-sm"}>
                            Desde <span class={"font-medium"}>{props.min_grade}</span> hasta{" "}
                            <span class={"font-medium"}>{props.max_grade}</span>
                        </P>
                    </div>
                    <div>
                        <i class="fas fa-arrow-right text-light-gray-500" />
                    </div>
                    <Show when={retrieveLabel.data}>
                        {label => <InterpretationName {...label()} />}
                    </Show>
                </div>
            </Show>
            <InterpretationActionButton
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onDelete={() => onDelete(props.id)}
                disabled={props.disabled}
            />
        </InterpretationContainer>
    );
}

function InterpretationContainer(props: ParentProps) {
    return (
        <div
            class={"flex w-full justify-between rounded-sm border border-light-gray-200 px-4 py-2"}
        >
            {props.children}
        </div>
    );
}

function InterpretationName(props: { name: string; color: string; icon: string }) {
    return (
        <div class={"flex items-center gap-x-2"}>
            <i class={props.icon} style={{ color: props.color }} />
            <P class={"text-sm"}>{props.name}</P>
        </div>
    );
}

function InterpretationActionButton(props: {
    isEditing: Accessor<boolean>;
    setIsEditing: Setter<boolean>;
    onDelete(): void;
    disabled: boolean;
}) {
    return (
        <Show when={!props.isEditing()}>
            <div class={"flex gap-x-2"}>
                <Button
                    icon={"far fa-edit"}
                    bgStyle={"text-only"}
                    disabled={props.disabled}
                    onClick={() => props.setIsEditing(true)}
                />
                <Button
                    icon={"far fa-trash-alt"}
                    bgStyle={"text-only"}
                    disabled={props.disabled}
                    variant={"danger"}
                    type={"button"}
                    onClick={props.onDelete}
                />
            </div>
        </Show>
    );
}
