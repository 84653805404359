import { Show } from "solid-js";
import { P } from "../../../utils/typography";
import { Folder } from "./GenericFileExplorer";
import { defaultFormattedDate } from "../../../utils/formatDate";

export type UpdateFolder = {
    id: string;
    name?: string;
    description?: string;
    starred?: boolean;
    icon?: string;
    color?: string;
};

const formatDate: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
};
export default function FileExplorerPanel<T extends Folder>(props: {
    activeFolder: T | undefined;
}) {
    return (
        <Show when={props.activeFolder} fallback={<NoActiveFolder />}>
            {folder => {
                return (
                    <div>
                        <div class={"border-b border-light-gray-200 p-4"}>
                            <P class={"text-xl font-normal"}>Detalle de carpeta</P>
                        </div>
                        <div class={"space-y-6 px-4 py-3"}>
                            <InfoItem label={"Nombre de la carpeta"} value={folder().name} />
                            <InfoItem
                                label={"Creado el"}
                                value={defaultFormattedDate(folder().created_at, formatDate)}
                            />
                            <InfoItem
                                label={"Última modificación"}
                                value={defaultFormattedDate(folder().updated_at, formatDate)}
                            />
                            <InfoItem label={"Descripción"} value={folder().description} />
                        </div>
                    </div>
                );
            }}
        </Show>
    );
}

function NoActiveFolder() {
    return (
        <div class={"flex h-full flex-col items-center justify-center gap-1 text-center"}>
            <div
                class={"bg flex h-12 w-12 items-center justify-center rounded-full bg-primary-100"}
            >
                <i class="fas fa-search text-display-xs text-primary-600" />
            </div>
            <P class={"font-semibold text-light-gray-500"}>
                Selecciona un elemento para ver los detalles
            </P>
        </div>
    );
}

function InfoItem(props: { label: string; value: string | undefined }) {
    return (
        <div class={"flex flex-col"}>
            <P class={"text-light-gray-500"}>{props.label}</P>
            <P>{props.value}</P>
        </div>
    );
}
