//import { createRetrieveStep } from "../../../../api/services/checklist/queries";
import { For, Show } from "solid-js";
import { SectionStep } from "../../../../api/services/checklist/interface";
import { InputUnion } from "../../../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { H2, P } from "../../../../utils/typography";
//import { FormBuilderUI } from "../../../FormBuilder/FormBuilderUI";
import { Tab } from "../../../ui/Tabs";
import { FormBuilderUI } from "../../../FormBuilder/FormBuilderUI";
import { createQuery } from "@tanstack/solid-query";
import { createUpdateChecklistSectionStepMutation } from "../../../../api/services/checklist/mutations";

export function CreateChecklistTask(props: { sectionSteps: SectionStep[] }) {
    //const retrieveFormQuery = createRetrieveWorkflowFormQuery();

    //const retrieveStepQuery = createRetrieveStep( () => "71749aad-6731-4584-bd48-6fe36127dc99");

    return (
        <Tab title={"Tareas"}>
            <P>FormuBuilderUI</P>

            <For each={props.sectionSteps}>
                {step => (
                    <Show
                        when={step.fields.length > 0}
                        fallback={
                            <div>
                                <H2>No hay campos para {step.mainField.label}</H2>
                            </div>
                        }
                    >
                        <H2>Formulario de {step.mainField.label}</H2>
                        <StepForm step={step} />
                    </Show>
                )}
            </For>
        </Tab>
    );
}
export function StepForm(props: { step: SectionStep }) {
    const formQuery = createQuery(() => ({
        queryKey: ["stepform", props.step.id],
        queryFn: () => ({
            id: props.step.id,
            fields: props.step.fields,
        }),
    }));
    const updateStepMutation = createUpdateChecklistSectionStepMutation();
    //const mainField = ()=>props.step.mainField;
    const saveForm = async (fields: InputUnion[]) => {
        const payload: SectionStep = {
            ...props.step,
            fields,
        };
        console.log("saveForm", {
            FIELDS: props.step.fields,
            mainField: payload.mainField,
        });
        updateStepMutation.mutate(payload, {
            onSuccess() {
                formQuery.refetch();
            },
            onError() {
                alert("Error");
            },
        });
    };
    return <FormBuilderUI onSaveForm={saveForm} retrieveFormQuery={formQuery} />;
}
