import { Show, For } from "solid-js";
import { ProcessVars } from "../../../api/services/workflow/interface";
import { createExecutionProcessVarsQuery } from "../../../api/services/workflow/queries";
import { RecordTable, Entry } from "../../ui/RecordTable";
import Accordion, { AccordionItem } from "../../ui/Accordion";

export function ProcessVarsWrapper(props: { executionId: string }) {
    const processVarsQuery = createExecutionProcessVarsQuery(() => props.executionId);

    return <Show when={processVarsQuery.data}>{data => <ProcessVarsTable vars={data()} />}</Show>;
}
export function ProcessVarsTable(props: { vars: ProcessVars }) {
    return (
        <div class="mt-2 space-y-2">
            <Accordion>
                <AccordionItem summary="Datos Generados">
                    <RecordTable>
                        <For each={Object.entries(props.vars)}>
                            {([key, value]) => <Entry name={key} value={value} />}
                        </For>
                    </RecordTable>
                </AccordionItem>
            </Accordion>
        </div>
    );
}
