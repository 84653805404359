import { useNavigate, useParams } from "@solidjs/router";
import {
    createWorkflowElementListQuery,
    createWorkflowExecutionDetailQuery,
} from "../../api/services/workflow/queries";
import { For, Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ProcessVarsWrapper } from "./execution-components/ProcessVars";
import Accordion, { AccordionItem } from "../ui/Accordion";
import { ExecutionMetadata } from "./execution-components/ExecutionMetaData";
import { Button } from "../ui/components";
import { ExecutionElements } from "./execution-components/ExecutionElements";
import { createActivitiesByExecutionQuery } from "../../api/services/task-manager/queries";
import { P } from "../../utils/typography";
import { createWorkflowDetailQuery } from "../../api/services/workflow/workflows/queries";
import { WorkflowElement, WorkflowExecution } from "../../api/services/workflow/interface";
import { ActivityByExecution } from "../../api/services/task-manager/interface";
type WFElementWithActivity = {
    wfElement: WorkflowElement;
    activity: ActivityByExecution;
};

function mergeActivitiesWithElements(
    activities: ActivityByExecution[],
    elements: WorkflowElement[],
): WFElementWithActivity[] {
    const result: WFElementWithActivity[] = [];
    for (const element of elements) {
        const activity = activities.find(act => act.user_task === element.id);
        if (activity) {
            result.push({ wfElement: element, activity });
        }
    }
    return result;
}
export default function WorkflowExecutionDetailPage() {
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <WorkflowExecutionDetailPageContent />
        </Suspense>
    );
}
export function WorkflowExecutionDetailPageContent() {
    const params = useParams<{ id: string; workflowType: string }>();
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => params.id);
    const activitiesByExecutionQuery = createActivitiesByExecutionQuery(() => params.id);
    const workflowElementListQuery = createWorkflowElementListQuery(() => params.id);

    return (
        <PageWrapper>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={activitiesByExecutionQuery.data}>
                    {activities => (
                        <Show when={workflowElementListQuery.data}>
                            {elements => (
                                <PendingActivities
                                    activities={mergeActivitiesWithElements(
                                        activities(),
                                        elements(),
                                    )}
                                />
                            )}
                        </Show>
                    )}
                </Show>
                <Show when={executionDetailQuery.data}>
                    {execution => (
                        <>
                            <DetailPageHeader execution={execution()} />
                            <Accordion startOpen>
                                <AccordionItem summary="Información de seguimiento">
                                    <ExecutionElements
                                        executionId={params.id}
                                        workflowId={execution().workflowId}
                                    />
                                </AccordionItem>
                            </Accordion>
                            <ProcessVarsWrapper executionId={execution().id} />

                            {/* <SidePanel position="right" controller={panelController}>
                                {bpmnElement => (
                                    <ElementExecution
                                        element={bpmnElement}
                                        workflowId={execution().workflowId}
                                        executionId={params.id}
                                        onActionSuccess={handleActionSuccess}
                                    />
                                )}
                            </SidePanel> */}
                        </>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}
function PendingActivities(props: { activities: WFElementWithActivity[] }) {
    return (
        <For each={props.activities}>
            {({ wfElement, activity }) => (
                <PendingActivity activity={activity} wfElement={wfElement} />
            )}
        </For>
    );
}

function PendingActivity(props: { activity: ActivityByExecution; wfElement: WorkflowElement }) {
    const onClick = () => {
        console.log("Realizar actividad", props.activity);
    };
    return (
        <div class="flex flex justify-between gap-x-4 rounded-md border p-4">
            <div class="flex flex-col gap-y-2">
                <P class="font-medium">{props.wfElement.name}</P>
            </div>
            <Button onClick={onClick}>Realizar actividad</Button>
        </div>
    );
}

function DetailPageHeader(props: { execution: WorkflowExecution }) {
    const navigate = useNavigate();
    const params = useParams<{ id: string; workflowType: string }>();
    const workflowDetailQuery = createWorkflowDetailQuery(() => props.execution.workflowId);

    const navigateBack = () => {
        navigate(`/workflows/${params.workflowType}/executions`);
    };
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={workflowDetailQuery.data}>
                {data => (
                    <div class="mb-10 flex flex-col gap-y-2">
                        <div class={"flex items-center"}>
                            <Button onClick={navigateBack} bgStyle="text-only">
                                <i class={"fas fa-chevron-left"} />
                            </Button>
                            <P class={"text-display-sm font-medium text-light-gray-500"}>
                                {data().name}
                            </P>
                        </div>
                        <ExecutionMetadata executionId={props.execution.id} display="bubble" />
                    </div>
                )}
            </Show>
        </Suspense>
    );
}
