import { BorderedCard } from "../../ui/cards";
import { Badge } from "../../ui/components";
import { For, JSX, Show } from "solid-js";
import { P } from "../../../utils/typography";
import { CircleNumber } from "../../ui/CircleNumber";
import { createModalController, Modal } from "../../ui/Modal";
import { ResponseDispatcher } from "../../checklists/ChecklistExecutionDetailPage";
import { FormValues } from "../../forms/state";

type TaskCardProps<T> = {
    title: string;
    formValues?: FormValues | null;
    description: string;
    isReady: boolean;
    taskType: string;
    isOptional: boolean;
    images?: string[];
    data?: T;
    children?: (data: T, close: () => void) => JSX.Element;
    completedAt?: string | null;
};
export function TaskCard<T>(props: TaskCardProps<T>) {
    const modal = createModalController<T, void>();

    return (
        <BorderedCard onClick={() => props.data && modal.open(props.data)}>
            <div class={"flex gap-x-3"}>
                <div>
                    <div
                        class={
                            "flex min-h-8 min-w-8 items-center justify-center rounded-full bg-light-gray-100"
                        }
                        classList={{
                            "bg-success-100": !!props.completedAt,
                        }}
                    >
                        {props.isReady ? (
                            <i
                                class={`fas fa-check ${
                                    props.completedAt ? "text-success-600" : ""
                                }`}
                            />
                        ) : (
                            <i
                                class={`fas fa-camera ${
                                    props.completedAt ? "text-success-600" : ""
                                }`}
                            />
                        )}
                    </div>
                </div>
                <div class="flex-1">
                    <P
                        class={`!mb-0 text-default font-bold ${
                            props.completedAt ? "opacity-50" : ""
                        }`}
                    >
                        {props.title}
                    </P>
                    <P class={"!mb-0 text-sm"}>{props.description}</P>
                    <div class={"mt-1 flex justify-between"}>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            {props.taskType}
                        </Badge>
                        {props.isOptional && (
                            <Badge class={"w-fit border border-light-gray-600 bg-transparent"}>
                                Opcional
                            </Badge>
                        )}
                    </div>
                </div>
                {/* <Show when={props.data}>
                    <div class="flex flex-col items-end">
                        <TechnicalDetails>
                            <JsonDebug value={props.data as unknown} />
                        </TechnicalDetails>
                    </div>
                </Show> */}
            </div>
            <div>
                <Show when={props.completedAt}>
                    <P class={"mt-3 text-center font-medium text-success-600"}>
                        Finalizada el {props.completedAt || ""}
                    </P>

                    <Show when={props.formValues}>
                        {values => <TaskResponse formValues={values()} />}
                    </Show>
                </Show>
            </div>
            <Modal controller={modal}>
                {data => (
                    <>
                        <Modal.Header>{props.title}</Modal.Header>
                        {props.children?.(data, modal.dismiss)}
                    </>
                )}
            </Modal>
        </BorderedCard>
    );
}

function TaskResponse(props: { images?: string[]; formValues: FormValues }) {
    return (
        <div class={"rounded-xs bg-light-gray-200 p-2"}>
            <div class={"flex justify-between"}>
                <P>Respuesta</P>
                <CircleNumber number={1} />
            </div>
            <ResponseDispatcher response={props.formValues} />
            <div class={"grid grid-cols-12 gap-x-2"}>
                <For each={props.images}>
                    {image => {
                        return (
                            <img src={image} alt={"image"} class={"col-span-4 h-auto rounded-md"} />
                        );
                    }}
                </For>
            </div>
        </div>
    );
}
