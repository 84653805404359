import { useParams } from "@solidjs/router";
import { create, string, type } from "superstruct";
import { createWorkflowExecutionDetailQuery } from "../../api/services/workflow/queries";
import { Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import { createModalController } from "../ui/Modal";
import SidePanel from "../ui/SidePanel";
import Toastify from "toastify-js";
import { useLocale } from "../i18n/context";
import { BpmnElement } from "./bpmn";
import { GenericSuspenseFallback } from "../ui/skeletons";

import { ElementExecution } from "./execution-components/ElementExecution";
import { WorkflowDetailWrapper } from "./WorkflowDetailWrapper";

export default function WorkflowExecutionAdministrationDetailPage() {
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <WorkflowExecutionDetailPageContent />
        </Suspense>
    );
}
export function WorkflowExecutionDetailPageContent() {
    const params = create(useParams(), type({ id: string() }));
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => params.id);
    const panelController = createModalController<BpmnElement, void>();
    const [locale] = useLocale();

    const handleActionSuccess = () => {
        panelController.dismiss();
        Toastify({
            text: locale().workflows.actionSuccess,
            style: { backgroundColor: "green" },
        }).showToast();
    };

    return (
        <PageWrapper>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={executionDetailQuery.data}>
                    {execution => (
                        <>
                            <WorkflowDetailWrapper
                                panelController={panelController}
                                executionId={execution().id}
                                workflowId={execution().workflowId}
                            />

                            <SidePanel position="right" controller={panelController}>
                                {bpmnElement => (
                                    <ElementExecution
                                        element={bpmnElement}
                                        workflowId={execution().workflowId}
                                        executionId={params.id}
                                        onActionSuccess={handleActionSuccess}
                                    />
                                )}
                            </SidePanel>
                        </>
                    )}
                </Show>
            </Suspense>
        </PageWrapper>
    );
}
