import BackButton from "../../../utils/components/BackButton";
import { Badge } from "../../ui/components";
import { ActivityIcon, Countdown } from "../ActivityItemMobile";
import { ActivityItem, Priority } from "../../../api/services/task-manager/interface";
import { P } from "../../../utils/typography";
import { Show } from "solid-js";
import { useLocale } from "../../i18n/context";
export function DetailActivityHeader(props: {
    activity: ActivityItem;
    isExpired: boolean;
    remainingTime: Temporal.Duration;
}) {
    const [locale] = useLocale();
    // const { nowZdt } = useClock();
    // const remainingTime = createMemo(() => getHmsDurationBetween(nowZdt(), props.activity.dueDate));
    // const isExpired = createMemo(() => remainingTime().sign === -1);
    // const allTasksCompleted = createMemo(() => recurrenceTasksQuery.data
    //     ?.filter(t => t.completed_at!== null).length === activityTasksQuery.data?.length
    // )

    return (
        <div class={"sticky top-0 z-dropdown bg-white pb-6"}>
            {/*<AlertMessage
                title={"Actividad finalizada con éxito"}
                type={"success"}
                class={"mb-3"}
            />
            <AlertMessage
                title={"Actividad expirada"}
                subtitle={
                    "Su fecha se cumplió y el tiempo extra expiró. No puede ser completada"
                }
                type={"error"}
                class={"mb-3"}
            />
            <AlertMessage
                title={"Actividad programada"}
                subtitle={"Puede ser realizada a partir del {start_date}"}
                type={"program_activity"}
                class={"mb-3"}
            />*/}

            <BackButton url={"/activities/"} />

            <div class={"flex justify-between"}>
                <Badge
                    class={`${
                        badgeClasses[props.activity.priority]
                    } flex items-center justify-center gap-x-1 !pr-3`}
                >
                    <ActivityIcon priority={props.activity.priority} />
                    <P class={"!mb-0 text-sm"}>
                        {locale().activities.priorities[props.activity.priority]}
                    </P>
                </Badge>
                <div>
                    <Show when={props.isExpired}>
                        <Badge
                            class={"border border-error-100 bg-error-50 font-medium text-error-700"}
                        >
                            Expirada
                        </Badge>
                    </Show>
                    <Countdown
                        remainingTime={props.remainingTime}
                        priority={props.activity.priority}
                    />
                </div>
            </div>
            <P class={"!mb-2 text-xl font-medium"}>{props.activity.title}</P>

            <Show when={props.activity.venue} fallback={<>&ndash;</>}>
                {venue => (
                    <div class={"flex gap-x-1"}>
                        <P class={"!mb-0"}>Recinto:</P>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            {venue().name}
                        </Badge>
                    </div>
                )}
            </Show>
        </div>
    );
}

const badgeClasses: Partial<Record<Priority, string>> = {
    [Priority.Reminder]: "border border-primary-400 bg-primary-100 font-medium text-primary-700",
    [Priority.ThreeUpArrows]: "border border-error-100 bg-error-50 font-medium text-error-700",
    [Priority.TwoUpArrows]: "border border-warning-200 bg-warning-50 font-medium text-warning-700",
};
