import { OnClickDiv } from "../../../utils/solidjs";
import { Show } from "solid-js";
import { Folder } from "./GenericFileExplorer";
import FolderItemActionButton from "./FolderItemActionButton";

export default function FolderItem<T extends Folder>(props: {
    folder: T;
    onClick: OnClickDiv;
    onDblClick: OnClickDiv;
    isActive: boolean;
    deleteFolder: ((folder: T) => void) | undefined;
    editFolder?: ((folder: T) => void) | undefined;
    setActiveFolderId: (folderId: string | undefined) => void;
}) {
    return (
        <div
            onClick={e => props.onClick(e)}
            onDblClick={e => props.onDblClick(e)}
            class={`group relative flex cursor-pointer flex-col items-center rounded-md px-4 py-2 ${
                props.isActive ? "bg-primary-50" : "hover:bg-light-gray-50"
            }`}
        >
            <FolderItemActionButton
                isActive={props.isActive}
                folder={props.folder}
                deleteFolder={props.deleteFolder}
                editFolder={props.editFolder}
                setActiveFolderId={props.setActiveFolderId}
                class={"absolute right-0 top-0 hidden group-hover:block"}
            />
            <FolderItemIcon folder={props.folder} />
            <div
                // Show ellipsis after two lines of text
                class="w-full overflow-hidden break-words text-center font-normal leading-tight text-light-gray-700"
                style={{
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                }}
                title={props.folder.name}
            >
                {props.folder.name}
            </div>
        </div>
    );
}

export function FolderItemIcon(props: { folder: Folder }) {
    const isFAIcon = () => props.folder.icon?.startsWith("fa");
    return (
        <div class="relative">
            <i
                class={`fas fa-folder relative block text-display-lg`}
                style={{ color: props.folder.color || "#52AEFF" }}
            >
                <Show when={props.folder.icon && isFAIcon()}>
                    <i
                        class={`${props.folder.icon} absolute bottom-[14px] left-4 text-default text-white`}
                    />
                </Show>
            </i>
            <Show when={props.folder.starred}>
                <div
                    class={
                        "absolute -right-1 bottom-1 flex h-4 w-4 items-center justify-center rounded-full bg-light-gray-50"
                    }
                >
                    <i class="fas fa-star text-[10px] text-warning-300" />
                </div>
            </Show>
        </div>
    );
}
