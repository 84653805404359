import { FieldProps } from "./parts/types";
import { LabelAbove } from "./parts/layouts";
import { createSignal, createUniqueId, For } from "solid-js";
import _ from "lodash";
import { createField } from "../state";
import { parsedEnv } from "../../../utils/parsedEnv";

export default function StarsField(
    props: FieldProps<number, void> & {
        max: number;
        labels: string[];
    },
) {
    const field = createField(props, 0);
    const inputId = createUniqueId();
    const [hoveredStars, setHoveredStars] = createSignal(0);
    const highlightedStars = () => hoveredStars() || field.value;
    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <div class="flex flex-col">
                <div class="flex">
                    <For each={_.range(props.max)}>
                        {index => (
                            <i
                                role="button"
                                onClick={() => field.setValue(index + 1)}
                                onMouseEnter={() => setHoveredStars(index + 1)}
                                onMouseLeave={() => setHoveredStars(0)}
                                class={`${
                                    index < highlightedStars()
                                        ? `fas fa-star ${
                                              hoveredStars()
                                                  ? "text-warning-300"
                                                  : "text-warning-400"
                                          }`
                                        : "far fa-star"
                                } px-1.5 py-1.5 text-display-xs`}
                            />
                        )}
                    </For>
                </div>
                <div class="ps-1.5 text-sm">
                    {props.labels[highlightedStars()] ??
                        (parsedEnv.MODE === "development" && highlightedStars() + " estrellas")}
                </div>
            </div>
        </LabelAbove>
    );
}
