import { JSX, Show } from "solid-js";
import { P } from "../../../utils/typography";
import { SearchBar } from "../SearchBar";
import { Button } from "../components";
import { Folder } from "./GenericFileExplorer";
import {
    createFileExplorerQuery,
    fileExplorerStorage,
} from "../../../api/services/checklist/queries";
import { FileExplorerMode } from "../../../api/services/checklist/implementations/v3";
import { parsedEnv } from "../../../utils/parsedEnv";

export default function FileExplorerHeader<T extends Folder>(props: {
    currentFolder: T | undefined;
    entityName: string;
    entityDescription?: string;
    createCategoryButton: JSX.Element;
    createEntityButton?: JSX.Element;
    headerChildren?: JSX.Element;
}) {
    const fileExplorerQuery = createFileExplorerQuery();
    const mutation = fileExplorerStorage.useMutation();

    const showCreateCategoryButton = () => {
        const featureFlag = parsedEnv.VITE_FEATURE_CREATE_CATEGORY_AT_ROOT_BUTTON;
        const isRootFolder = !props.currentFolder;

        //hide if feature flag is false and is in root folder
        return isRootFolder ? featureFlag : true;
    };
    return (
        <>
            <div class={"flex items-center justify-between"}>
                <div>
                    <P class={"text-display-sm font-semibold"}>{props.entityName}</P>
                    <P>{props.entityDescription}</P>
                </div>
                <div class={"rounded-2 bg-light-gray-100 px-6 py-3 text-light-gray-500"}>
                    <P>1 click: Seleccionar categoría.</P>
                    <P>2 click: Entrar a categoría.</P>
                </div>
            </div>
            <div class={"flex justify-between"}>
                <SearchBar variant={"white"} />
                <div class={"flex gap-x-4"}>
                    {props.createCategoryButton}
                    <Show when={showCreateCategoryButton()}>{props.createEntityButton}</Show>
                    {props.headerChildren}
                    <div class={"flex gap-1"}>
                        <Button
                            bgStyle={"outline"}
                            classList={{
                                "!border-primary-300 text-primary-700":
                                    fileExplorerQuery.data?.mode === FileExplorerMode.LIST,
                                "!border-light-gray-300 !text-light-gray-300":
                                    fileExplorerQuery.data?.mode === FileExplorerMode.FOLDER,
                            }}
                            onClick={() => {
                                mutation.mutate({
                                    mode: FileExplorerMode.LIST,
                                    sidePanel: fileExplorerQuery.data?.sidePanel ?? false,
                                });
                            }}
                        >
                            <i class="fas fa-th-list" />
                        </Button>
                        <Button
                            bgStyle={"outline"}
                            onClick={() => {
                                mutation.mutate({
                                    mode: FileExplorerMode.FOLDER,
                                    sidePanel: fileExplorerQuery.data?.sidePanel ?? false,
                                });
                            }}
                            classList={{
                                "!border-primary-300 text-primary-700":
                                    fileExplorerQuery.data?.mode === FileExplorerMode.FOLDER,
                                "!border-light-gray-300 !text-light-gray-300":
                                    fileExplorerQuery.data?.mode === FileExplorerMode.LIST,
                            }}
                        >
                            <i class="fas fa-folder" />
                        </Button>
                        <Button
                            bgStyle={"outline"}
                            class={"!rounded-full !px-3"}
                            classList={{
                                "!border-transparent": !fileExplorerQuery.data?.sidePanel,
                                "!border-primary-100 bg-primary-50":
                                    fileExplorerQuery.data?.sidePanel,
                            }}
                            onClick={() => {
                                mutation.mutate({
                                    mode: fileExplorerQuery.data?.mode ?? FileExplorerMode.FOLDER,
                                    sidePanel: !fileExplorerQuery.data?.sidePanel,
                                });
                            }}
                        >
                            <i class="fas fa-info-circle text-lg text-light-gray-500" />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
