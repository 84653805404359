import Toastify from "toastify-js";
import { Concept, CreateConceptParams } from "../../../api/services/checklist/interface";
import {
    createChecklistConceptCreateMutation,
    createChecklistConceptUpdateMutation,
} from "../../../api/services/checklist/mutations";
import IntegerField from "../../forms/fields/IntegerField";
import TextField from "../../forms/fields/TextField";
import { FormWrapper } from "../../forms/FormWrapper";
import { Button } from "../../ui/components";
import { Show, Suspense } from "solid-js";
import { createModalController, Modal } from "../../ui/Modal";
import ConceptStepForm from "./ConceptStepForm";
import { useParams } from "@solidjs/router";

type CreateConceptFormValues = Omit<CreateConceptParams, "evaluation" | "parent">;
export default function CreateUpdateConceptForm(props: {
    evaluationId: string;
    concept?: Concept;
    disabled: boolean;
    parentId?: string;
    class?: string;
    parentInputClass?: string;
}) {
    const subConceptModal = createModalController<void, void>();
    const stepModal = createModalController<void, void>();
    const createConceptMutation = createChecklistConceptCreateMutation();
    const updateConceptMutation = createChecklistConceptUpdateMutation();
    const params = useParams<{ id: string }>();
    const create = (values: CreateConceptFormValues) => {
        createConceptMutation.mutate(
            {
                ...values,
                evaluation: props.evaluationId,
                parent: props.parentId ?? null,
            },
            {
                onSuccess: () => {
                    Toastify({
                        text: "Concepto creado",
                    }).showToast();
                },
                onError: () => {
                    Toastify({
                        text: "Error al crear el concepto",
                        backgroundColor: "red",
                    }).showToast();
                },
            },
        );
    };

    const edit = async (values: CreateConceptFormValues) => {
        if (props.concept) {
            try {
                await updateConceptMutation.mutateAsync({
                    ...values,
                    evaluation: props.evaluationId,
                    id: props.concept?.id,
                    parent: props.concept.parent,
                });
                Toastify({
                    text: "Concepto actualizado",
                }).showToast();
            } catch (e) {
                Toastify({
                    text: "Error al actualizar el concepto",
                    backgroundColor: "red",
                }).showToast();
            }
        }
    };

    const onSubmit = (values: CreateConceptFormValues) => {
        if (props.concept && !props.parentId) {
            edit(values);
        } else {
            create(values);
        }
    };

    const onClickAddSubconcept = () => {
        subConceptModal.open();
    };

    const onClickAddStep = () => {
        stepModal.open();
    };

    return (
        <>
            <FormWrapper onSubmit={onSubmit} class={props.class}>
                <div class={props.parentInputClass}>
                    <TextField
                        label={"Nombre del concepto"}
                        name="name"
                        defaultValue={props.concept?.name}
                        disabled={props.disabled}
                    />
                    <IntegerField
                        label={"Ponderación"}
                        name="weight"
                        defaultValue={
                            props.concept?.weight ? String(props.concept?.weight) : undefined
                        }
                        disabled={props.disabled}
                    />
                </div>
                <Show when={!props.disabled}>
                    <div class="my-4 flex justify-end space-x-2">
                        <Button
                            type={"submit"}
                            disabled={
                                createConceptMutation.isPending || updateConceptMutation.isPending
                            }
                        >
                            {props.concept && !props.parentId ? "Actualizar" : "Crear"}
                        </Button>
                        <Show when={props.concept && !props.parentId}>
                            <div class="flex space-x-2">
                                <Button type={"button"} bgStyle="outline" onClick={onClickAddStep}>
                                    Agregar Paso
                                </Button>
                                <Button type={"button"} onClick={onClickAddSubconcept}>
                                    Agregar subconcepto
                                </Button>
                            </div>
                        </Show>
                    </div>
                </Show>
            </FormWrapper>

            <Show when={props.concept}>
                {concept => (
                    <Modal controller={subConceptModal}>
                        <Modal.Header>
                            <h2 class="text-xl font-bold">
                                Agregar subconcepto a {concept().name}
                            </h2>
                        </Modal.Header>
                        <CreateUpdateConceptForm
                            evaluationId={props.evaluationId}
                            disabled={props.disabled}
                            parentId={concept().id}
                        />
                    </Modal>
                )}
            </Show>

            <Show when={props.concept}>
                {concept => (
                    <Suspense>
                        <Modal controller={stepModal}>
                            <ConceptStepForm concept={concept()} checklistId={params.id} />
                        </Modal>
                    </Suspense>
                )}
            </Show>
        </>
    );
}
