import { FieldProps } from "./parts/types";
import { InputChoice } from "choices.js";
import ChoiceField from "./choiceField/ChoiceField";

export default function IconField(props: FieldProps<string, HTMLSelectElement>) {
    return (
        <ChoiceField
            {...props}
            renderChoice={html}
            fetchChoices={async () => {
                const icons = (await import("./icons_es.json")).default;
                return Object.entries(icons).map(([k, v]) => ({
                    value: k,
                    label: v as string,
                }));
            }}
        />
    );
}

const html = (icon: InputChoice) => `
<div class="flex items-center">
    <div class="flex w-4 mr-3 justify-center items-center">
        <i class="${icon.value} text-lg"></i>
    </div>
    <span class="text-sm font-normal pt-0.5">${icon.label}</span>
</div>`;
