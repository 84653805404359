import {
    Accessor,
    createContext,
    createEffect,
    createSignal,
    For,
    Match,
    mergeProps,
    ParentProps,
    Show,
    Switch,
} from "solid-js";
import { LavandaCard } from "../ui/cards";
import { Button, Icon } from "../ui/components";
import {
    EditableForm,
    FormPayload,
    mockDynamicFormPayload,
    RenderDynamicForm,
} from "./RenderDynamicForm";
import { FormBuilder } from "./FormBuilder";
import { InputTypeEnum } from "../../api/services/formbuilder/interfaces/InputTypeEnum";
import { makeBlankField } from "./makeBlankField";
import {
    InputUnion,
    MultipleChoiceInput,
    TextInput,
} from "../../api/services/formbuilder/interfaces/inputs/BaseInput";
import { H1, Hr, P } from "../../utils/typography";
import { FormWrapper } from "../forms/FormWrapper";
import TextField from "../forms/fields/TextField";
import { createStore, Store } from "solid-js/store";
import { useRequiredContext } from "../../utils/solidjs";
import Accordion, { AccordionItem } from "../ui/Accordion";
import { useLocale } from "../i18n/context";
import { FormValues, isBlank } from "../forms/state";
import { sleep } from "../../utils/mocks";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { createTabsController, Tab, Tabs } from "../ui/Tabs";
import CheckboxField from "../forms/fields/CheckboxField";
import { showErrorToast, showSuccessToast } from "../../utils/errorHandling";
import ConditionField from "../forms/fields/ConditionField";
import { DynamicForm } from "../../api/services/formbuilder/DynamicForm";
import { CreateQueryResult } from "@tanstack/solid-query";
import { nonCheckboxInputStyling } from "../forms/presentation";
import DescriptionField from "../forms/fields/DescriptionField";

/** Allows user to edit forms. Works with any {@link DynamicForm}. */
export function FormBuilderUI(props: FormBuilderProps) {
    const { lg } = useResponsiveBreakpoints();

    return (
        <FormBuilderContextProvider {...props}>
            <Show when={lg()} fallback={<FormBuilderMobile />}>
                <FormBuilderDesktop />
            </Show>
        </FormBuilderContextProvider>
    );
}

function FormBuilderMobile() {
    const tabs = createTabsController();
    const formBuilder = useFormBuilder();

    return (
        <Tabs staticController={tabs}>
            <Tab title="Form Builder">
                <EditableForm onSelectField={() => tabs.setActiveTab(1)} readonly />
                <FormToolbox />
            </Tab>
            <Tab title="Field Settings">
                <FieldSettings />
                <FormJsonDebugger />
            </Tab>
            <Tab title="Form Preview">
                <FormPreview fields={formBuilder.payload.fields} />
            </Tab>
        </Tabs>
    );
}

function FormBuilderDesktop() {
    return (
        <div class="bg-gray-200 grid h-screen grid-cols-12 gap-4 space-x-4">
            <div class="col-span-3">
                <FormToolbox />
            </div>
            <div class="col-span-4">
                <FormBuilderHeader />
                <EditableForm readonly />
            </div>
            <div class="col-span-5">
                <FieldSettings />
                {/*<FormJsonDebugger />*/}
            </div>
            {/* <div class="col-span-3">
                <FormPreview />
            </div> */}
        </div>
    );
}

type FormBuilderContextValue = FormBuilderProps & {
    addBlankField: (field: InputTypeEnum) => void;
    payload: Store<FormPayload>;
    setPayload: (payload: FormPayload) => void;
    removeField: (id: string) => void;
    selectedField: Accessor<string | null>;
    getSelectedField: Accessor<InputUnion | null>;
    setSelectedField: (field: string | null) => void;
    setField: (id: string, field: Partial<InputUnion>) => void;
    addOption: (fieldId: string, option: string) => void;
};
export const FormBuilderContext = createContext<FormBuilderContextValue>();

export const useFormBuilder = () =>
    useRequiredContext(FormBuilderContext, "useFormBuilder", "FormBuilderContext");

export type FormBuilderProps = {
    /** GETs the form details from the backend if the form already exists.
     * This query should be disabled otherwise, i.e. use `{ enabled: !!formId() }`. */
    retrieveFormQuery: CreateQueryResult<DynamicForm>;
    /** Create or update the form here, depending on whether it already exists. */
    onSaveForm: (fields: InputUnion[]) => Promise<void>;
};

const FormBuilderContextProvider = (props: ParentProps<FormBuilderProps>) => {
    const [selectedField, setSelectedField] = createSignal<string | null>(null);
    const [payload, setPayload] = createStore(mockDynamicFormPayload);
    const [firstRetrieve, setFirstRetrieve] = createSignal(true);
    createEffect(() => {
        if (props.retrieveFormQuery.data && firstRetrieve()) {
            setPayload(props.retrieveFormQuery.data);
            setFirstRetrieve(false);
        }
    });
    const addBlankField = (input: InputTypeEnum) => {
        const formBuilder = new FormBuilder(payload, setPayload);
        const field = makeBlankField(input, String(payload.fields.length + 1));
        const formPayload = formBuilder.addField(field).build();
        setPayload(formPayload);
    };

    const removeField = (id: string) => {
        const formBuilder = new FormBuilder(payload, setPayload).removeField(id);
        setPayload(formBuilder.build());
    };

    const setField = (id: string, field: Partial<InputUnion>) => {
        const formBuilder = new FormBuilder(payload, setPayload).setFieldData(id, field);
        setPayload(formBuilder.build());
    };

    const addOption = (fieldId: string, option: string) => {
        const formBuilder = new FormBuilder(payload, setPayload);
        const field = formBuilder.build().fields.find(f => f.id === fieldId);
        if (field?.type === InputTypeEnum.multiple_choice) {
            formBuilder.addOptionToField(fieldId, option);
            setPayload(formBuilder.build());
        }
    };
    const getSelectedField = () => {
        if (selectedField()) {
            return payload.fields.find(f => f.id == selectedField()) || null;
        }
        return null;
    };

    const contextValue: FormBuilderContextValue = mergeProps(props, {
        addBlankField,
        payload,
        setPayload,
        removeField,
        selectedField,
        setSelectedField,
        setField,
        getSelectedField,
        addOption,
    });

    return (
        <FormBuilderContext.Provider value={contextValue}>
            {props.children}
        </FormBuilderContext.Provider>
    );
};

const inputsDict: Partial<Record<InputTypeEnum, { name: string; icon: string }>> = {
    [InputTypeEnum.integer]: {
        name: "Integer Field",
        icon: "fas fa-hashtag",
    },
    [InputTypeEnum.float]: {
        name: "Float Field",
        icon: "fas fa-hashtag",
    },
    [InputTypeEnum.decimal]: {
        name: "Decimal Field",
        icon: "fas fa-hashtag",
    },
    [InputTypeEnum.boolean]: {
        name: "Boolean Field",
        icon: "fas fa-hashtag",
    },
    [InputTypeEnum.text]: {
        name: "Text Field",
        icon: "fas fa-font",
    },
    [InputTypeEnum.date]: {
        name: "Date Field",
        icon: "far fa-calendar",
    },
    [InputTypeEnum.datetime]: {
        name: "Date Time Field",
        icon: "far fa-calendar",
    },
    [InputTypeEnum.multiple_choice]: {
        name: "Select Field",
        icon: "fas fa-list",
    },
    [InputTypeEnum.files]: {
        name: "File Field",
        icon: "fas fa-file",
    },
    [InputTypeEnum.venue]: {
        name: "Venue Field",
        icon: "fas fa-store",
    },
};

function FormToolbox() {
    return (
        <div class="bg-gray-800">
            <div class="">
                <h1 class="text-lg font-semibold">Components</h1>
                {/*<p class="text-gray-300 mt-2 text-sm">Elige los componentes para el formulario</p>
                <SearchBar variant="white" placeholder="Buscar componentes" />*/}
            </div>
            <div class="mt-3 grid grid-cols-2 gap-2">
                <For each={Object.entries(inputsDict)}>
                    {([key, value]) => (
                        <InputTypeCard
                            icon={value.icon}
                            name={value.name}
                            inputType={key as InputTypeEnum}
                        />
                    )}
                </For>
            </div>
        </div>
    );
}

type InputTypeCard = {
    icon: string;
    name: string;
    inputType: InputTypeEnum;
};
function InputTypeCard(props: InputTypeCard) {
    const formBuilder = useFormBuilder();
    return (
        <LavandaCard
            class={"col-span-1 flex flex-col items-center gap-y-2 !p-3 text-center"}
            onClick={() => formBuilder.addBlankField(props.inputType)}
        >
            <Icon name={props.icon} />
            <P class={"!mb-0 text-sm"}>{props.name}</P>
        </LavandaCard>
    );
}

type FormSettingFormvalues = {
    label: string;
    name: string;
    placeholder: string;
    required: boolean;
    skippable: boolean;
    show_if: string;
    description: string;
};
function FieldSettings() {
    const formBuilder = useFormBuilder();

    const onSubmit = (data: FormSettingFormvalues) => {
        const selectedField = formBuilder.selectedField();
        const selectedFieldObject = formBuilder.getSelectedField();
        if (!selectedField || !selectedFieldObject) return;
        formBuilder.setField(String(selectedField), {
            ...selectedFieldObject,
            label: data.label,
            placeholder: data.placeholder,
            required: data.required,
            skippable: data.skippable,
            name: data.name,
            show_if: isBlank(data.show_if) ? null : data.show_if,
            description: isBlank(data.description) ? null : data.description,
        });
    };

    //test
    // const selectedField = createMemo(() =>
    //     formBuilder.payload.fields.find(f => f.id === formBuilder.selectedField()),
    // );

    return (
        <Show when={formBuilder.getSelectedField()}>
            {field => (
                <Show keyed when={field().id}>
                    <FormWrapper onSubmit={onSubmit} optionalityIndicator="asterisk">
                        <div class="bg-gray-800 max-w-96">
                            <div class="px-4">
                                <h1 class="text-lg font-semibold">Field Settings</h1>
                                <EditField field={field()} />
                            </div>
                            <Button type={"submit"} class={"mt-3 h-12 w-full"}>
                                <Icon name="fas fa-save" />
                                Guardar campo
                            </Button>
                        </div>
                    </FormWrapper>
                </Show>
            )}
        </Show>
    );
}

function FormJsonDebugger() {
    const formBuilder = useFormBuilder();
    const [locale] = useLocale();

    return (
        <Accordion>
            <AccordionItem
                summary={locale().utils.showTechnicalDetails}
                summaryOpen={locale().utils.hideTechnicalDetails}
            >
                <pre>{JSON.stringify(formBuilder.payload, null, 4)}</pre>
            </AccordionItem>
        </Accordion>
    );
}

function FormPreview(props: { fields: InputUnion[] }) {
    async function handleSubmit(formValues: FormValues): Promise<void> {
        await sleep(1000);
        alert(JSON.stringify(formValues, null, 4));
    }

    return <RenderDynamicForm readonly fields={props.fields} onSubmit={handleSubmit} />;
}

function FormBuilderHeader() {
    const { onSaveForm, payload } = useFormBuilder();

    const onClickSave = async () => {
        try {
            await onSaveForm(payload.fields);
            showSuccessToast("Formulario guardado");
        } catch (error) {
            showErrorToast(`Error al guardar el formulario: ${error}`);
        }
    };

    return (
        <div class="mb-3 flex w-full">
            <div class={"w-full"}>
                <div class={"flex w-full items-center justify-between gap-x-2"}>
                    <H1 class={"!mb-0 !text-xl"}>Form Builder</H1>
                    <Button
                        variant="primary"
                        bgStyle="outline"
                        onClick={onClickSave}
                        pendingText="Guardando..."
                    >
                        <Icon name="fas fa-save" />
                        Guardar
                    </Button>
                </div>
                <p class="text-gray-500 mt-2">Crea formularios para tu aplicación</p>
            </div>
        </div>
    );
}

function EditField(props: { field: InputUnion }) {
    return (
        <Show when={props.field.id} keyed>
            <h1 class="!mb-0 text-lg font-semibold">{props.field.label}</h1>
            <p class="text-gray-300 mb-3 mt-2 text-sm">Configura los campos del componente</p>

            <div class={"flex flex-col gap-y-3 bg-white"}>
                <Switch fallback={<EditBaseFields field={props.field} />}>
                    <Match when={props.field.type === InputTypeEnum.text}>
                        <EditBaseFields field={props.field} />
                        <EditTextFields field={props.field as TextInput} />
                    </Match>
                    <Match when={props.field.type === InputTypeEnum.multiple_choice}>
                        <EditBaseFields field={props.field} />
                        <EditSelectFields field={props.field as MultipleChoiceInput} />
                    </Match>
                </Switch>
            </div>
        </Show>
    );
}

function EditTextFields(props: { field: TextInput }) {
    return (
        <div class="flex gap-3">
            <TextField
                name={`min_length_${props.field.id}`}
                placeholder="Min"
                label={"Largo mínimo"}
                optional
            />
            <TextField
                name={`max_length_${props.field.id}`}
                placeholder="Max"
                label={"Largo máximo"}
                optional
            />
        </div>
    );
}

function EditSelectFields(props: { field: MultipleChoiceInput }) {
    const formBuilder = useFormBuilder();
    const [option, setOption] = createSignal<string>("");
    const onClickAddOption = () => {
        formBuilder.addOption(props.field.id, option());
    };
    return (
        <div>
            <div>
                <P class={"!mb-0 font-semibold"}>Agregar opción</P>
                <div class="flex">
                    <input // Use input instead of TextField to avoid having a label with "(optional)"
                        name="option"
                        placeholder="Opción"
                        onChange={e => setOption(e.currentTarget.value)}
                        {...nonCheckboxInputStyling}
                    />
                    <Button onClick={onClickAddOption} bgStyle={"text-only"}>
                        <Icon name="fas fa-plus" />
                    </Button>
                </div>
                <For each={props.field.choices}>
                    {option => (
                        <ul class={"mt-1 list-disc pl-3"}>
                            <li>{option.choice}</li>
                        </ul>
                    )}
                </For>
            </div>
        </div>
    );
}

function EditBaseFields(props: { field: InputUnion }) {
    return (
        <>
            <TextField name={"label"} label={"Etiqueta"} defaultValue={props.field.label} />
            <TextField
                name={"name"}
                label="Variable de proceso"
                placeholder="field_name"
                defaultValue={props.field.name}
                inputClass="font-mono hide-lastpass"
            />
            <div class="flex gap-3">
                <CheckboxField
                    name={"required"}
                    placeholder="Requerido"
                    label={"Requerido"}
                    defaultValue={props.field.required}
                />
                <CheckboxField
                    name="skippable"
                    placeholder="Opcional"
                    label="Saltable"
                    defaultValue={props.field.skippable}
                />
            </div>
            <Hr>Avanzado</Hr>
            <DescriptionField
                name={"description"}
                label={"Descripción"}
                defaultValue={props.field.description || ""}
                optional
            />
            <TextField
                name={"placeholder"}
                label={"Placeholder"}
                defaultValue={props.field.placeholder}
                optional
            />
            <ConditionField
                name="show_if"
                label="Mostrar si"
                defaultValue={props.field.show_if ?? undefined}
                optional
            />
        </>
    );
}
