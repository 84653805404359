import { Show, For } from "solid-js";
import { createWorkflowElementListQuery } from "../../../api/services/workflow/queries";
import { BpmnViewer, BpmnOverlay, BpmnElement } from "../bpmn";

export function BpmnExecutionViewer(props: {
    bpmn: string | null;
    executionId: string;
    onClickElement?: (element: BpmnElement) => void;
}) {
    const workflowElementListQuery = createWorkflowElementListQuery(() => props.executionId);

    return (
        <BpmnViewer bpmn={props.bpmn} onClickElement={props.onClickElement}>
            <Show when={workflowElementListQuery.data}>
                {workflowElementList => (
                    <For
                        each={workflowElementList().filter(
                            workflowElement => workflowElement.isActive,
                        )}
                    >
                        {workflowElement => (
                            <BpmnOverlay bpmnElementId={workflowElement.bpmnElementId}>
                                {bpmnElement => (
                                    <div
                                        class="isolate h-full w-full bg-warning-200/50"
                                        onClick={() => props.onClickElement?.(bpmnElement)}
                                    />
                                )}
                            </BpmnOverlay>
                        )}
                    </For>
                )}
            </Show>
        </BpmnViewer>
    );
}
