import { Folder } from "./GenericFileExplorer";
import Dropdown from "../Dropdown";
import { Button } from "../components";
import { Show } from "solid-js";
import { createMyRolesQuery } from "../../../api/services/auth/queries";
import { Role } from "../../../api/services/auth/interface";

export default function FolderItemActionButton<T extends Folder>(props: {
    isActive: boolean;
    folder: T;
    deleteFolder: ((folder: T) => void) | undefined;
    editFolder?: ((folder: T) => void) | undefined;
    setActiveFolderId: (folderId: string | undefined) => void;
    class?: string;
}) {
    const rolesQuery = createMyRolesQuery();
    async function onClickDeleteFolder(folder: T) {
        await props.deleteFolder?.(folder);
        props.setActiveFolderId(undefined);
    }

    async function onClickEditFolder() {
        await props.editFolder?.(props.folder);
        props.setActiveFolderId(undefined);
    }

    return (
        <Show
            when={(props.deleteFolder || props.editFolder) && rolesQuery.data?.includes(Role.ADMIN)}
        >
            <div
                class={props.class}
                classList={{
                    "!block": props.isActive,
                }}
            >
                <Dropdown
                    trigger={onClick => (
                        <Button bgStyle={"text-only"} onClick={onClick}>
                            <i class="fas fa-ellipsis-v" />
                        </Button>
                    )}
                    padding={"none"}
                >
                    <div
                        class={
                            "w-40 rounded-md border-light-gray-200 bg-white py-2 shadow-light-gray-200 drop-shadow"
                        }
                    >
                        <Show when={props.editFolder}>
                            <Button
                                bgStyle={"text-only"}
                                onClick={onClickEditFolder}
                                class={"w-full !justify-start p-0 !font-normal !text-primary-700"}
                            >
                                Editar
                            </Button>
                        </Show>
                        <Show when={props.deleteFolder}>
                            <Button
                                bgStyle={"text-only"}
                                class={"w-full !justify-start p-0 !font-normal !text-error-600"}
                                onClick={() => onClickDeleteFolder(props.folder)}
                            >
                                Eliminar
                            </Button>
                        </Show>
                    </div>
                </Dropdown>
            </div>
        </Show>
    );
}
