import { Button } from "../components";
import { Show } from "solid-js";
import { Folder } from "./GenericFileExplorer";

export default function CreateCategoryButton<T extends Folder>(props: {
    createFolder: ((name: string, parent: T["id"] | null) => Promise<T>) | undefined;
    currentFolder: T | undefined;
    setActiveFolderId: (folderId: string | undefined) => void;
}) {
    const onClickNewFolder = async () => {
        const newFolder = await props.createFolder?.(
            `Categoría ${Temporal.Now.plainDateTimeISO()
                .toString()
                .split(".")[0]
                .replace("T", " ")}`,
            props.currentFolder?.id ?? null,
        );
        props.setActiveFolderId(newFolder?.id);
    };
    return (
        <Show when={props.createFolder}>
            <Button bgStyle={"outline"} onClick={onClickNewFolder} icon={"fas fa-plus"}>
                Crear categoría
            </Button>
        </Show>
    );
}
