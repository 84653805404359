import { array, Infer, string, type } from "superstruct";
import { OperationEnum, sOperationEnum } from "./OperationEnum";
export type AudienceExpression = {
    id: string;
    operation: OperationEnum;
    groups: string[];
    persons: string[];
    sub_expressions: string[];
    tenant: string;
};
export function sAudienceExpression() {
    return type({
        id: string(),
        operation: sOperationEnum(),
        groups: array(string()),
        persons: array(string()),
        sub_expressions: array(string()),
        tenant: string(),
    });
}

export function sCreateUpdateAudienceExpressionBody() {
    return type({
        operation: sOperationEnum(),
        groups: array(string()),
        persons: array(string()),
        sub_expressions: array(string()),
    });
}

export type CreateUpdateAudienceExpressionBody = Infer<
    ReturnType<typeof sCreateUpdateAudienceExpressionBody>
>;
