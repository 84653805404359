import { Accessor, createContext, createEffect, createSignal, ParentProps, Setter } from "solid-js";

type ContextProps = {
    executionId: Accessor<string>;
    currentStepId: Accessor<string | null>;
    setCurrentStepId: Setter<string | null>;
    checklistId: Accessor<string>;
    defaultStepId: Accessor<string | null>;
};
type ProviderProps = {
    executionId: string;
    defaultStepId: string | null;
    checklistId: string;
};
export const ChecklistExecutionContext = createContext<ContextProps>(undefined);

export const ChecklistExecutionContextProvider = (props: ParentProps<ProviderProps>) => {
    const [currentStepId, setCurrentStepId] = createSignal<string | null>(props.defaultStepId);

    createEffect(() => {
        setCurrentStepId(props.defaultStepId);
    });
    return (
        <ChecklistExecutionContext.Provider
            value={{
                executionId: () => props.executionId,
                currentStepId,
                setCurrentStepId,
                checklistId: () => props.checklistId,
                defaultStepId: () => props.defaultStepId,
            }}
        >
            {props.children}
        </ChecklistExecutionContext.Provider>
    );
};
