import {
    ExecuteWorkflowParams,
    FormResponse,
    NewWorkflowVersionPayload,
    PerformWorkflowActionParams,
    SetBpmnParams,
    WorkflowCompletion,
    WorkflowCompletionStatus,
    WorkflowElement,
    WorkflowElementType,
    WorkflowExecution,
    WorkflowForm,
    WorkflowService,
} from "./interface";
import { BadResponseError, workflowClient } from "../../../modules/client/client";
import {
    any,
    array,
    boolean,
    create,
    enums,
    Infer,
    nullable,
    number,
    record,
    string,
    type,
    union,
} from "superstruct";
import { InputUnion } from "../formbuilder/interfaces/inputs/BaseInput";
import { deserializeInstantDefensive, makeServiceQuery, sDateTimeString } from "../../utils";
import {
    deserializeField,
    deserializeFormResponse,
    serializeField,
    serializeFormValues,
    sV3FormDescription,
    sV3FormResponse,
} from "../formbuilder/DynamicForm";
import { last } from "lodash";
import { Mixin } from "ts-mixer";
import { V3WorkflowCategoryEndpoints } from "./categories/v3";
import { V3WorkflowTypeEndpoints } from "./workflow-types/v3";
import {
    deserializeWorkflowElementType,
    sV3UserStartEvent,
    V3WorkflowElementType,
} from "./workflows/interface";
import V3WorkflowEndpoints from "./workflows/v3";
import { sV3WorkflowProfile } from "../person/implementations/v3";

export default class V3WorkflowService
    extends Mixin(V3WorkflowCategoryEndpoints, V3WorkflowTypeEndpoints, V3WorkflowEndpoints)
    implements WorkflowService
{
    updateWorkflowForm(params: {
        formId: string;
        fields: InputUnion[];
        processVars: Record<string, string>;
    }): Promise<void> {
        return workflowClient
            .put(`/forms/workflow-forms/${params.formId}/description/`)
            .sendJson({
                description: { fields: params.fields.map(serializeField) },
                process_vars: params.processVars,
            })
            .receive(any());
    }

    createWorkflowForm = async (params: {
        fields: InputUnion[];
        processVars: Record<string, string>;
        workflowId: string;
    }): Promise<{ id: string }> =>
        workflowClient
            .post("/forms/workflow-forms/")
            .sendJson({
                description: { fields: params.fields.map(deserializeField) },
                process_vars: params.processVars,
                workflow: params.workflowId,
            })
            .receive(type({ id: string() }));

    retrieveWorkflowForm = async (formId: string): Promise<WorkflowForm> => {
        return workflowClient
            .get(`/forms/workflow-forms/${formId}/`)
            .receive(sV3WorkflowForm())
            .then(deserializeWorkflowForm);
    };

    setBpmn = async (params: SetBpmnParams): Promise<void> =>
        workflowClient
            .put(`/workflows/workflows/${params.workflowId}/set-bpmn/`)
            .sendJson({ bpmn: params.bpmn })
            .receive(any());

    getFormByBpmnElement = async (params: {
        workflowId: string;
        bpmnElementId: string;
    }): Promise<WorkflowForm> =>
        workflowClient
            .get(`/forms/workflows/${params.workflowId}/elements/${params.bpmnElementId}/`)
            .receive(sV3WorkflowForm())
            .then(deserializeWorkflowForm);

    activateWorkflow = async (workflowId: string): Promise<void> =>
        workflowClient.put(`/workflows/workflows/${workflowId}/activate/`).receive(any());

    executeWorkflow = async (params: ExecuteWorkflowParams): Promise<WorkflowExecution> => {
        console.log("EXECUTE", params.startEventType);
        switch (params.startEventType) {
            case WorkflowElementType.VanillaStartEvent:
                await workflowClient
                    .post(`/workflows/workflows/start-event/`)
                    .sendJson({
                        workflow: params.workflowId,
                        start_event: params.startEventElementId,
                    })
                    .receiveNothing();
                break;
            case WorkflowElementType.MessageStartEvent:
                await workflowClient
                    .post(`/workflows/workflows/message-start-event/`)
                    .sendJson({
                        workflow: params.workflowId,
                        start_event: params.startEventElementId,
                        data: await serializeFormValues(params.formValues),
                    })
                    .receiveNothing();
                break;
        }

        // Workaround while the backend doesn't return the created execution
        const allExecutions = await workflowClient
            .get("/workflows/executions/")
            .receive(array(sV3WorkflowExecution()));
        const newExecution = last(allExecutions)!;
        return deserializeWorkflowExecution(newExecution);
    };

    listWorkflowExecutions = makeServiceQuery({
        fetchJson: async () => workflowClient.get("/workflows/executions/").receiveJson(),
        responseSchema: array(sV3WorkflowExecution()),
        deserialize: array => array.map(deserializeWorkflowExecution),
    });

    retrieveWorkflowExecution = makeServiceQuery({
        fetchJson: async (executionId: string): Promise<V3WorkflowExecution> => {
            const json = await this.listWorkflowExecutions.fetchJson();
            const executions = create(json, array(sV3WorkflowExecution()));
            const found = executions.find(execution => execution.id.toString() === executionId);
            if (!found) throw new Error(`Workflow execution with id "${executionId}" not found.`);
            return found;
        },
        responseSchema: sV3WorkflowExecution(),
        deserialize: deserializeWorkflowExecution,
    });

    listWorkflowElements = async (workflowExecutionId: string): Promise<WorkflowElement[]> =>
        workflowClient
            .get(`/workflows/executions/${workflowExecutionId}/elements/`)
            .receive(array(sV3WorkflowElement()))
            .then(array => array.map(deserializeWorkflowElement));

    performWorkflowAction = async (params: PerformWorkflowActionParams): Promise<void> => {
        // Convert params.bpmnElementId to workflowElement.id (they are not the same!)
        const allWorkflowElements = await this.listWorkflowElements(params.executionId);
        const workflowElement = allWorkflowElements.find(
            we => we.bpmnElementId === params.bpmnElementId,
        );
        if (!workflowElement)
            throw new Error(
                `Workflow element not found for BPMN element with ID "${params.bpmnElementId}".`,
            );

        await workflowClient
            .put(`/workflows/elements/${workflowElement.id}/action/`)
            .sendJson({ data: await serializeFormValues(params.formValues) })
            .receive(any());
    };

    retrieveFormResponse = async (params: {
        formId: string;
        executionId: string;
    }): Promise<FormResponse | null> =>
        workflowClient
            .get(`/forms/workflow-forms/${params.formId}/executions/${params.executionId}/`)
            .receive(sV3FormResponse())
            .then(deserializeFormResponse)
            .catch(error => {
                if (error instanceof BadResponseError && error.response.status === 404) return null;
                throw error;
            });

    myStartEvents = makeServiceQuery({
        fetchJson: async (workflowTypeId: string) =>
            workflowClient
                .get(`/workflows/my-start-events/?workflow_type=${workflowTypeId}`)
                .receiveJson(),
        responseSchema: array(sV3UserStartEvent()),
        deserialize: data => data,
    });

    processVars = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/workflows/executions/${id}/process_vars/`).receiveJson(),
        responseSchema: sV3ProcessVar(),
        deserialize: data => data,
    });

    newVersion = async (payload: NewWorkflowVersionPayload): Promise<{ id: string }> => {
        return await workflowClient
            .post(`/workflows/workflows/new-version/`)
            .sendJson(payload)
            .receive(type({ id: string() }));
    };

    userTaskDetail = makeServiceQuery({
        fetchJson: async (id: string) =>
            workflowClient.get(`/workflows/user-tasks/${id}/`).receiveJson(),
        responseSchema: sV3UserTaskDetail(),
        deserialize: data => data,
    });
    userTaskByRecurrenceDetail = makeServiceQuery({
        fetchJson: async (recurrenceId: string) =>
            workflowClient
                .get(`/workflows/recurrence-tasks/${recurrenceId}/user-task/`)
                .receiveJson(),
        responseSchema: sV3UserTaskDetail(),
        deserialize: data => data,
    });
}
function sV3ProcessVar() {
    return record(string(), union([string(), number(), array(union([string(), number()]))]));
}
type V3WorkflowForm = Infer<ReturnType<typeof sV3WorkflowForm>>;

function sV3WorkflowForm() {
    return type({
        id: string(),
        description: sV3FormDescription(),
        process_vars: record(string(), string()),
        workflow: string(),
    });
}

function deserializeWorkflowForm(payload: V3WorkflowForm): WorkflowForm {
    return {
        id: payload.id,
        fields: payload.description.fields.map(deserializeField),
        processVars: payload.process_vars,
        workflowId: payload.workflow,
    };
}

type V3WorkflowExecution = Infer<ReturnType<typeof sV3WorkflowExecution>>;

export function sV3WorkflowExecution() {
    return type({
        id: string(),
        completed: nullable(sV3CompletedExecution()),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        workflow: string(),
    });
}

export function deserializeWorkflowExecution(payload: V3WorkflowExecution): WorkflowExecution {
    return {
        id: payload.id,
        createdAt: Temporal.Instant.from(payload.created_at),
        updatedAt: Temporal.Instant.from(payload.updated_at),
        workflowId: payload.workflow,
        ...deserializeCompletedExecution(payload.completed),
    };
}

type V3CompletedExecution = Infer<ReturnType<typeof sV3CompletedExecution>>;
// export type UserTaskDetail = {
//     id: string;
//     // created_at: string;
//     // updated_at: string;
//     // name: string;
//     // bpmn_id: string;
//     // type: string;
//     // is_active: boolean;
//     // execution_audience: string;
//     // successfully_completed: boolean;
//     // summary: string;
//     // description: string;
//     execution: string;
// }
function sV3UserTaskDetail() {
    return type({
        id: string(),
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        name: string(),
        execution: string(),
        bpmn_id: string(),
        type: string(),
        is_active: boolean(),
        execution_audience: string(),
        successfully_completed: nullable(boolean()),
        summary: string(),
        description: string(),
    });
}
function sV3CompletedExecution() {
    return type({
        id: string(),
        // created_at: sDateTimeString(), // we don't care
        updated_at: sDateTimeString(),
        successfully_completed: boolean(),
        error_message: string(),
    });
}

function deserializeCompletedExecution(payload: V3CompletedExecution | null): WorkflowCompletion {
    return payload === null
        ? { status: WorkflowCompletionStatus.InProcess }
        : payload.successfully_completed
        ? {
              status: WorkflowCompletionStatus.Success,
              completedAt: Temporal.Instant.from(payload.updated_at),
          }
        : {
              status: WorkflowCompletionStatus.Error,
              completedAt: Temporal.Instant.from(payload.updated_at),
              errorMessage: payload.error_message,
          };
}

type V3WorkflowElement = Infer<ReturnType<typeof sV3WorkflowElement>>;

function sV3WorkflowElement() {
    return type({
        id: string(),
        created_at: sDateTimeString(), // we don't care
        updated_at: sDateTimeString(), // we don't care
        name: string(),
        bpmn_id: string(),
        type: enums(Object.values(V3WorkflowElementType)),
        is_active: boolean(),
        user: nullable(sV3WorkflowProfile()),
        // execution: integer(), // we don't care
    });
}

function deserializeWorkflowElement(payload: V3WorkflowElement): WorkflowElement {
    return {
        id: payload.id,
        name: payload.name,
        bpmnElementId: payload.bpmn_id,
        type: deserializeWorkflowElementType(payload.type),
        isActive: payload.is_active,
        createdAt: deserializeInstantDefensive(payload.created_at),
        updatedAt: deserializeInstantDefensive(payload.updated_at),
        user: payload.user,
    };
}

export function sEditBpnmStorage() {
    return type({
        sidePanel: boolean(),
    });
}
