import { Suspense, For, Show } from "solid-js";
import { createListChecklistEvaluationQuery } from "../../../api/services/checklist/queries";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { EvaluationForm } from "./EvaluationForm";
import { Tab, Tabs } from "../../ui/Tabs";
import { ModalController } from "../../ui/Modal";

export function EvaluationList(props: {
    checklistId: string;
    evaluationLabelModal: ModalController<{ evaluationID: string }, void>;
    evaluationLabelConditionModal: ModalController<{ evaluationID: string }, void>;
}) {
    const evaluationsListQuery = createListChecklistEvaluationQuery(() => props.checklistId);

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={evaluationsListQuery.data}>
                {data => (
                    <Tabs parentClass={"my-6"} headerTabClass={"mb-4"}>
                        <For each={data()}>
                            {evaluation => (
                                <Tab title={evaluation.name === "" ? "Recorrido" : evaluation.name}>
                                    <EvaluationForm
                                        checklistId={props.checklistId}
                                        evaluation={evaluation}
                                        evaluationLabelModal={props.evaluationLabelModal}
                                        evaluationLabelConditionModal={
                                            props.evaluationLabelConditionModal
                                        }
                                    />
                                </Tab>
                            )}
                        </For>
                    </Tabs>
                )}
            </Show>
        </Suspense>
    );
}
