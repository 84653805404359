import { P } from "../../../../utils/typography";
import { useLocale } from "../../../i18n/context";
import { BorderedCard } from "../../../ui/cards";
import { EventGeneralDataProps } from "../ExecutionEvents";

export function ActivityDetailCards(props: EventGeneralDataProps) {
    const [locale] = useLocale();
    return (
        <div>
            <P>Detalle de la actividad</P>
            <div class="flex gap-4 space-x-4">
                <BorderedCard>
                    <P>Estado de la Actividad</P>
                    <P>{props.element.isActive ? "En curso" : "Finalizada"}</P>
                    <P>
                        {Intl.DateTimeFormat(locale().codeWithCountry, {
                            weekday: "short",
                        }).format(props.element.createdAt)}
                    </P>
                </BorderedCard>
                <BorderedCard>
                    <P>¿Cuánto tiempo estuvo activa la actividad?</P>
                    <P>XX Minutos</P>
                    <P>x tiempo menos que el promedio</P>
                </BorderedCard>
                <BorderedCard>
                    <P>Tiempo estimado de cierre de actividad</P>
                    <P>X Horas</P>
                </BorderedCard>
            </div>
        </div>
    );
}
