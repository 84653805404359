import { Modal, ModalController } from "../../../ui/Modal";
import { P } from "../../../../utils/typography";
import { FormWrapper } from "../../../forms/FormWrapper";
import { CreateEvaluationLabelConditionPayload } from "../../../../api/services/checklist/interface";
import IntegerField from "../../../forms/fields/IntegerField";
import { createEvaluationLabelConditionMutation } from "../../../../api/services/checklist/mutations";
import { Button } from "../../../ui/components";
import SubmitButton from "../../../forms/SubmitButton";
import EvaluationLabelField from "./EvaluationLabelField";
import { createForm } from "../../../forms/state";

export default function EvaluationLabelConditionModal(props: {
    controller: ModalController<{ evaluationID: string }, void>;
}) {
    const createEvaluationLabelCondition = createEvaluationLabelConditionMutation();
    async function onSubmit(values: CreateEvaluationLabelConditionPayload) {
        await createEvaluationLabelCondition.mutateAsync(values, {
            onSuccess: () => {
                props.controller.dismiss();
            },
        });
    }

    return (
        <Modal controller={props.controller}>
            {data => (
                <>
                    <Modal.Header>
                        <P class={"text-lg font-medium"}>Agregar asignación</P>
                    </Modal.Header>
                    <EvaluationLabelConditionForm
                        evaluationID={data.evaluationID}
                        onCancel={props.controller.dismiss}
                        onSubmit={onSubmit}
                    />
                </>
            )}
        </Modal>
    );
}

export function EvaluationLabelConditionForm(props: {
    onCancel: () => void;
    onSubmit: (values: CreateEvaluationLabelConditionPayload) => void;
    defaultValues?: CreateEvaluationLabelConditionPayload;
    evaluationID: string;
}) {
    const form = createForm<CreateEvaluationLabelConditionPayload>();
    const minField = form.getFieldController("min_grade");
    const minVal = () => minField.value;
    const maxField = form.getFieldController("max_grade");
    const maxVal = () => maxField.value;
    return (
        <FormWrapper onSubmit={props.onSubmit} class={"w-full space-y-4"}>
            <div class={"flex gap-x-3"}>
                <IntegerField
                    name={"min_grade"}
                    label={"Mínimo"}
                    min={0}
                    max={maxVal() - 1}
                    defaultValue={
                        props.defaultValues ? String(props.defaultValues.min_grade) : undefined
                    }
                />
                <IntegerField
                    name={"max_grade"}
                    label={"Máximo"}
                    min={minVal() + 1}
                    max={100}
                    defaultValue={
                        props.defaultValues ? String(props.defaultValues.max_grade) : undefined
                    }
                />
            </div>

            <EvaluationLabelField
                name={"evaluation_label"}
                evaluationID={props.evaluationID}
                defaultValue={props.defaultValues?.evaluation_label}
            />

            <div class={"flex justify-between gap-x-4"}>
                <Button type={"button"} onClick={props.onCancel} bgStyle={"outline"}>
                    Cancelar
                </Button>
                <SubmitButton>{props.defaultValues ? "Guardar" : "Crear"}</SubmitButton>
            </div>
        </FormWrapper>
    );
}
