import { For } from "solid-js";
import { Modal, ModalController } from "../ui/Modal";
import { WorkflowWithStartEvents } from "./WorkflowsByStartEvent";

export function ChooseStartEventModal(props: {
    executeModal: ModalController<WorkflowWithStartEvents, void>;
    workflowType: string;
}) {
    return (
        <Modal controller={props.executeModal}>
            {compundWF => {
                return (
                    <>
                        <Modal.Header>¿De que forma quiere iniciar el workflow?</Modal.Header>
                        <For each={compundWF.startEvents}>
                            {event => (
                                <a
                                    href={`/workflows/${props.workflowType}/${compundWF.workflow.id}/start-execution/${event.bpmn_id}`}
                                >
                                    <div class="cursor-pointer border p-4">
                                        {event.start_event_type}
                                    </div>
                                </a>
                            )}
                        </For>
                    </>
                );
            }}
        </Modal>
    );
}
