import { string, type, optional, Infer } from "superstruct";

export function sAudienceType() {
    return type({
        id: string(),
        name: string(),
        description: optional(string()),
        tenant: string(),
    });
}

export type AudienceType = Infer<ReturnType<typeof sAudienceType>>;
export function sCreateUpdateAudienceType() {
    return type({
        name: string(),
        description: optional(string()),
    });
}
export type CreateUpdateAudienceType = Infer<ReturnType<typeof sCreateUpdateAudienceType>>;
