import _ from "lodash";
import { UserStartEvent } from "../../api/services/workflow/interface";
import { Workflow } from "../../api/services/workflow/workflows/interface";
import { Button } from "../ui/components";
import { createModalController, ModalController } from "../ui/Modal";
import { ThreeColumnsOnDesktop } from "./utils";
import { ChooseStartEventModal } from "./ChooseStartEventModal";
import { NoWorkflows, WorkflowItem } from "./WorkflowsAdministrationPage";
import { useNavigate } from "@solidjs/router";

export type WorkflowWithStartEvents = {
    workflow: Workflow;
    startEvents: UserStartEvent[];
};
export function WorkflowsByStartEvent(props: {
    workflows: Workflow[];
    startEvents: UserStartEvent[];
    workflowType: string;
}) {
    const executeModal = createModalController<WorkflowWithStartEvents, void>();
    const eventsByWorkFlow = () => _.groupBy(props.startEvents, "workflow");
    const filteredWorkflows = () => props.workflows.filter(wf => eventsByWorkFlow()[wf.id]);
    return (
        <div>
            <ThreeColumnsOnDesktop each={filteredWorkflows()} fallback={<NoWorkflows />}>
                {workflow => (
                    <WorkflowItem
                        workflow={workflow}
                        executeAction={
                            <ExecuteButton
                                controller={executeModal}
                                workflow={workflow}
                                eventByWorkflow={eventsByWorkFlow()[workflow.id]}
                                workflowType={props.workflowType}
                            />
                        }
                    />
                )}
            </ThreeColumnsOnDesktop>
            <ChooseStartEventModal executeModal={executeModal} workflowType={props.workflowType} />
        </div>
    );
}

function ExecuteButton(props: {
    controller: ModalController<WorkflowWithStartEvents, void>;
    workflow: Workflow;
    eventByWorkflow: UserStartEvent[];
    workflowType: string;
}) {
    const navigate = useNavigate();

    const onClick = (e: Event) => {
        e.stopPropagation();
        if (props.eventByWorkflow.length === 1) {
            const event = props.eventByWorkflow[0];
            navigate(
                `/workflows/${props.workflowType}/${props.workflow.id}/start-execution/${event.bpmn_id}`,
            );
        } else {
            props.controller.open({
                workflow: props.workflow,
                startEvents: props.eventByWorkflow,
            });
        }
    };
    return (
        <div>
            <Button bgStyle="outline" onClick={onClick}>
                Ir a ejecutar
            </Button>
        </div>
    );
}
