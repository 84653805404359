import { For } from "solid-js";
import { P } from "../../../../utils/typography";
import { EventGeneralDataProps } from "../ExecutionEvents";
type FakeTask = {
    name: string;
};
export function ActivityTasks(props: EventGeneralDataProps) {
    const tasks: FakeTask[] = [{ name: "fake tarea 1" }, { name: "fake tarea 2" }];
    return (
        <div class="">
            <P>Tareas de la actividad {props.element.id}</P>
            <For each={tasks}>{task => <ActivityTaskItem {...props} task={task} />}</For>
        </div>
    );
}

function ActivityTaskItem(props: EventGeneralDataProps & { task: FakeTask }) {
    return (
        <div class="m-4 flex flex-col gap-2 rounded-md border-2 border-black p-4">
            {props.task.name}
            <div>
                <P>¿Cuándo de realizará el procedimiento?</P>
                <P>Se solicita ingresar una fecha</P>
            </div>
            <div>
                <P>Respuesta</P>
                <P>23 de junio de 2023</P>
            </div>
            <div>
                <P>Respondida por Carlos Contreras</P>
                <P>el 21 de junio de 2023</P>
            </div>
        </div>
    );
}
