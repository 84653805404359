import "filepond/dist/filepond.min.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
import { createUniqueId, onCleanup, onMount, ParentProps } from "solid-js";
import { LabelAbove } from "./parts/layouts";
import { getCommonInputProps } from "./parts/props";
import { createField, FieldController } from "../state";
import { FieldProps } from "./parts/types";
import * as FilePond from "filepond";
import { FilePondOptions } from "filepond";
import { createRef } from "../../../utils/reactRefs";
import { documentManagerClient } from "../../../api/clients/documentManager";
import { showErrorToast } from "../../../utils/errorHandling";
import { array, is, string } from "superstruct";
import FilePondPluginGetFile from "filepond-plugin-get-file";

let registeredPlugin = false;

type FilepondFieldValue =
    | { temporary_upload: string } // before submitting files to backend
    | string[] // links of the form `https://.../filepond/:source/download`
    | undefined;

export default function FilepondField(
    props: ParentProps<FieldProps<FilepondFieldValue, HTMLInputElement>>,
) {
    const field = createField(props, undefined);
    const inputId = createUniqueId();
    const inputRef = createRef<HTMLInputElement>();

    onMount(() => {
        if (!registeredPlugin) {
            try {
                FilePond.registerPlugin(FilePondPluginGetFile);
                registeredPlugin = true;
            } catch (err) {
                console.error(err);
            }
        }

        const pond = FilePond.create(inputRef.current!);
        const opts: FilePondOptions = {
            // Use options based on https://github.com/ImperialCollegeLondon/django-drf-filepond?tab=readme-ov-file#chunked-uploads
            chunkUploads: true,
            chunkSize: 500_000,
            server: filepondServer(field, props.name),
            onerror: (error, file, status) => {
                console.error("filepond error", { error, file, status });
                showErrorToast(error);
            },

            // defaultValue
            files: initialFiles(field),

            // filepond-plugin-get-file
            labelButtonDownloadItem: "Descargar archivo",
        };
        // Order of options matters https://github.com/pqina/filepond/issues/594
        if (Object.keys(opts).indexOf("files") < Object.keys(opts).indexOf("server")) {
            const files = opts.files;
            delete opts.files;
            opts.files = files;
        }
        // using pond.setOptions as initial files don't work with FilePond.setOptions (I don't know why)
        pond.setOptions(opts);
    });
    onCleanup(() => FilePond.destroy(inputRef.current!));

    return (
        <LabelAbove {...props} field={field} inputId={inputId}>
            <input
                type="file"
                {...getCommonInputProps(inputId, props)}
                class="filepond"
                ref={inputRef}
            />
        </LabelAbove>
    );
}

function filepondServer(
    field: FieldController<FilepondFieldValue>,
    fieldName: string,
): FilePondOptions["server"] {
    return {
        url: documentManagerClient.urlPrefix + "/filepond",
        process: {
            url: "/process/",
            /* V3 only accepts the file if the input name is "filepond",
             * here we simulate that by crafting the request that would be sent in that case.
             * For context, see https://github.com/ImperialCollegeLondon/django-drf-filepond/issues/4 */
            ondata: data => {
                const file = data.getAll(fieldName).find(value => value instanceof File)!;
                data.delete(fieldName);
                data.append("filepond", "{}");
                data.append("filepond", file);
                return data;
            },
            onload: (temporary_upload: string) => {
                field.setValue({ temporary_upload });
                return temporary_upload;
            },
        },
        // @ts-expect-error - Official typings don't include this for some reason
        patch: "/patch/",
        revert: "/revert/",

        // fetch and restore are used when `files` has a file with type: "limbo"
        fetch: "/fetch/?target=",
        restore: "/restore/",

        load: (source, load, error, _progress, abort, _headers) => {
            console.debug("filepond load", { source, load, error });
            const url = source.startsWith("https")
                ? source
                : `${documentManagerClient.urlPrefix}/filepond/${source}/download`;

            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    // Create a File object from the Blob
                    const fileName = source.split("/").pop() || "file";
                    const file = new File([blob], fileName, { type: blob.type });
                    load(file);
                })
                .catch(err => {
                    console.error("Failed to load file:", err);
                    error(err.message);
                });

            return {
                abort: () => {
                    // User tapped abort, cancel our ongoing actions here
                    abort();
                },
            };
        },
    };
}

function initialFiles(field: FieldController<FilepondFieldValue>): FilePondOptions["files"] {
    if (!field.value) return undefined;
    if (!is(field.value, array(string()))) {
        console.debug(field.value);
        throw new Error(
            `FilepondField expected a field.value with an array of links${
                "temporary_upload" in field.value ? " instead of a temporary_upload" : ""
            }`,
        );
    }
    return field.value.map(url => ({
        source: url,
        options: {
            type: "local",
            // file: {
            //     name: "remote-file.pdf", // Replace with actual file name if known
            //     size: 1234567, // Replace with actual file size if known
            //     type: "application/pdf", // Replace with actual MIME type
            // },
        },
    }));
}
