import { Accessor, For, JSX, Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { P } from "../../../utils/typography";
import { ChecklistSections, SectionStep } from "../../../api/services/checklist/interface";
import { createSectionStepListQuery } from "../../../api/services/checklist/queries";
import { useLocale } from "../../i18n/context";

type Props = {
    section: ChecklistSections;
    customName?: JSX.Element;
    actions?: (step: Accessor<SectionStep[]>) => JSX.Element;
    IterableItem: (props: { step: SectionStep; index: number; disabled?: boolean }) => JSX.Element;
    disabled?: boolean;
    //filterSteps?: (step: SectionStep) => boolean; .filter(props.filterSteps ?? (x => x))
};
export function SectionCard(props: Props) {
    const stepsQuery = createSectionStepListQuery(() => props.section.id);
    const [locale] = useLocale();

    return (
        <div class={"rounded-md border border-light-gray-300"}>
            <header class={"pr-2.5 flex items-baseline border-b border-light-gray-300 py-4 pl-4"}>
                {props.customName ?? <P>{props.section.name}</P>}
            </header>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show when={stepsQuery.data} fallback={<p>Loading...</p>}>
                    {steps => (
                        <div class="p-2">
                            <div class="mb-1 flex flex-col gap-2">
                                <For each={steps()} fallback={<P>{locale().checklists.noSteps}</P>}>
                                    {(step, index) => (
                                        <props.IterableItem
                                            step={step}
                                            index={index()}
                                            disabled={props.disabled}
                                        />
                                    )}
                                </For>
                            </div>
                            {props.actions?.(steps)}
                        </div>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}
