import { Show, Suspense } from "solid-js";
import {
    createPaulsenGroupTypeListQuery,
    createListPaulsenGroupsQuery,
    createListPaulsenPositionsQuery,
} from "../../api/services/audiences-paulsen/queries";
import SelectField from "../forms/fields/SelectField";
import { LavandaCard } from "../ui/cards";
import { useFormState } from "../forms/state";
import {
    PaulsenAudienceGroup,
    PaulsenAudiencePosition,
    PaulsenGroupType,
} from "../../api/services/audiences-paulsen/interface";

export default function AudienceGroupDefinitionForm(props: { identifier: string }) {
    const groupTypesQuery = createPaulsenGroupTypeListQuery();
    const groupListQuery = createListPaulsenGroupsQuery();
    const positionListQuery = createListPaulsenPositionsQuery();
    const form = useFormState<{ [fieldName: `groupType-${string}`]: string }>();
    const selectedGroupType = (): { type: string; id: string | number | undefined } => {
        const groupType = form.values[`groupType-${props.identifier}`];
        const [type, id] = groupType.split("-");
        if (!isNaN(Number(id))) {
            return {
                type,
                id: Number(id),
            };
        }
        return {
            type,
            id: id,
        };
    };
    const groupOptions = (
        groups: PaulsenAudienceGroup[],
        positions: PaulsenAudiencePosition[],
    ): [value: string | number, label: string | number][] => {
        switch (selectedGroupType().type) {
            case "in_group":
                return groups
                    .filter(g => g.type == selectedGroupType().id)
                    .map(group => {
                        return [`${group.id}`, group.name];
                    });
            case "bosses_of":
                return positions.map(position => {
                    return [`${position.id}`, position.name];
                });
            case "subordinates_of":
                return positions.map(position => {
                    return [`${position.id}`, position.name];
                });
            default:
                return [];
        }
    };
    const groupTypeOptions = (
        groupTypes: PaulsenGroupType[],
    ): [value: string | number, label: string | number][] => {
        const bossesOf: [value: string | number, label: string | number][] = [
            [`bosses_of`, `Jefes de`],
        ];
        const subordinatesOf: [value: string | number, label: string | number][] = [
            [`subordinates_of`, `Subordinados de`],
        ];
        const groupOptions: [value: string | number, label: string | number][] = groupTypes.map(
            groupType => {
                return [`in_group-${groupType.id}`, `Usuarios que pertenecen al ${groupType.name}`];
            },
        );
        return [...groupOptions, ...bossesOf, ...subordinatesOf];
    };

    return (
        <LavandaCard>
            <Suspense>
                <div class="space-y-2">
                    <Show when={groupTypesQuery.data}>
                        {groupTypes => {
                            return (
                                <SelectField
                                    name={`groupType-${props.identifier}`}
                                    label={""} //Tipo de grupo
                                    options={[
                                        ...groupTypeOptions(groupTypes()),
                                        //...positionOptions(positionList()),
                                    ]}
                                    defaultValue={`in_group-${groupTypes()[0].id}`}
                                    // onChange={
                                    //     (e) => {
                                    //         setSelectedGroupType(Number(e.target.value));
                                    //     }
                                    // }
                                />
                            );
                        }}
                    </Show>
                    <Show when={positionListQuery.data}>
                        {positionList => {
                            return (
                                <Show when={groupListQuery.data}>
                                    {groupList => {
                                        return (
                                            <SelectField
                                                name={`group-${props.identifier}`}
                                                label={""} //Grupo
                                                options={[
                                                    ...groupOptions(groupList(), positionList()),
                                                ]}
                                                //disabled={selectedGroupType() === undefined}
                                            />
                                        );
                                    }}
                                </Show>
                            );
                        }}
                    </Show>
                </div>
            </Suspense>
        </LavandaCard>
    );
}
