import { createSignal, Show, Suspense } from "solid-js";
import { PageWrapper } from "../ui/pageWrappers";
import {
    createAllWorkflowTypesQuery,
    createMyExecutionsQuery,
    createMyWorkflowTypesQuery,
} from "../../api/services/workflow/workflow-types/queries";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { H2, P } from "../../utils/typography";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ThreeColumnsOnDesktop } from "./utils";
import { BorderedCard } from "../ui/cards";
import {
    CreateWorkflowType,
    WorkflowType,
} from "../../api/services/workflow/workflow-types/interface";
import { createListWorkflowCategoriesQuery } from "../../api/services/workflow/categories/queries";
import { Tab, Tabs } from "../ui/Tabs";
import { WorkflowExecutionItem } from "./WorkflowExecutionList";
import GenericFileExplorer from "../ui/genericFileExplorer/GenericFileExplorer";
import {
    createDestroyWorkflowCategoryMutation,
    createUpdateWorkflowCategoryMutation,
    createWorkflowCategoryMutation,
} from "../../api/services/workflow/categories/mutations";
import {
    UpdateWorkflowCategory,
    WorkflowCategory,
} from "../../api/services/workflow/categories/interface";
import {
    createDestroyWorkflowTypeMutation,
    createUpdateWorkflowTypeMutation,
    createWorkflowTypeMutation,
} from "../../api/services/workflow/workflow-types/mutations";
import { FormWrapper } from "../forms/FormWrapper";
import TextField from "../forms/fields/TextField";
import ColorField from "../forms/fields/ColorField";
import UuidAudienceField from "../audiences/UuidAudienceField";
import IconField from "../forms/fields/IconField";
import SubmitButton from "../forms/SubmitButton";
import { createModalController, Modal, ModalController } from "../ui/Modal";
import { Button } from "../ui/components";
import DeleteButton from "../ui/DeleteButton";
import { EditButton } from "../ui/EditableName";
import { createWorkflowListQuery } from "../../api/services/workflow/workflows/queries";
import { CreateWorkflowForm, WorkflowItem } from "./WorkflowsAdministrationPage";

export default function WorkflowsPlayground() {
    const [workflowType, setWorkflowType] = createSignal<WorkflowType>();
    return (
        <PageWrapper>
            <MyWorkflowTypes />
            <AllWorkflowTypes onSelect={setWorkflowType} />
            <Show when={workflowType()}>
                {workflowType => (
                    <Tabs>
                        <Tab title="Categorías">
                            <WorkflowFileExplorer workflowType={workflowType()} />
                        </Tab>
                        <Tab title="Mis ejecuciones">
                            <MyExecutions workflowType={workflowType()} />
                        </Tab>
                    </Tabs>
                )}
            </Show>
        </PageWrapper>
    );
}

function MyWorkflowTypes() {
    const myWorkflowTypesQuery = createMyWorkflowTypesQuery();

    return (
        <div>
            <H2>My WorkflowTypes</H2>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={myWorkflowTypesQuery.data}
                    fallback={<ErrorBlock error={myWorkflowTypesQuery.error} />}
                >
                    {myWorkflowTypes => (
                        <ThreeColumnsOnDesktop
                            each={myWorkflowTypes()}
                            fallback={<P>No tienes tipos de workflow.</P>}
                        >
                            {workflowType => <WorkflowTypeCard workflowType={workflowType} />}
                        </ThreeColumnsOnDesktop>
                    )}
                </Show>
            </Suspense>
        </div>
    );
}

function AllWorkflowTypes(props: { onSelect: (workflowType: WorkflowType) => void }) {
    const allWorkflowTypesQuery = createAllWorkflowTypesQuery();
    const modal = createModalController<WorkflowType | undefined, void>();

    return (
        <div>
            <H2>All WorkflowTypes</H2>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={allWorkflowTypesQuery.data}
                    fallback={<ErrorBlock error={allWorkflowTypesQuery.error} />}
                >
                    {allWorkflowTypes => (
                        <ThreeColumnsOnDesktop
                            each={allWorkflowTypes()}
                            fallback={<P>No existen tipos de workflow.</P>}
                        >
                            {workflowType => (
                                <WorkflowTypeCard
                                    workflowType={workflowType}
                                    onClick={() => props.onSelect(workflowType)}
                                    onClickEdit={() => modal.open(workflowType)}
                                />
                            )}
                        </ThreeColumnsOnDesktop>
                    )}
                </Show>
                <Button
                    onClick={() => modal.open(undefined)}
                    icon="fas fa-plus"
                    bgStyle="text-only"
                >
                    Crear tipo de workflow
                </Button>
                <UpdateOrCreateWorkflowTypeModal controller={modal} />
            </Suspense>
        </div>
    );
}

function WorkflowTypeCard(props: {
    workflowType: WorkflowType;
    onClick?: () => void;
    onClickEdit?: () => void;
}) {
    const onClickEdit = async (e: Event) => {
        e.stopPropagation();
        props.onClickEdit?.();
    };

    const destroyWorkflowTypeMutation = createDestroyWorkflowTypeMutation();
    const onClickDelete = async (e: Event) => {
        e.stopPropagation();
        if (confirm(`¿Borrar el tipo de workflow "${props.workflowType.name}"?`))
            await destroyWorkflowTypeMutation.mutateAsync(props.workflowType.id);
    };

    return (
        <BorderedCard
            onClick={props.onClick}
            class="flex h-full items-baseline"
            color={props.workflowType.color}
        >
            <i class={props.workflowType.icon} style={{ color: props.workflowType.color }} />
            <div class="ml-3 flex-1">{props.workflowType.name}</div>
            <Show when={props.onClickEdit}>
                <EditButton onClick={onClickEdit} />
            </Show>
            <DeleteButton onClick={onClickDelete} />
        </BorderedCard>
    );
}

function UpdateOrCreateWorkflowTypeModal(props: {
    controller: ModalController<WorkflowType | undefined, void>;
}) {
    const updateMutation = createUpdateWorkflowTypeMutation();
    const createMutation = createWorkflowTypeMutation();
    const updateOrCreate = async (
        formValues: CreateWorkflowType,
        workflowType: WorkflowType | undefined,
    ) => {
        if (workflowType) await updateMutation.mutateAsync({ id: workflowType.id, ...formValues });
        else await createMutation.mutateAsync(formValues);
        props.controller.dismiss();
    };
    const onClickCancel = (workflowType: WorkflowType | undefined) => {
        if (confirm(`¿Cancelar la ${workflowType ? "edición" : "creación"} de tipo de workflow?`))
            props.controller.dismiss();
    };

    return (
        <Modal confirmMode controller={props.controller}>
            {workflowType => (
                <>
                    <Modal.Header>
                        {workflowType ? "Editar" : "Crear"} tipo de workflow
                    </Modal.Header>
                    <FormWrapper
                        onSubmit={values => updateOrCreate(values, workflowType)}
                        class="flex flex-col gap-3"
                        defaultValues={workflowType}
                    >
                        <WorkflowTypeFields />
                        <div class="flex justify-center gap-3">
                            <SubmitButton>
                                {workflowType ? "Guardar cambios" : "Crear tipo de workflow"}
                            </SubmitButton>
                            <Button
                                type="button"
                                variant="danger"
                                bgStyle="outline"
                                onClick={() => onClickCancel(workflowType)}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </FormWrapper>
                </>
            )}
        </Modal>
    );
}

function WorkflowTypeFields() {
    return (
        <div class="flex flex-col gap-3">
            <TextField name="name" label="Nombre" />
            <UuidAudienceField name="admin_audience" label="Audiencia de administración" />
            <UuidAudienceField name="read_audience" label="Audiencia de visualización" />
            <IconField
                name="icon"
                label="Icono"
                defaultValue="fas fa-project-diagram"
                placeholder="fas fa-project-diagram"
                optional
            />
            <ColorField name="color" label="Color" defaultValue="#808080" />
        </div>
    );
}

function MyExecutions(props: { workflowType: WorkflowType }) {
    const myExecutionsQuery = createMyExecutionsQuery(() => props.workflowType.id);

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show
                when={myExecutionsQuery.data}
                fallback={<ErrorBlock error={myExecutionsQuery.error} />}
            >
                {myExecutions => (
                    <ThreeColumnsOnDesktop
                        each={myExecutions()}
                        fallback={<P>No tienes ejecuciones de workflow.</P>}
                    >
                        {execution => (
                            <WorkflowExecutionItem
                                execution={execution}
                                navigateToExecutionDetail={() =>
                                    (window.location.href = `/workflows/executions-administration/${execution.id}`)
                                }
                            />
                        )}
                    </ThreeColumnsOnDesktop>
                )}
            </Show>
        </Suspense>
    );
}

export function WorkflowFileExplorer(props: { workflowType: WorkflowType }) {
    const categoriesQuery = createListWorkflowCategoriesQuery(() => ({
        workflow_type: props.workflowType.id,
    }));

    const newCategoryMutation = createWorkflowCategoryMutation();
    const createFolder = async (name: string, parent: WorkflowCategory["id"] | null) => {
        return await newCategoryMutation.mutateAsync({
            name,
            icon: "fas fa-folder",
            ...(parent ? { parent } : { workflow_type: props.workflowType.id }),
        });
    };

    const updateCategoryMutation = createUpdateWorkflowCategoryMutation();
    const updateFolder = async (body: UpdateWorkflowCategory) => {
        await updateCategoryMutation.mutateAsync(body);
    };

    const destroyCategoryMutation = createDestroyWorkflowCategoryMutation();
    const deleteFolder = async (folder: WorkflowCategory) => {
        await destroyCategoryMutation.mutateAsync(folder.id);
    };

    const workflowsQuery = createWorkflowListQuery(() => ({
        workflow_type: props.workflowType.id,
    }));

    const createWorkflowModal = createModalController<WorkflowCategory | undefined, void>();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show
                when={categoriesQuery.data}
                fallback={<ErrorBlock error={categoriesQuery.error} />}
            >
                {categories => (
                    <Show
                        when={workflowsQuery.data}
                        fallback={<ErrorBlock error={workflowsQuery.data} />}
                    >
                        {workflows => (
                            <GenericFileExplorer
                                folders={categories()}
                                filterEntities={categoryId =>
                                    workflows().filter(
                                        workflow => workflow.workflow_group.category === categoryId,
                                    )
                                }
                                renderEntity={workflow => <WorkflowItem workflow={workflow} />}
                                EntitiesContainer={props => (
                                    <div class="mt-12 grid grid-cols-12 gap-3">
                                        {props.children}
                                    </div>
                                )}
                                urlPrefix={`/workflows/${props.workflowType.id}/administration`}
                                createFolder={createFolder}
                                updateFolder={updateFolder}
                                deleteFolder={deleteFolder}
                                entityName="Workflow"
                                entityDescription={`Administración de los workflows de tipo "${props.workflowType.name}"`}
                                createEntity={category => createWorkflowModal.open(category)}
                            />
                        )}
                    </Show>
                )}
            </Show>
            <Modal controller={createWorkflowModal} confirmMode>
                {category => (
                    <>
                        <Modal.Header>
                            <h1 class="mb-1 text-display-md">Crear workflow</h1>
                            <Show when={category}>
                                {category => (
                                    <h2 class="mb-3 text-lg">
                                        en la categoría{" "}
                                        <span class="inline-flex items-baseline text-md font-medium">
                                            <div class="flex w-6 justify-center">
                                                <i class={category().icon} />
                                            </div>
                                            {category().name}
                                        </span>
                                    </h2>
                                )}
                            </Show>
                        </Modal.Header>

                        <CreateWorkflowForm
                            defaultCategoryId={category?.id}
                            showCategoryField={false}
                        />
                    </>
                )}
            </Modal>
        </Suspense>
    );
}
