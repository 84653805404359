import { For, Show, untrack } from "solid-js";
import { LogicParserOptions } from "../../../../api/services/logic-parser/interface";
import SelectField from "../../../forms/fields/SelectField";
import { useFormState } from "../../../forms/state";
import { FrontendTargetEntity } from "../CreateChecklist";
import { FunctionParserInputDispatcher } from "./InputDispatcher";

export function FilterFunctionSection(props: {
    index: number;
    filterOptions: LogicParserOptions;
    defaultValues?: {
        filter: string;
        filterValue: string;
    };
}) {
    const form = useFormState<{
        entities: FrontendTargetEntity[];
    }>();

    return (
        <div class={"flex gap-x-4"}>
            <SelectField
                name={`entities[${props.index}].filter`}
                label={"Tipo de Filtro"}
                defaultValue={props.defaultValues?.filter}
                options={[
                    ["", "Seleccione una opción"],
                    ...(props.filterOptions.functions.map(item => [
                        item.function,
                        getNameFromFunction(item.function),
                    ]) as [value: string | number, label: string | number][]),
                ]}
                onChange={() => {
                    //delete value from filerValue
                    const index = untrack(() => props.index);
                    form.setValues(values => {
                        values.entities[index].filterValue = "";
                    });
                }}
                //onChange={e => onChangeFilterType(e.currentTarget.value)}
                // onChange={() => {
                //     form.setValues
                // }}
            />

            <For each={props.filterOptions.functions}>
                {item => {
                    return (
                        <Show when={form.values.entities[props.index]?.filter == item.function}>
                            <FunctionParserInputDispatcher
                                func={item}
                                index={props.index}
                                defaultValue={props.defaultValues?.filterValue}
                            />
                        </Show>
                    );
                }}
            </For>
        </div>
    );
}

const functionNameDict: Record<string, string> = {
    filter_venue: "Por Recinto",
    filter_type: "Por Tipo",
    filter_code: "Por Código",
    filter_last_status: "Por Último Estado",
    filter_category: "Por Categoría",
    filter_has_qr: "Por QR (VERIFICAR)", //Verificar traducción
    filter_has_position: "Por Posición (VERIFICAR)", //Verificar traducción
    filter_company: "Por Empresa",
    filter_company_type: "Por Tipo de Empresa",
    filter_venue_category: "Por Categoría de Recinto",
    filter_venue_business_line: "Por Línea de Negocio de Recinto",
    filter_id: "Por ID", //Verificar traducción
    filter_address_contains: "Por Dirección (VERIFICAR)", //Verificar traducción
    filter_gla_greater_than: "Por gla Mayor a (VERIFICAR)", //Verificar traducción
    filter_gla_less_than: "Por gla Menor a (VERIFICAR)", //Verificar traducción
    filter_business_line: "Por Línea de Negocio",
    filter_country: "Por País",
};
function getNameFromFunction(functionName: string): string {
    return functionNameDict[functionName] ?? functionName;
}
