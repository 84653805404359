import { useParams } from "@solidjs/router";
import { PageWrapper } from "../ui/pageWrappers";
import { WorkflowFileExplorer } from "./WorkflowsPlayground";
import { createRetrieveWorkflowTypeQuery } from "../../api/services/workflow/workflow-types/queries";
import { Show, Suspense } from "solid-js";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { GenericSuspenseFallback } from "../ui/skeletons";

export default function WorkflowTypeAdministrationPage() {
    const params = useParams<{ workflowType: string }>();

    return (
        <PageWrapper>
            <WorkflowTypeAdministration workflowTypeId={params.workflowType} />
        </PageWrapper>
    );
}

function WorkflowTypeAdministration(props: { workflowTypeId: string }) {
    const query = createRetrieveWorkflowTypeQuery(() => props.workflowTypeId);

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={query.data} fallback={<ErrorBlock error={query.error} />}>
                {workflowType => <WorkflowFileExplorer workflowType={workflowType()} />}
            </Show>
        </Suspense>
    );
}
