import { createApiMutation } from "../../utils";
import { CreatePaulsenAudiencePayload, CreatePaulsenVariablePayload } from "./interface";

/** @deprecated */
export function createMakePaulsenAudienceMutation() {
    return createApiMutation(api => ({
        mutationKey: ["makeAudience"],
        mutationFn: (payload: CreatePaulsenAudiencePayload) =>
            api.audiencesPaulsen.createAudience(payload),
    }));
}

/** @deprecated */
export function createMakePaulsenVariableMutation() {
    return createApiMutation(api => ({
        mutationKey: ["variable"],
        mutationFn: (payload: CreatePaulsenVariablePayload) =>
            api.audiencesPaulsen.createVariable(payload),
    }));
}
