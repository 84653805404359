import { P } from "../../../utils/typography";
import { Tab } from "../../ui/Tabs";
import { ActivityItem } from "../../../api/services/task-manager/interface";
import { useLocale } from "../../i18n/context";

export function DetailTab(props: { activity: ActivityItem }) {
    //
    const [locale] = useLocale();
    return (
        <Tab title={"Detalle"}>
            <div class={"mt-3 space-y-3"}>
                <div>
                    <P class={"!mb-1 text-sm"}>Fecha límite para ejecutar la actividad</P>
                    <P class={"!mb-0 font-medium"}>
                        {Intl.DateTimeFormat(locale().codeWithCountry, {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: false,
                        }).format(props.activity.dueDate.toPlainDateTime())}
                    </P>
                </div>
                <div>
                    <P class={"!mb-1 text-sm"}>¿Quién debe realizar la actividad?</P>
                    <div class={"flex flex-wrap gap-1"}>
                        <P class={"rounded-xs border border-light-gray-300 px-1 text-sm"}>
                            {props.activity.executionAudience}
                        </P>
                    </div>
                </div>

                <div>
                    {/* <P class={"!mb-1 text-sm"}>¿Cómo se debe completar la activiad?</P> */}
                    <div class={"flex flex-wrap gap-1"}>
                        <P>Al menos 1 de los responsables debe completar la actividad</P>
                    </div>
                </div>

                {/* <Show
                        when={notifications.length > 0}
                    >
                          <div>
                        <P class={"text-sm font-medium"}>Notificaciones</P>
                            <NotificationCard notificationTypes={notifications} />
                        </div>
                    </Show> */}

                {/* <div>
                    <P class={"font-medium"}>Tags</P>
                    <div class={"flex flex-wrap gap-2"}>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            Mantención
                        </Badge>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            Continuidad operacional
                        </Badge>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            Actividad recurrente
                        </Badge>
                        <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
                            +
                        </Badge>
                    </div>
                </div> */}
            </div>
        </Tab>
    );
}
// function NotificationCard(props:{
//     notificationTypes: string[],
// }){
//     return (
//         <BorderedCard>
//             <div class={"flex w-full gap-x-3"}>
//                 <div>
//                     <div
//                         class={
//                             "flex min-h-8 min-w-8 items-center justify-center rounded-full bg-light-gray-100"
//                         }
//                     >
//                         <i class="far fa-bell"></i>
//                     </div>
//                 </div>
//                 <div>
//                     <P class={"font-medium"}>Notificaciones</P>
//                     <div class={"flex justify-between"}>

//                         <For
//                             each={props.notificationTypes}
//                         >
//                             {notificationType => {
//                                 return (
//                                     <Badge class={"w-fit border border-light-gray-200 !bg-light-gray-50"}>
//                                         {notificationType}
//                                     </Badge>
//                                 );
//                             }}
//                         </For>
//                     </div>
//                 </div>
//             </div>
//         </BorderedCard>
//     )
// }
