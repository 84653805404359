import { array } from "superstruct";
import { makeServiceQuery } from "../../../utils";
import { newPersonClient } from "../../person/person";
import {
    CreateUpdateAudienceExpressionBody,
    sAudienceExpression,
} from "../models/AudienceExpression";
import { CreateUpdateAudienceType, sAudienceType } from "../models/AudienceType";
import { CreateUpdateAudience, sAudience } from "../models/Audience";
import { AudiencesLuchoService } from "../interface";

export default class V3AudiencesLuchoService implements AudiencesLuchoService {
    retrieveAudienceExpressions = makeServiceQuery({
        fetchJson: async () => newPersonClient.get("/audience-expressions").receiveJson(),
        responseSchema: array(sAudienceExpression()),
        deserialize: array => array,
    });

    getAudienceExpression = makeServiceQuery({
        fetchJson: async (audienceId: string) =>
            newPersonClient.get(`/audience-expressions/${audienceId}`).receiveJson(),
        responseSchema: sAudienceExpression(),
        deserialize: audience => audience,
    });

    createAudienceExpression = async (payload: CreateUpdateAudienceExpressionBody) => {
        const response = await newPersonClient
            .post("/audience-expressions")
            .sendJson(payload)
            .receive(sAudienceExpression());
        return response;
    };

    putAudienceExpression = async (
        audienceId: string,
        payload: CreateUpdateAudienceExpressionBody,
    ) => {
        const response = await newPersonClient
            .put(`/audience-expressions/${audienceId}`)
            .sendJson(payload)
            .receive(sAudienceExpression());
        return response;
    };

    patchAudienceExpression = async (
        audienceId: string,
        payload: CreateUpdateAudienceExpressionBody,
    ) => {
        const response = await newPersonClient
            .patch(`/audience-expressions/${audienceId}`)
            .sendJson(payload)
            .receive(sAudienceExpression());
        return response;
    };

    deleteAudienceExpression = async (audienceId: string) => {
        await newPersonClient.delete(`/audience-expressions/${audienceId}`);
    };

    retrieveAudienceTypes = makeServiceQuery({
        fetchJson: async () => newPersonClient.get("/audience-types").receiveJson(),
        responseSchema: array(sAudienceType()),
        deserialize: array => array,
    });

    getAudienceType = makeServiceQuery({
        fetchJson: async (audienceTypeId: string) =>
            newPersonClient.get(`/audience-types/${audienceTypeId}`).receiveJson(),
        responseSchema: sAudienceType(),
        deserialize: audience => audience,
    });

    createAudienceType = async (payload: CreateUpdateAudienceType) => {
        const response = await newPersonClient
            .post("/audience-types")
            .sendJson(payload)
            .receive(sAudienceType());
        return response;
    };

    putAudienceType = async (audienceTypeId: string, payload: CreateUpdateAudienceType) => {
        const response = await newPersonClient
            .put(`/audience-types/${audienceTypeId}`)
            .sendJson(payload)
            .receive(sAudienceType());
        return response;
    };

    patchAudienceType = async (audienceTypeId: string, payload: CreateUpdateAudienceType) => {
        const response = await newPersonClient
            .patch(`/audience-types/${audienceTypeId}`)
            .sendJson(payload)
            .receive(sAudienceType());
        return response;
    };

    deleteAudienceType = async (audienceTypeId: string) => {
        await newPersonClient.delete(`/audience-types/${audienceTypeId}`);
    };

    retrieveAudiences = makeServiceQuery({
        fetchJson: async () => newPersonClient.get("/audiences").receiveJson(),
        responseSchema: array(sAudience()),
        deserialize: array => array,
    });

    getAudience = makeServiceQuery({
        fetchJson: async (audienceId: string) =>
            newPersonClient.get(`/audiences/${audienceId}`).receiveJson(),
        responseSchema: sAudience(),
        deserialize: audience => audience,
    });

    createAudience = async (payload: CreateUpdateAudience) => {
        const response = await newPersonClient
            .post("/audiences")
            .sendJson(payload)
            .receive(sAudience());
        return response;
    };

    putAudience = async (audienceId: string, payload: CreateUpdateAudience) => {
        const response = await newPersonClient
            .put(`/audiences/${audienceId}`)
            .sendJson(payload)
            .receive(sAudience());
        return response;
    };

    patchAudience = async (audienceId: string, payload: CreateUpdateAudience) => {
        const response = await newPersonClient
            .patch(`/audiences/${audienceId}`)
            .sendJson(payload)
            .receive(sAudience());
        return response;
    };

    deleteAudience = async (audienceId: string) => {
        await newPersonClient.delete(`/audiences/${audienceId}`);
    };
}
