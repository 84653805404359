import { Show, Suspense } from "solid-js";
import { PageWrapper } from "../../ui/pageWrappers";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { BoundaryEvent, BpmnElement, UserTask } from "../bpmn";
import { ElementTitle } from "../utils";
import NotImplementedElementExecution, {
    ElementExecutionProps,
} from "./NotImplementedElementExecution";
import { Dynamic } from "solid-js/web";
import { createPerformWorkflowActionMutation } from "../../../api/services/workflow/mutations";
import {
    createFormByBpmnElementQuery,
    createFormResponseQuery,
    createWorkflowExecutionDetailQuery,
} from "../../../api/services/workflow/queries";
import { JsonDebug } from "../../../utils/debug";
import { showErrorToast } from "../../../utils/errorHandling";
import { RenderDynamicFields } from "../../FormBuilder/RenderDynamicForm";
import { FormWrapper } from "../../forms/FormWrapper";
import { FormValues } from "../../forms/state";
import SubmitButton from "../../forms/SubmitButton";
import { useLocale } from "../../i18n/context";
import { Priority } from "../../../api/services/task-manager/interface";
import { WorkflowExecution } from "../../../api/services/workflow/interface";
import { useClock } from "../../../utils/clock";
import { P } from "../../../utils/typography";
import { Countdown } from "../../Activities/ActivityItemDesktop";

export function ElementExecution(props: ElementExecutionProps<BpmnElement>) {
    const getWidget = (element: BpmnElement) => {
        if (element instanceof BoundaryEvent) return BoundaryEventExecution;
        if (element instanceof UserTask) return UserTaskExecution;
        return NotImplementedElementExecution;
    };

    return (
        <PageWrapper>
            <div class="min-w-140">
                <ElementTitle element={props.element} />
                <Suspense fallback={<GenericSuspenseFallback />}>
                    <Dynamic component={getWidget(props.element)} {...props} />
                </Suspense>
            </div>
        </PageWrapper>
    );
}

export function UserTaskExecution(
    props: Omit<ElementExecutionProps<UserTask>, "element" | "workflowId"> & {
        element: { id: string };
    },
) {
    const [locale] = useLocale();
    const performWorkflowActionMutation = createPerformWorkflowActionMutation();
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => props.executionId);
    const formByBpmnElementQuery = createFormByBpmnElementQuery(() => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        workflowId: executionDetailQuery.data?.workflowId!, //props.workflowId
        bpmnElementId: props.element.id,
    }));
    const formResponseQuery = createFormResponseQuery(() => ({
        formId: formByBpmnElementQuery.data?.id,
        executionId: props.executionId,
    }));

    const handleSubmit = async (formValues: FormValues) => {
        try {
            await performWorkflowActionMutation.mutateAsync({
                executionId: props.executionId,
                bpmnElementId: props.element.id,
                formValues,
            });
            props.onActionSuccess();
        } catch (error) {
            showErrorToast(error);
        }
    };

    return (
        <Show when={formByBpmnElementQuery.data}>
            {form => (
                <Show when={formResponseQuery.data !== undefined}>
                    <Show when={formResponseQuery.data !== null}>
                        <JsonDebug value={formResponseQuery.data} />
                    </Show>
                    <FormWrapper
                        class="flex flex-col gap-3"
                        onSubmit={handleSubmit}
                        defaultValues={formResponseQuery.data?.formValues ?? undefined}
                    >
                        <RenderDynamicFields fields={form().fields} />
                        <SubmitButton>{locale().workflows.actionSubmit}</SubmitButton>
                    </FormWrapper>
                </Show>
            )}
        </Show>
    );
}

function BoundaryEventExecution(props: ElementExecutionProps<BoundaryEvent>) {
    const executionDetailQuery = createWorkflowExecutionDetailQuery(() => props.executionId);

    return (
        <Show when={executionDetailQuery.data}>
            {execution => (
                <Show when={props.element.duration} fallback={<P>Sin tiempo de expiración.</P>}>
                    {duration => <TimerEvent duration={duration()} execution={execution()} />}
                </Show>
            )}
        </Show>
    );
}

function TimerEvent(props: { duration: Temporal.Duration; execution: WorkflowExecution }) {
    const { nowInstant } = useClock();
    const remainingTime = () =>
        nowInstant().until(Temporal.Instant.from(props.execution.updatedAt).add(props.duration));

    return (
        <Countdown
            remainingTime={remainingTime()}
            // Hack for demo, priority should come from the workflow in the final version
            priority={Priority.ThreeUpArrows}
        />
    );
}
