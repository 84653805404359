import { defensive, sDateTimeString, ServiceQuery } from "../../utils";
import {
    any,
    array,
    integer,
    literal,
    nullable,
    number,
    optional,
    record,
    string,
    type,
} from "superstruct";

export interface LocationsService {
    listVenues: ServiceQuery<[], Venue[]>;
}

export type Venue = {
    target_entity?: "venue";

    id: string;
    created_at?: string;
    updated_at?: string;
    name: string;
    description: string;
    code: string;
    address: string;
    country: string;
    timezone: string;
    gla: string | null;
    legacy_id: number | null;
    extra_data: Record<string, unknown> | null;
    business_line: number | null;
    categories: number[];
};

export function sVenue() {
    return type({
        target_entity: optional(literal("venue")),

        id: string(),
        created_at: defensive(sDateTimeString(), undefined),
        updated_at: defensive(sDateTimeString(), undefined),
        name: string(),
        description: string(),
        code: string(),
        address: string(),
        country: string(),
        timezone: string(),
        gla: nullable(string()),
        legacy_id: nullable(number()),
        extra_data: nullable(record(string(), any())),
        business_line: nullable(integer()),
        categories: array(integer()),
    });
}
