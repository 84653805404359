import { Modal, ModalController } from "../Modal";
import { Button } from "../components";
import { P } from "../../../utils/typography";
import { FormWrapper } from "../../forms/FormWrapper";
import SubmitButton from "../../forms/SubmitButton";
import { Folder } from "./GenericFileExplorer";
import { createRetrieveCategoryDetailQuery } from "../../../api/services/checklist/queries";
import { Show, Suspense } from "solid-js";
import { GenericSuspenseFallback } from "../skeletons";
import { ErrorBlock } from "../../../utils/GenericErrorBoundary";

export default function DeleteCategoryModalConfirm<T extends Folder>(props: {
    controller: ModalController<T, void>;
    deleteFolder?: (body: T) => Promise<void>;
}) {
    return (
        <Modal controller={props.controller} confirmMode>
            {folder => <DeleteModalContent {...props} folder={folder} />}
        </Modal>
    );
}

function DeleteModalContent<T extends Folder>(props: {
    deleteFolder?: (body: T) => Promise<void>;
    controller: ModalController<T, void>;
    folder: T;
}) {
    async function onSubmit() {
        await props.deleteFolder?.(props.folder);
        props.controller.dismiss();
    }
    const categoryId = () => props.folder.id;
    const categoryDetailQuery = createRetrieveCategoryDetailQuery(categoryId);
    return (
        <>
            <Modal.Header>
                <div class="flex items-baseline space-x-2">
                    <Button
                        icon={"far fa-trash-alt text-error-600"}
                        bgStyle={"text-only"}
                        class={"!h-12 !w-12 !rounded-full bg-error-100"}
                    />
                    <P class={"text-lg font-semibold"}>Eliminar categoría</P>
                </div>
            </Modal.Header>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={categoryDetailQuery.data}
                    fallback={<ErrorBlock error={categoryDetailQuery.error} />}
                >
                    {data => (
                        <Show
                            when={data().is_deletable}
                            fallback={
                                <div class={"space-y-2 pt-4"}>
                                    <P>
                                        Esta categoría no se puede eliminar porque tiene contenido
                                    </P>
                                    <div class="flex gap-x-3 pt-8">
                                        <Button
                                            class={"!w-full"}
                                            bgStyle={"outline"}
                                            onClick={props.controller.dismiss}
                                        >
                                            Cerrar
                                        </Button>
                                    </div>
                                </div>
                            }
                        >
                            <FormWrapper class={"space-y-2 pt-4"} onSubmit={onSubmit}>
                                <P>¿Está seguro que desea eliminar la categoría {data().name}?</P>
                                {/* <P>Esta acción es irreversible</P> */}

                                <div class="flex gap-x-3 pt-8">
                                    <Button
                                        class={"!w-full"}
                                        bgStyle={"outline"}
                                        onClick={props.controller.dismiss}
                                    >
                                        Cancelar
                                    </Button>
                                    <SubmitButton
                                        variant={"danger"}
                                        class={"!w-full"}
                                        disabledReason="No se pueden eliminar categorías que tienen contenido"
                                        disabled={!data().is_deletable}
                                    >
                                        Eliminar categoría
                                    </SubmitButton>
                                </div>
                            </FormWrapper>
                        </Show>
                    )}
                </Show>
            </Suspense>
        </>
    );
}
