import { FormPayload } from "../../../../modules/FormBuilder/RenderDynamicForm";
import { ChecklistPayload } from "../../workflow/interface";
import { InputTypeEnum } from "../interfaces/InputTypeEnum";
import { makeBlankField } from "../../../../modules/FormBuilder/makeBlankField";

export function checklistQuestionToV3ChecklboxesInput({ data }: ChecklistPayload): FormPayload[] {
    return data.checklist_section_interfaces.map(section => {
        return {
            fields: section.checklist_step_interfaces.map(step => {
                return makeBlankField(InputTypeEnum.checkboxes, String(step.id), {
                    label: step.name,
                    name: step.name,
                    required: true,
                    choices: step.checklist_grade_options.map(step => ({
                        choice: step.name,
                        image: null,
                    })),
                });
            }),
        };
    });
}
