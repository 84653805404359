import { useParams } from "@solidjs/router";
import { Show } from "solid-js";
import {
    createFormByBpmnElementQuery,
    createFormResponseQuery,
} from "../../../../api/services/workflow/queries";
import { EventGeneralDataProps } from "../ExecutionEvents";
import { WorkflowForm } from "../../../../api/services/workflow/interface";
import { P } from "../../../../utils/typography";
import { ResponseDispatcher } from "../../../checklists/ChecklistExecutionDetailPage";

export function ActivityResponses(props: EventGeneralDataProps) {
    const params = useParams<{ id: string }>();
    const elementsByIdQuery = createFormByBpmnElementQuery(() => ({
        bpmnElementId: props.element.bpmnElementId,
        workflowId: props.workflowId,
    }));
    return (
        <div>
            <Show when={elementsByIdQuery.data}>
                {form => <ResponseRender executionId={params.id} form={form()} />}
            </Show>
        </div>
    );
}

function ResponseRender(props: { form: WorkflowForm; executionId: string }) {
    const formResponsesQuery = createFormResponseQuery(() => ({
        executionId: props.executionId,
        formId: props.form.id,
    }));
    return (
        <Show when={formResponsesQuery.data}>
            {formResponse => (
                <Show when={formResponse().formValues} fallback={<P>-- No hay respuesta --</P>}>
                    {formValues => <ResponseDispatcher response={formValues()} />}
                </Show>
            )}
        </Show>
    );
}
