import { createListPositionsQuery } from "../../api/services/positions/queries";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { H2 } from "../../utils/typography";
import { JsonDebug } from "../../utils/debug";
import { createPersonQuery } from "../../api/services/person/queries";
import { Show, Suspense } from "solid-js";

export function PositionsAndPeopleDebug() {
    const positionsQuery = createListPositionsQuery();

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <div class="flex gap-10">
                <Show
                    when={positionsQuery.data}
                    fallback={<ErrorBlock error={positionsQuery.error} />}
                >
                    {positions => (
                        <>
                            <div>
                                <H2>Positions</H2>
                                <JsonDebug value={positions()} />
                            </div>
                            <PersonEndpointDebug id="9cb9efd5-ec0d-47a5-865c-27ed5ce00f81" />
                        </>
                    )}
                </Show>
            </div>
        </Suspense>
    );
}

function PersonEndpointDebug(props: { id: string }) {
    const personQuery = createPersonQuery(() => props.id);

    return (
        <div>
            <H2>Hardcoded person</H2>
            <Suspense fallback={<GenericSuspenseFallback />}>
                <Show
                    when={personQuery.data || !props.id}
                    fallback={<ErrorBlock error={personQuery.error} />}
                >
                    <JsonDebug value={personQuery.data} />
                </Show>
            </Suspense>
        </div>
    );
}
