// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
function id(x) {
    return x[0];
}

import moo from "moo";
const lexer = moo.compile({
    // every regex uses /u because of https://github.com/no-context/moo/issues/116
    ws: /[ \t]+/u,
    number: { match: /-?[0-9]+/u, value: Number.parseInt },
    string: { match: /"(?:\\["\\]|[^\n"\\])*"/u, value: JSON.parse },
    //variable: { match: /[\p{L}_][\p{L}\p{N}_]*/u },
    lparen: "(",
    rparen: ")",
    variable: {
        match: /[\p{L}_][\p{L}\p{N}_]*/u,
        type: moo.keywords({
            and: "and",
            or: "or",
            not: "not",
            True: "True", // True: 'True' instead of true: 'True' as true is reserved in JS
            False: "False",
            None: "None",
        }),
    },
    operator: {
        match: /[<!=>]+/u,
        type: moo.keywords({
            cmp: ["<", "<=", ">", ">=", "==", "!="],
        }),
    },
});
let Lexer = lexer;
let ParserRules = [
    { name: "main", symbols: ["_", "expr", "_"], postprocess: d => d[1] },
    { name: "expr", symbols: ["ors"], postprocess: id },
    {
        name: "ors",
        symbols: ["ors", "_", lexer.has("or") ? { type: "or" } : or, "_", "ands"],
        postprocess: d => ({ type: "binOp", op: "or", lhs: d[0], rhs: d[4] }),
    },
    { name: "ors", symbols: ["ands"], postprocess: id },
    {
        name: "ands",
        symbols: ["ands", "_", lexer.has("and") ? { type: "and" } : and, "_", "cmps"],
        postprocess: d => ({ type: "binOp", op: "and", lhs: d[0], rhs: d[4] }),
    },
    { name: "ands", symbols: ["cmps"], postprocess: id },
    {
        name: "cmps",
        symbols: ["cmps", "_", lexer.has("cmp") ? { type: "cmp" } : cmp, "_", "nots"],
        postprocess: d =>
            d[0].chain
                ? // Python comparison chain,   e.g.   a < b <= c  ---->  a < b and b <= c
                  {
                      type: "binOp",
                      op: "and",
                      lhs: d[0],
                      rhs: { type: "binOp", op: d[2].value, lhs: d[0].chain, rhs: d[4] },
                      chain: d[4],
                  } // chain contains the second last number (the "b" in the example)
                : // Regular comparison,        e.g.   a < b
                  { type: "binOp", op: d[2].value, lhs: d[0], rhs: d[4], chain: d[4] },
    },
    { name: "cmps", symbols: ["nots"], postprocess: id },
    {
        name: "nots",
        symbols: [lexer.has("not") ? { type: "not" } : not, "_", "nots"],
        postprocess: d => ({ type: "unaryOp", op: "not", operand: d[2] }),
    },
    { name: "nots", symbols: ["atom"], postprocess: id },
    {
        name: "atom",
        symbols: [
            lexer.has("lparen") ? { type: "lparen" } : lparen,
            "_",
            "expr",
            "_",
            lexer.has("rparen") ? { type: "rparen" } : rparen,
        ],
        postprocess: d => d[2],
    },
    {
        name: "atom",
        symbols: [lexer.has("variable") ? { type: "variable" } : variable],
        postprocess: d => ({ type: "var", identifier: d[0].value }),
    },
    {
        name: "atom",
        symbols: [lexer.has("number") ? { type: "number" } : number],
        postprocess: d => ({ type: "lit", value: d[0].value }),
    },
    {
        name: "atom",
        symbols: [lexer.has("string") ? { type: "string" } : string],
        postprocess: d => ({ type: "lit", value: d[0].value }),
    },
    {
        name: "atom",
        symbols: [lexer.has("True") ? { type: "True" } : True],
        postprocess: () => ({ type: "lit", value: true }),
    },
    {
        name: "atom",
        symbols: [lexer.has("False") ? { type: "False" } : False],
        postprocess: () => ({ type: "lit", value: false }),
    },
    {
        name: "atom",
        symbols: [lexer.has("None") ? { type: "None" } : None],
        postprocess: () => ({ type: "lit", value: undefined }),
    },
    { name: "_$ebnf$1", symbols: [] },
    {
        name: "_$ebnf$1",
        symbols: ["_$ebnf$1", lexer.has("ws") ? { type: "ws" } : ws],
        postprocess: function arrpush(d) {
            return d[0].concat([d[1]]);
        },
    },
    { name: "_", symbols: ["_$ebnf$1"], postprocess: () => null },
];
let ParserStart = "main";
export default { Lexer, ParserRules, ParserStart };
