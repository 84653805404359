import { LocationsService, sVenue, Venue } from "../interface";
import { makeServiceQuery } from "../../../utils";
import { assetsClient } from "../../../clients/assets";
import { array } from "superstruct";

export class V3LocationsService implements LocationsService {
    listVenues = makeServiceQuery({
        fetchJson: async () => assetsClient.get("/locations/venues").receiveJson(),
        responseSchema: array(sVenue()),
        deserialize: (arr: Venue[]) => arr,
    });
}
