import { P } from "../../../utils/typography";
import { BorderedCard } from "../../ui/cards";
import { JSX } from "solid-js";
import { SectionStep } from "../../../api/services/checklist/interface";

type Props = {
    actions?: JSX.Element;
    onClick?: () => void;
    disabled?: boolean;
    index: number;
    step: SectionStep;
    class?: string;
};
export function LabelCard(props: Props) {
    return (
        <BorderedCard
            onClick={props.onClick}
            class={`flex flex-row items-baseline py-2 pr-1 
                    ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"} ${props.class}`}
        >
            <P class="!mb-0 flex-1">
                Paso {props.index + 1}: {props.step.mainField.label}
            </P>
            {props.actions}
        </BorderedCard>
    );
}
