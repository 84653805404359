import { Button } from "../ui/components";
import { Tabs } from "../ui/Tabs";
import { TaskTab } from "./detailPage/TaskTab";
import { DetailTab } from "./detailPage/DetailTab";
import { PageWrapper } from "../ui/pageWrappers";
import { createMemo, Show, Suspense } from "solid-js";
import { createModalController } from "../ui/Modal";
import { TaskDetailModal } from "./modals/TaskDetailModal";
import { EndTaskModal } from "./modals/EndTaskModal";
import { createRef } from "../../utils/reactRefs";
import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import {
    createActivityDetailQuery,
    createActivityTasksQuery,
    createGetOrCreateRecurrenceActivityQuery,
    createRecurrenceTasksQuery,
} from "../../api/services/task-manager/queries";
import { useParams } from "@solidjs/router";
import { ErrorBlock } from "../../utils/GenericErrorBoundary";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { DetailActivityHeader } from "./detailPage/DetailActivityHeader";
import { useClock } from "../../utils/clock";
import { getHmsDurationBetween } from "../../utils/formatDate";
import { InfoBubble } from "../ui/InfoBubble";
import { ActivityItem, RecurrenceActivity } from "../../api/services/task-manager/interface";

export default function ActivitiesDetailPage() {
    const params = useParams<{ id: string; recurrenceId: string }>();
    const id = () => params.id;
    const recurrenceId = () => params.recurrenceId;
    const activityDetailQuery = createActivityDetailQuery(id, recurrenceId);
    const recurrenceActivityQuery = createGetOrCreateRecurrenceActivityQuery(id, recurrenceId);
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={recurrenceActivityQuery.data}>
                {recurrenceActivity => (
                    <Show
                        when={activityDetailQuery.data}
                        fallback={<ErrorBlock error={activityDetailQuery.error} />}
                    >
                        {activityItem => (
                            <ActivityDetailContent
                                recurrenceActivity={recurrenceActivity()}
                                activityItem={activityItem()}
                            />
                        )}
                    </Show>
                )}
            </Show>
        </Suspense>
    );
}

function ActivityDetailContent(props: {
    recurrenceActivity: RecurrenceActivity;
    activityItem: ActivityItem;
}) {
    const { sm } = useResponsiveBreakpoints();
    const { nowZdt } = useClock();

    const taskDetailModalController = createModalController<void, void>();
    const endTaskModalController = createModalController<void, void>();
    const params = useParams<{ id: string; recurrenceId: string }>();
    const id = () => params.id;
    const recurrenceId = () => params.recurrenceId;
    const recurrenceActivityQuery = createGetOrCreateRecurrenceActivityQuery(id, recurrenceId);
    const ref = createRef<HTMLDialogElement>();
    const isCompleted = createMemo(() => recurrenceActivityQuery.data?.status === "COMPLETED");
    const recurrenceTasksQuery = createRecurrenceTasksQuery(() => props.recurrenceActivity.id);
    const activityTasksQuery = createActivityTasksQuery(id);
    const allTasksCompleted = createMemo(
        () =>
            recurrenceTasksQuery.data?.filter(t => t.completed_at !== null).length ===
            activityTasksQuery.data?.length,
    );
    const remainingTime = createMemo(() =>
        getHmsDurationBetween(nowZdt(), props.activityItem.dueDate),
    );
    const isExpired = createMemo(() => remainingTime().sign === -1);
    return (
        <>
            <PageWrapper class={"bg-white !pb-0"}>
                <div class={"grid grid-cols-12 gap-x-6"}>
                    <div
                        class={
                            "relative col-span-12 overflow-y-scroll md:col-span-8 md:max-h-[calc(100vh-130px)]"
                        }
                    >
                        <Show when={isCompleted()}>
                            <InfoBubble text="Esta actividad esta finalizada" />
                        </Show>

                        <DetailActivityHeader
                            activity={props.activityItem}
                            isExpired={isExpired()}
                            remainingTime={remainingTime()}
                        />
                        <Tabs>
                            <TaskTab
                                isExpired={isExpired()}
                                activityId={id()}
                                recurrenceId={recurrenceId()}
                                modalController={taskDetailModalController}
                            />
                            <DetailTab activity={props.activityItem} />
                        </Tabs>

                        <Show when={!sm()}>
                            <div class={`fixed bottom-24 left-0 z-dropdown w-full bg-white p-2`}>
                                <Button
                                    variant={"primary"}
                                    size={"lg"}
                                    class={"w-full"}
                                    disabled={!allTasksCompleted() || isExpired() || isCompleted()}
                                    onClick={() => {
                                        ref.current?.showModal();
                                        endTaskModalController.open();
                                    }}
                                >
                                    Finalizar actividad
                                </Button>
                            </div>
                        </Show>
                        <Show when={sm()}>
                            <div
                                class={
                                    "sticky bottom-0 flex w-full justify-end gap-x-6 bg-white pb-4 pt-6"
                                }
                            >
                                {/* <Button
                                    variant={"primary"}
                                    bgStyle={"outline"}
                                    size={"lg"}
                                    disabled={!allTasksCompleted() || isExpired() || isCompleted()}

                                    onClick={() => {
                                        ref.current?.showModal();
                                        endTaskModalController.open();
                                    }}
                                >
                                    Finalizar en otro momento
                                </Button> */}
                                <Button
                                    variant={"primary"}
                                    size={"lg"}
                                    onClick={() => {
                                        ref.current?.showModal();
                                        endTaskModalController.open();
                                    }}
                                    disabled={!allTasksCompleted() || isExpired() || isCompleted()}
                                >
                                    Finalizar actividad
                                </Button>
                            </div>
                        </Show>
                    </div>
                    <div class={"hidden md:flex"} />
                </div>

                <TaskDetailModal controller={taskDetailModalController} />
                <EndTaskModal
                    controller={endTaskModalController}
                    recurrence={props.recurrenceActivity}
                />
            </PageWrapper>
        </>
    );
}
