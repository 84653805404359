import { FieldProps } from "./parts/types";
import ChoiceField from "./choiceField/ChoiceField";
import { getApiInstance } from "../../../api";
import { InputChoice } from "choices.js";
import { parsedEnv } from "../../../utils/parsedEnv";

export default function VenueField(props: FieldProps<string, HTMLSelectElement>) {
    return (
        <ChoiceField
            {...props}
            renderChoice={html}
            fetchChoices={async () => {
                const api = getApiInstance();
                const venues = await api.locations.listVenues();
                return venues.map(
                    (venue): VenueChoice => ({
                        value: venue.id,
                        label: venue.name,
                        labelDescription: venue.description,
                        customProperties: {
                            address: venue.address,
                            code: venue.code,
                            legacy_id: (venue.legacy_id || "").toString(),
                        },
                    }),
                );
            }}
            userConfig={{
                placeholderValue: "Selecciona un recinto",
                searchPlaceholderValue: "Escribe para buscar un recinto",
                searchFields: [
                    "value",
                    "label",
                    "labelDescription",
                    "customProperties.address",
                    "customProperties.code",
                    "customProperties.legacy_id",
                ],
            }}
        />
    );
}

type VenueChoice = InputChoice & {
    customProperties?: {
        address: string;
        code: string;
        legacy_id: string;
    };
};

const html = (choice: VenueChoice) => {
    const codeOrLegacyId = choice.customProperties?.code || choice.customProperties?.legacy_id;
    return `
<div class="flex flex-col">
    <span class="text-sm font-normal pt-0.5">${choice.label}</span>
    <div class="flex items-baseline gap-x-2 text-xs">
        ${
            codeOrLegacyId
                ? `<code class="rounded-sm bg-light-gray-300 px-2 py-0.5">${codeOrLegacyId}</code>`
                : ``
        }
        ${
            parsedEnv.MODE === "development"
                ? `<code class="text-[#aa66aa]">${choice.value}</code>`
                : `<span class="text-dark-gray-400">${
                      choice.customProperties?.address || choice.labelDescription
                  }</span>`
        }
    </div>
</div>`;
};
