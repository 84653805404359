import { ColumnDefinition, Table } from "../Table";
import { Folder } from "./GenericFileExplorer";
import { Accessor, Show } from "solid-js";
import { P } from "../../../utils/typography";
import { defaultFormattedDate } from "../../../utils/formatDate";
import FolderItemActionButton from "./FolderItemActionButton";
import { FolderGridProps } from "./FolderGrid";
import { FolderItemIcon } from "./FolderItem";

const formatDate: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
};

export default function ListGridRender<T extends Folder>(
    props: FolderGridProps<T> & {
        filteredFolders: Accessor<T[]>;
        onClickFolder: (e: Event, folder: T) => void;
        onDblClickFolder: (e: Event, folder: T) => void;
    },
) {
    const tableColumns: ColumnDefinition<T>[] = [
        {
            title: "Nombre",
            cell: row => {
                return (
                    <div class={"flex items-center gap-x-4 pl-2"}>
                        <FolderItemIcon folder={row} />
                        <P class={"font-normal"}>{row.name}</P>
                    </div>
                );
            },
        },
        {
            title: "Descripción",
            accessor: "description",
        },
        {
            title: "Última modificación",
            cell: row => {
                return (
                    <P>{row.updated_at ? defaultFormattedDate(row.updated_at, formatDate) : "-"}</P>
                );
            },
        },
        {
            title: "",
            cell: row => {
                return (
                    <FolderItemActionButton
                        isActive={row.id === props.activeFolder?.id}
                        folder={row}
                        deleteFolder={props.deleteFolder}
                        setActiveFolderId={props.setActiveFolderId}
                        editFolder={props.editFolder}
                    />
                );
            },
        },
    ];

    return (
        <Show when={props.filteredFolders().length > 0}>
            <Table
                theadClass={"text-left"}
                tbodyTrClass={`border-b border-light-gray-300 [&>td]:py-3 px-2 cursor-pointer`}
                columns={tableColumns}
                data={props.filteredFolders()}
                trOptions={row => ({
                    onClick: e => props.onClickFolder(e, row),
                    onDblClick: e => props.onDblClickFolder(e, row),
                    class:
                        row.id === props.activeFolder?.id
                            ? "!bg-primary-50"
                            : "hover:bg-light-gray-50",
                })}
            />
        </Show>
    );
}
